import { format } from 'date-fns'
import {
  CloneEstimationType,
  CreateEstimationType,
  EstimationDatapointType,
  EstimationDetail,
} from '@netpurpose/types'
import { FieldMap } from '../../queryBuilder/fieldMapping'
import { BACKEND_DATE_FORMAT } from '../datetime'
import { BackendWorkbook } from '../workbook'
import {
  BackendCloneEstimationForm,
  BackendEstimationCommon,
  BackendEstimationDatapoints,
  BackendEstimationDetail,
} from './types'

export const formatEstimation = (
  estimation: CreateEstimationType,
): Omit<BackendEstimationCommon, 'workbook_id' | 'status'> & {
  clone_from?: BackendWorkbook['workbook_id']
} => ({
  entity_id: estimation.entityId,
  technology_id: estimation.technologyId,
  technology_name: estimation.technologyName,
  reporting_end_date: format(estimation.reportingEndDate, BACKEND_DATE_FORMAT),
  workbook_name: estimation.workbookName,
  ...(estimation.cloneEstimation ? { clone_from: estimation.cloneEstimation } : {}),
})

export const formatCloneEstimation = (
  cloneForm: CloneEstimationType,
): BackendCloneEstimationForm => ({
  source_entity_id: cloneForm.sourceEntityId,
  destination_entity_id: cloneForm.destinationEntityId,
  technology_id: cloneForm.technologyId,
})

const typeMap: Record<EstimationDatapointType, keyof BackendEstimationDatapoints> = {
  revenue: 'revenue',
  output: 'output',
  outcomeLevel1: 'outcome_level_1',
  activityCosts: 'activity_costs',
  baselineCosts: 'baseline_costs',
  outcomeLevel2s: 'outcome_level_2s',
  impact: 'impact',
  activityCost: 'activity_cost',
  baselineCost: 'baseline_cost',
  outcomeLevel2: 'outcome_level_2',
}

export const formatEstimationType = (type: EstimationDatapointType): string => typeMap[type]

export const estimationFieldMap: FieldMap<Omit<EstimationDetail, 'id'>, BackendEstimationDetail> = {
  format: {
    workbookId: 'workbook_id',
    workbookName: 'workbook_name',
    entityId: 'entity_id',
    technologyId: 'technology_id',
    technologyName: 'technology_name',
    status: 'status',
    reportingEndDate: 'reporting_end_date',
    revenue: 'revenue',
    output: 'output',
    outcomeLevel1: 'outcome_level_1',
    outcomeLevel2s: 'outcome_level_2s',
    activityCosts: 'activity_costs',
    baselineCosts: 'baseline_costs',
    impact: 'impact',
  },
  parse: {
    workbook_id: 'workbookId',
    workbook_name: 'workbookName',
    entity_id: 'entityId',
    technology_id: 'technologyId',
    technology_name: 'technologyName',
    status: 'status',
    reporting_end_date: 'reportingEndDate',
    revenue: 'revenue',
    output: 'output',
    outcome_level_1: 'outcomeLevel1',
    outcome_level_2s: 'outcomeLevel2s',
    activity_costs: 'activityCosts',
    baseline_costs: 'baselineCosts',
    impact: 'impact',
  },
}
