import qs from 'qs'
import {
  CloneEstimationType,
  CreateEstimationType,
  Datapoint,
  Estimation,
  EstimationBase,
  EstimationDatapointType,
  EstimationDetail,
  EstimationList,
  Workbook,
} from '@netpurpose/types'
import { ImpactThemes } from '../../generated/modelling'
import { joinPaths } from '../../utils'
import { AbstractModelApi } from '../AbstractModelApi'
import { formatDateForBackend } from '../datetime'
import { formatCloneEstimation, formatEstimation, formatEstimationType } from './formatEstimation'
import {
  parseEstimationBase,
  parseEstimationDetail,
  parseEstimationList,
  reverseEstimationFieldMap,
} from './parseEstimation'
import { BackendEstimationBase, BackendEstimationDetail, BackendEstimationList } from './types'

export type EstimationParams = {
  entity_id: number | undefined
  technology_id: string | undefined
  status?: 'accepted' | 'created'
  with_incomplete?: boolean
  sort_by: string[]
}

export default class EstimationApi extends AbstractModelApi<
  BackendEstimationList,
  BackendEstimationDetail,
  EstimationList,
  EstimationDetail
> {
  endpoint = '/estimations'
  listResultParser = parseEstimationList
  detailResultParser = parseEstimationDetail
  reverseFieldMap = reverseEstimationFieldMap

  async get(params: EstimationParams): Promise<EstimationDetail[]> {
    const { entity_id, technology_id, status, with_incomplete, sort_by } = params
    const query = qs.stringify({ status, with_incomplete, sort_by })
    const { data } = await this.api.get<BackendEstimationDetail[]>(
      `/estimations/${entity_id}/${technology_id}?${query}`,
    )
    return data.map(parseEstimationDetail)
  }

  async attachDatapointToEstimation(
    datapointId: Datapoint['id'],
    estimationWorkbookId: Workbook['id'],
    estimationDatapointName: EstimationDatapointType,
    impactTheme?: ImpactThemes,
  ): Promise<EstimationDetail> {
    const { data } = await this.api.post<BackendEstimationDetail>(
      `/estimations/${estimationWorkbookId}/datapoints`,
      {
        estimation_id: estimationWorkbookId,
        datapoint_id: datapointId,
        datapoint_type: formatEstimationType(estimationDatapointName),
        impact_theme: impactTheme,
      },
    )
    return parseEstimationDetail(data)
  }

  async detachDatapoint(
    datapointId: Datapoint['id'],
    estimationWorkbookId: Workbook['id'],
    impactTheme?: ImpactThemes,
  ): Promise<EstimationDetail> {
    const { data } = await this.api.delete<BackendEstimationDetail>(
      `/estimations/${estimationWorkbookId}/datapoints`,
      {
        data: {
          estimation_id: estimationWorkbookId,
          datapoint_id: datapointId,
          impact_theme: impactTheme,
        },
      },
    )
    return parseEstimationDetail(data)
  }

  async createEstimation(estimation: CreateEstimationType): Promise<Partial<EstimationDetail>> {
    const { data } = await this.api.post<BackendEstimationDetail>(
      this.endpoint,
      formatEstimation(estimation),
    )
    return parseEstimationDetail(data)
  }

  async cloneEstimation(cloneEstimationParams: CloneEstimationType): Promise<EstimationBase[]> {
    const { data } = await this.api.post<BackendEstimationBase[]>(
      joinPaths(this.endpoint, 'clone'),
      formatCloneEstimation(cloneEstimationParams),
    )
    // NOTE: data === '' when no new estimations have been created
    return data ? data.map(parseEstimationBase) : []
  }

  async updateEstimation(
    estimationId: Estimation['id'],
    impactTheme: Estimation['impactTheme'],
    estimationStatus: Estimation['status'],
  ): Promise<EstimationDetail> {
    const { data } = await this.api.post<BackendEstimationDetail>(
      `/estimations/${estimationId}/${impactTheme}/${
        estimationStatus === 'accepted' ? 'accept' : 'reject'
      }`,
    )
    return parseEstimationDetail(data)
  }

  async updateReportingEndDate(estimationId: Estimation['id'], reportingEndDate: Date) {
    const formattedDate = formatDateForBackend(reportingEndDate)
    await this.api.put<BackendEstimationDetail>(
      `/estimations/${estimationId}/reporting-end-date/${formattedDate}`,
    )
  }

  async deleteBranch(estimationId: Estimation['id'], impactTheme: ImpactThemes) {
    await this.api.delete<BackendEstimationDetail>(`/estimations/${estimationId}/${impactTheme}`)
  }
}
