import { UnitLegacy } from '@netpurpose/types'
import { GeneratedFactApi } from '../../GeneratedApi'
import { Camelize } from '../../utils'

export type BackendUnit = {
  unit_id: number
  name: string
  description?: string | null
  is_standard?: boolean
  unit_type?: string | null
  iso_code?: string | null
  conversion_factor?: number
  standard_id?: number
}

// @ts-expect-error
export const parseUnit = (unit: BackendUnit): UnitLegacy => ({
  id: unit.unit_id,
  name: unit.name,
  description: unit.description,
  isStandard: unit.is_standard,
  type: unit.unit_type,
  iso: unit.iso_code,
  conversionFactor: unit.conversion_factor,
  standardUnitId: unit.standard_id,
})

type ReturnedUnit = Awaited<ReturnType<(typeof GeneratedFactApi)['units']['getUnit']>>

export const transformUnit = <UnitProps extends Camelize<ReturnedUnit>>({
  unitId,
  unitType,
  isoCode,
  standardId,
  ...rest
}: UnitProps) => ({
  id: unitId,
  type: unitType,
  iso: isoCode,
  standardUnitId: standardId,
  ...rest,
})
