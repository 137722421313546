import { AxiosInstances } from '../client'
import { AbstractModelApi } from './AbstractModelApi'

/**
 * Creates an AbstractModelApi that uses the same model for both list-model and detail-model operations
 */
export abstract class SimpleAbstractModelApi<
  BackendModel,
  Model extends { id: number } | { id: string },
> extends AbstractModelApi<BackendModel, BackendModel, Model, Model> {
  listResultParser: (backendModel: BackendModel) => Model
  detailResultParser: (backendModel: BackendModel) => Model

  constructor(axiosInstances: AxiosInstances, resultParser: (backendModel: BackendModel) => Model) {
    super(axiosInstances)
    this.listResultParser = resultParser
    this.detailResultParser = resultParser
  }
}
