import { FC } from 'react'

const FunctionFormula: FC = () => (
  <>
    <path
      d="M14.5938 13L17.4137 17.41"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.4137 13L14.5938 17.41"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.9978 6C14.9978 5.46957 14.7871 4.96089 14.412 4.58582C14.0369 4.21074 13.5282 4 12.9978 4C10.9378 4 10.3278 7.29999 10.1278 8.79999C9.95781 10.11 9.96783 11.43 9.89783 12.74C9.79783 14.74 9.70781 17.86 7.00781 17.88C6.62962 17.8808 6.25564 17.8009 5.91077 17.6457C5.56589 17.4905 5.25804 17.2636 5.00781 16.98"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8 10H13"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
)

export default FunctionFormula
