import { Standard } from '@netpurpose/types'
import { AxiosInstances } from '../../client'
import { SimpleAbstractModelApi } from '../SimpleAbstractModelApi'
import { BackendStandard, parseStandard } from './parseStandard'

export class StandardApi extends SimpleAbstractModelApi<BackendStandard, Standard> {
  endpoint = '/standards'
  reverseFieldMap = undefined

  constructor(axiosInstances: AxiosInstances) {
    super(axiosInstances, parseStandard)
  }
}
