import { FC } from 'react'

const DragIcon: FC = () => (
  <>
    <circle cx="10" cy="8" r="1" fill="currentColor" />
    <circle cx="10" cy="12" r="1" fill="currentColor" />
    <circle cx="10" cy="16" r="1" fill="currentColor" />
    <circle cx="14" cy="16" r="1" fill="currentColor" />
    <circle cx="14" cy="12" r="1" fill="currentColor" />
    <circle cx="14" cy="8" r="1" fill="currentColor" />
  </>
)

export default DragIcon
