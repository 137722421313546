import { Datapoint, DatapointStatus } from '@netpurpose/types'
import { convertStringToEnum } from '@netpurpose/utils'
import { parseDatapointType } from './datapointTypeMap'
import { parseReference } from './parseReference'
import { BackendDatapoint } from './types'

// @ts-expect-error
export const parseDatapoint = (datapoint: BackendDatapoint): Datapoint => ({
  id: datapoint.datapoint_id,
  type: parseDatapointType(datapoint.type),
  value: datapoint.value,
  reportedValue: datapoint.reported_value,
  unitId: datapoint.unit_id,
  reportedUnitId: datapoint.reported_unit_id,
  status: convertStringToEnum<DatapointStatus>(datapoint.status, DatapointStatus),
  reference: datapoint.reference && parseReference(datapoint.reference),
  originalWorkbookId: datapoint.original_workbook_id,
  cell: datapoint.cell,
  formula: datapoint.formula && `=${datapoint.formula}`,
  raw: datapoint.raw,
  impactTheme: datapoint.impact_theme,
  reportingEndDate: datapoint.reporting_end_date,
  questionId: datapoint.question_id,
  fxRateUsed: datapoint.fx_rate_used,
  fxRateDate: datapoint.fx_rate_date,
})
