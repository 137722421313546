import { IngestionBatch, IngestionBatchWithTasks } from '@netpurpose/types'
import { AbstractModelApi } from '../AbstractModelApi'
import {
  parseIngestionBatch,
  parseIngestionBatchWithTasks,
  reverseIngestionBatchFieldMap,
} from './parseIngestionBatch'
import { BackendIngestionBatch, BackendIngestionBatchWithTasks } from './types'

export class IngestionBatchApi extends AbstractModelApi<
  BackendIngestionBatch,
  BackendIngestionBatchWithTasks,
  IngestionBatch,
  IngestionBatchWithTasks
> {
  endpoint = '/batches/ingestion'
  listResultParser = parseIngestionBatch
  detailResultParser = parseIngestionBatchWithTasks
  reverseFieldMap = reverseIngestionBatchFieldMap
}
