import React, { FC, useMemo } from 'react'
import { Api, ApiConfig } from '../Api'

type ApiState = {
  api: Api | null
}

export type IApiContext = ApiState

const defaultState: ApiState = {
  api: null,
}
export const defaultApiContext: IApiContext = {
  ...defaultState,
}

export const ApiContext = React.createContext(defaultApiContext)

type Props = {
  apiConfig: ApiConfig
  children: React.ReactNode
}

export const ApiProvider: FC<Props> = ({ apiConfig, ...restProps }) => {
  const api = useMemo(() => new Api(apiConfig), [apiConfig])

  return <ApiContext.Provider value={{ api }} {...restProps} />
}
