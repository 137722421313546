import { FC } from 'react'

const EyeOpenIcon: FC = () => (
  <svg viewBox="0 0 20 20">
    <path
      d="M10.0001 13.3334C11.0417 13.3334 11.9273 12.9687 12.6567 12.2392C13.3862 11.5098 13.7506 10.6245 13.7501 9.58337C13.7501 8.54171 13.3854 7.65615 12.6559 6.92671C11.9265 6.19726 11.0412 5.83282 10.0001 5.83337C8.95841 5.83337 8.07286 6.1981 7.34341 6.92754C6.61397 7.65699 6.24953 8.54226 6.25008 9.58337C6.25008 10.625 6.6148 11.5106 7.34425 12.24C8.07369 12.9695 8.95897 13.3339 10.0001 13.3334ZM10.0001 11.8334C9.37508 11.8334 8.84369 11.6145 8.40591 11.1767C7.96814 10.7389 7.74953 10.2078 7.75008 9.58337C7.75008 8.95837 7.96897 8.42699 8.40675 7.98921C8.84453 7.55143 9.37564 7.33282 10.0001 7.33337C10.6251 7.33337 11.1565 7.55226 11.5942 7.99004C12.032 8.42782 12.2506 8.95893 12.2501 9.58337C12.2501 10.2084 12.0315 10.7398 11.5942 11.1775C11.157 11.6153 10.6256 11.8339 10.0001 11.8334ZM10.0001 15.8334C8.06953 15.8334 6.30564 15.3228 4.70842 14.3017C3.11119 13.2806 1.90286 11.9023 1.08341 10.1667C1.04175 10.0973 1.01397 10.0106 1.00008 9.90671C0.986193 9.80282 0.979248 9.69504 0.979248 9.58337C0.979248 9.47226 0.986193 9.36449 1.00008 9.26004C1.01397 9.1556 1.04175 9.06893 1.08341 9.00004C1.90286 7.26393 3.11119 5.88532 4.70842 4.86421C6.30564 3.8431 8.06953 3.33282 10.0001 3.33337C11.9306 3.33337 13.6945 3.84393 15.2917 4.86504C16.889 5.88615 18.0973 7.26449 18.9167 9.00004C18.9584 9.06949 18.9862 9.15643 19.0001 9.26087C19.014 9.36532 19.0209 9.47282 19.0209 9.58337C19.0209 9.69449 19.014 9.80226 19.0001 9.90671C18.9862 10.0112 18.9584 10.0978 18.9167 10.1667C18.0973 11.9028 16.889 13.2814 15.2917 14.3025C13.6945 15.3237 11.9306 15.8339 10.0001 15.8334ZM10.0001 14.1667C11.5695 14.1667 13.0106 13.7534 14.3234 12.9267C15.6362 12.1 16.6395 10.9856 17.3334 9.58337C16.639 8.1806 15.6354 7.06587 14.3226 6.23921C13.0098 5.41254 11.569 4.99949 10.0001 5.00004C8.43064 5.00004 6.98953 5.41337 5.67675 6.24004C4.36397 7.06671 3.36064 8.18115 2.66675 9.58337C3.36119 10.9862 4.3648 12.1009 5.67758 12.9275C6.99036 13.7542 8.43119 14.1673 10.0001 14.1667Z"
      fill="currentColor"
    />
  </svg>
)

export default EyeOpenIcon
