import { FC } from 'react'

const FilterIcon: FC = () => (
  <>
    <path
      d="M12 15L7 7H17L12 15Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 15V17"
      stroke="currentColor"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
)

export default FilterIcon
