const Tick = () => (
  <path
    d="M11.1668 2L4.50016 10L1.8335 7.33333"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  />
)

export default Tick
