import { FC } from 'react'

const MinusIcon: FC = () => (
  <path
    d="M7 12H17"
    stroke="currentColor"
    strokeWidth="2"
    strokeMiterlimit="10"
    strokeLinecap="round"
    strokeLinejoin="round"
  />
)

export default MinusIcon
