export const getBaseUrl = (host: string) => `${host}/api/v1`

export const joinPaths = (...paths: (string | number)[]) => {
  const res = paths
    .map((p) => p.toString())
    .reduce((path, fragment) => {
      return (
        path +
        (fragment.startsWith('/') ? '' : '/') +
        (fragment.endsWith('/') ? fragment.slice(0, -1) : fragment)
      )
    }, '')

  return res.endsWith('/') ? res.slice(0, -1) : res
}
