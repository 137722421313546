export type FeedbackRequest = {
  message: string
  url: string
  id: string | number
  dataType: string
}

export type BackendFeedbackRequest = {
  feedback: string
  url: string
  data_id: string | number
  data_type: string
}

export const formatFeedbackRequestForBackend = (
  feedbackRequest: FeedbackRequest,
): BackendFeedbackRequest => ({
  feedback: feedbackRequest.message,
  url: feedbackRequest.url,
  data_id: feedbackRequest.id,
  data_type: feedbackRequest.dataType,
})
