import { AxiosInstances } from '../../client'
import { AbstractApi } from '../AbstractApi'
import { FeedbackRequest, formatFeedbackRequestForBackend } from './formatFeedbackRequest'

export default class FeedbackApi extends AbstractApi {
  constructor(axiosInstances: AxiosInstances) {
    super(axiosInstances)
    this.submitFeedback = this.submitFeedback.bind(this)
  }

  submitFeedback(feedbackRequest: FeedbackRequest) {
    return this.api.post('/feedback/submit_report_value', null, {
      params: formatFeedbackRequestForBackend(feedbackRequest),
    })
  }
}
