import { FC, ReactNode } from 'react'
import { useAlertBanner, useModalContext } from '@netpurpose/core'
import {
  AlertBanner as NPUIAlertBanner,
  AlertBannerType as NPUIAlertBannerType,
} from '@netpurpose/np-ui'

export enum AlertBannerType {
  GeneralError = 'generalError',
  NetworkError = 'networkError',
  ServerError = 'serverError',
}

type AlertBannerContent = Record<
  AlertBannerType,
  { level: NPUIAlertBannerType; content: ReactNode }
>

const alertBannerContent: AlertBannerContent = {
  [AlertBannerType.GeneralError]: {
    level: 'error',
    content: 'Something went wrong.',
  },
  [AlertBannerType.NetworkError]: {
    level: 'error',
    content: 'There is a connection problem. Please try again.',
  },
  [AlertBannerType.ServerError]: {
    level: 'error',
    content: '⚠️', // will be + server error message, see handleError in queryClient
  },
}

type Props = {
  isVisible: boolean
  isOverlay: boolean
  onClose: () => void
  type?: AlertBannerType
  text?: ReactNode
}

export const AlertBannerWithoutContext: FC<Props> = ({
  isVisible,
  type = AlertBannerType.GeneralError,
  isOverlay,
  onClose,
  text,
}) => {
  if (!isVisible) {
    return null
  }

  const { level, content } = alertBannerContent[type]

  return (
    <NPUIAlertBanner type={level} isOverlay={isOverlay} onClose={onClose}>
      {content} {text}
    </NPUIAlertBanner>
  )
}

export const AlertBanner: FC = () => {
  const { isVisible, type, hideAlertBanner, text } = useAlertBanner<AlertBannerType>()
  const { isAnyModalOpen: isOverlay } = useModalContext()

  const onClose = () => {
    hideAlertBanner(type)
  }

  return (
    <AlertBannerWithoutContext
      {...{ isVisible, ...(type ? { type } : {}), isOverlay, onClose, text }}
    />
  )
}
