import { FC } from 'react'

const ArrowDown: FC = () => (
  <>
    <path
      d="M17 13L12 18L7 13"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 16L12 7"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
)

export default ArrowDown
