import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/database'
import { NextRouter } from 'next/router'
import { config, routes } from '../config'

firebase.initializeApp(config.firebase)

export const logout = (router?: NextRouter) => {
  firebase
    .auth()
    .signOut()
    .then(() => {
      if (router) {
        router.push(routes.login)
      } else {
        window.location.assign(`${window.location.origin}/login`)
      }
    })
}

export const getToken = async (forceRefresh = false) => {
  const { currentUser } = firebase.auth()
  if (!currentUser) {
    return null
  }
  return currentUser.getIdToken(forceRefresh)
}
