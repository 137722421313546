import { FC } from 'react'

const SdgClimateChangeIcon: FC = () => (
  <>
    <path
      d="M32 43.0017V40.3217C32 36.8217 32.82 33.3817 34.38 30.2617"
      stroke="white"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M24 42.9998V36.6198C24 29.4398 21.68 22.5198 17.5 16.8398"
      stroke="white"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2 3.14162L2.72 6.96162C2.96 8.26162 4.3 14.7216 7.36 17.1616C10.64 19.7816 14.82 19.5616 17.64 16.7616L18.78 15.3416C20.92 12.0216 20.22 7.90162 16.94 5.28162C13.9 2.86162 7.26 2.96162 5.92 3.00162L2 3.12162"
      stroke="white"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M46 19.1018L45.44 22.1018C45.26 23.1218 44.2 28.1818 41.84 30.0818C39.28 32.1218 36.04 31.9618 33.86 29.7618L32.98 28.6618C31.3 26.0618 31.86 22.8418 34.4 20.8018C36.76 18.9018 41.94 18.9818 42.96 19.0218L46 19.1218"
      stroke="white"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
)

export default SdgClimateChangeIcon
