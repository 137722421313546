import { pickBy } from 'lodash'
import { DatapointType, TechnologyCost, TechnologyForCost } from '@netpurpose/types'
import { valueIsDefined } from '@netpurpose/utils'
import { ImpactThemes, TechnologyBase } from '../../generated/modelling'
import { parseDatapoint } from './datapoint'
import { BackendDatapoint, BackendTechnologyCost } from './types'

export const parseTechnologyForCost = (technology: TechnologyBase): TechnologyForCost => ({
  id: technology.technology_id || '',
  name: technology.technology_name,
  ...pickBy({ baseline: technology.baseline }, valueIsDefined),
  taxonId: technology.taxon_id,
})

export const parseTechnologyCost = (technologyCost: BackendTechnologyCost): TechnologyCost => {
  return {
    ...parseDatapoint(technologyCost as BackendDatapoint),
    type: DatapointType.TechnologyCost,
    numeratorUnitId: technologyCost.numerator_unit_id,
    numeratorReportedUnitId: technologyCost.numerator_reported_unit_id,
    denominatorUnitId: technologyCost.denominator_unit_id,
    denominatorReportedUnitId: technologyCost.denominator_reported_unit_id,
    technology: technologyCost?.technology
      ? parseTechnologyForCost(technologyCost.technology)
      : undefined,
    isPrimaryCost: technologyCost.is_primary_cost,
    impactTheme: technologyCost.impact_theme as ImpactThemes,
    impactQuestionId: technologyCost.impact_question_id,
  }
}
