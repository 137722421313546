import { FC } from 'react'

const LogoTextIcon: FC = () => (
  <>
    <g clipPath="url(#clip0_4718_44583)">
      <path
        d="M14.4206 10.1792V19.1038H9.52448V10.806C9.52448 9.19454 8.58162 8.44846 7.31315 8.44846C5.80897 8.44846 4.89408 9.37448 4.89408 11.1639V19.1038H0V4.18025H4.89608V5.6724C5.72108 4.47949 7.16733 3.76172 8.99512 3.76172C12.0334 3.76172 14.4206 5.88066 14.4206 10.1792Z"
        fill="#1B1B1B"
      />
      <path
        d="M24.7099 15.2806C25.7726 15.2806 26.6855 14.9227 27.3946 14.2352L30.9323 16.7424C29.4581 18.6227 27.2168 19.5184 24.592 19.5184C19.4902 19.5184 16.334 16.0853 16.334 11.6674C16.334 7.24962 19.5781 3.75781 24.1785 3.75781C28.7789 3.75781 31.7873 7.13032 31.7873 11.6088C31.7873 12.2356 31.7294 12.8321 31.5815 13.4588H21.4078C21.9971 14.8924 23.2956 15.2806 24.7099 15.2806ZM27.0111 10.0277C26.5676 8.47488 25.419 7.93908 24.2384 7.93908C22.8221 7.93908 21.7614 8.65483 21.3479 10.0277H27.0091H27.0111Z"
        fill="#1B1B1B"
      />
      <path
        d="M39.5722 8.92462V13.2535C39.5722 14.6567 40.6629 14.7456 42.6405 14.6263V19.1028C36.6238 19.8489 34.6781 17.9685 34.6781 13.3728V8.92462H32.4668V4.17925H34.6781V1.49215L39.5722 0V4.17925H42.6405V8.92462H39.5722Z"
        fill="#1B1B1B"
      />
      <path
        d="M61.2749 11.639C61.2749 16.0568 57.8251 19.4596 53.6661 19.4596C51.0413 19.4596 48.9478 18.1757 47.7093 16.1761V25.0704H45.7637V4.17819H47.7093V7.10386C48.9478 5.10421 51.0413 3.82031 53.6661 3.82031C57.8251 3.82031 61.2749 7.22315 61.2749 11.641V11.639ZM59.3293 11.639C59.3293 8.32508 56.7923 5.72898 53.5203 5.72898C50.2482 5.72898 47.7113 8.32508 47.7113 11.639C47.7113 14.9528 50.2462 17.5489 53.5203 17.5489C56.7943 17.5489 59.3293 14.9528 59.3293 11.639Z"
        fill="#1B1B1B"
      />
      <path
        d="M76.8427 4.17969V19.1033H74.8971V16.7761C73.7465 18.6564 72.0365 19.4632 69.9431 19.4632C66.3454 19.4632 64.1641 17.0753 64.1641 13.3449V4.17969H66.1097V13.3429C66.1097 15.9997 67.554 17.5525 70.1209 17.5525C72.6878 17.5525 74.8991 15.9997 74.8991 12.0004V4.17969H76.8447H76.8427Z"
        fill="#1B1B1B"
      />
      <path
        d="M87.5767 3.94141V5.85209C85.2176 5.85209 82.9183 7.10566 82.9183 10.8077V19.1056H80.9707V4.17999H82.9183V6.6568C83.9511 4.56819 85.689 3.94141 87.5767 3.94141Z"
        fill="#1B1B1B"
      />
      <path
        d="M105.74 11.639C105.74 16.0568 102.29 19.4596 98.131 19.4596C95.5061 19.4596 93.4127 18.1757 92.1742 16.1761V25.0704H90.2285V4.17819H92.1742V7.10386C93.4127 5.10421 95.5061 3.82031 98.131 3.82031C102.29 3.82031 105.74 7.22315 105.74 11.641V11.639ZM103.794 11.639C103.794 8.32508 101.257 5.72898 97.9851 5.72898C94.7131 5.72898 92.1762 8.32508 92.1762 11.639C92.1762 14.9528 94.7111 17.5489 97.9851 17.5489C101.259 17.5489 103.794 14.9528 103.794 11.639Z"
        fill="#1B1B1B"
      />
      <path
        d="M108.098 11.641C108.098 7.22315 111.547 3.82031 115.854 3.82031C120.161 3.82031 123.611 7.22315 123.611 11.641C123.611 16.0588 120.161 19.4616 115.854 19.4616C111.547 19.4616 108.098 16.0588 108.098 11.641ZM121.663 11.641C121.663 8.3271 119.126 5.731 115.854 5.731C112.582 5.731 110.045 8.3271 110.045 11.641C110.045 14.9549 112.582 17.551 115.854 17.551C119.126 17.551 121.663 14.9549 121.663 11.641Z"
        fill="#1B1B1B"
      />
      <path
        d="M136.701 15.1631C136.701 17.7006 134.519 19.4616 131.363 19.4616C128.415 19.4616 126.409 17.9392 125.672 16.0285L127.324 15.0438C127.855 16.5663 129.387 17.551 131.363 17.551C133.103 17.551 134.755 16.9242 134.755 15.1631C134.755 11.461 126.145 13.582 126.145 8.08852C126.145 5.70067 128.239 3.82031 131.187 3.82031C133.606 3.82031 135.492 5.01323 136.349 6.86528L134.727 7.81961C134.108 6.29713 132.603 5.731 131.187 5.731C129.653 5.731 128.091 6.47708 128.091 8.08852C128.091 11.7886 136.701 9.66964 136.701 15.1631Z"
        fill="#1B1B1B"
      />
      <path
        d="M153.627 11.6996C153.627 11.9989 153.597 12.3264 153.567 12.6256H140.621C141.034 15.6706 143.363 17.551 146.49 17.551C148.819 17.551 150.441 16.447 151.238 15.0135L152.948 16.0285C151.709 18.0585 149.438 19.4616 146.46 19.4616C141.799 19.4616 138.615 16.1478 138.615 11.641C138.615 7.13419 141.741 3.82031 146.312 3.82031C150.882 3.82031 153.625 7.61136 153.625 11.6996H153.627ZM140.623 10.715H151.622C151.208 7.43141 148.849 5.731 146.314 5.731C143.218 5.731 141.006 7.7913 140.623 10.715Z"
        fill="#1B1B1B"
      />
      <path
        d="M155.838 16.4161C155.838 14.7157 157.224 13.3125 158.934 13.3125C160.644 13.3125 162 14.7157 162 16.4161C162 18.1165 160.614 19.5197 158.934 19.5197C157.254 19.5197 155.838 18.1468 155.838 16.4161Z"
        fill="#5AF058"
      />
    </g>
    <defs>
      <clipPath id="clip0_4718_44583">
        <rect width="162" height="25.0714" fill="white" />
      </clipPath>
    </defs>
  </>
)

export default LogoTextIcon
