import { FC } from 'react'

const CloseIcon: FC = () => (
  <>
    <path
      d="M7.04688 7.0498L16.9469 16.9498"
      stroke="currentColor"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.04688 16.9498L16.9469 7.0498"
      stroke="currentColor"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
)

export default CloseIcon
