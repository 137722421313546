import { DataValue, NOT_FOUND, UnitLegacy } from '@netpurpose/types'

type BackedDataValueUnit = UnitLegacy['name'] | 'NEW' | 'NOT FOUND' | 'ND' | 'UNPROCESSABLE'

export type BackendDataValue = [number | null, BackedDataValueUnit]

export const notFoundDataValue = { unit: NOT_FOUND }

export const parseUnitName = (unit: BackedDataValueUnit) => {
  if (unit === 'NOT FOUND' || unit === 'ND') {
    return NOT_FOUND
  }
  return unit
}

export const parseDataValue = (backendDataValue: BackendDataValue): DataValue => {
  if (backendDataValue[0] === null) {
    return notFoundDataValue
  }
  const [value, unit] = backendDataValue
  return { value, unit: parseUnitName(unit) }
}

const formatUnitName = (unit: string) => (unit === NOT_FOUND ? 'NOT FOUND' : unit)

export const formatDataValue = (dataValue: DataValue): BackendDataValue => {
  const { value, unit } = dataValue
  if (value === undefined) {
    return [null, formatUnitName(unit)]
  }

  return [value, formatUnitName(unit)]
}
