import { FC } from 'react'

const SdgCleanEnergyIcon: FC = () => (
  <>
    <path
      d="M27.82 13.8789L18.94 23.9989H29.06L21.28 34.1189"
      stroke="white"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M30.6 37.1992V45.9992H39.4"
      stroke="white"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M30.6 2C39.62 5.3 46 13.54 46 23.18C46 32.82 39.62 41.04 30.6 44.36"
      stroke="white"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.4 10.8V2H8.59998"
      stroke="white"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.4 46.0006C8.38 42.7006 2 34.4606 2 24.8206C2 15.1806 8.38 6.96062 17.4 3.64062"
      stroke="white"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
)

export default SdgCleanEnergyIcon
