import { FC } from 'react'

const EmailIcon: FC = () => (
  <>
    <path
      d="M21 6H3V18H21V6Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21 6L12 13L3 6"
      stroke="currentColor"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
)

export default EmailIcon
