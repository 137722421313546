import { Workbook } from '@netpurpose/types'
import { AxiosInstances } from '../../client'
import { AbstractApi } from '../AbstractApi'
import { parseWorkbook } from './parseWorkbook'

// TODO: why can this not be an AbstractModelApi?
export default class WorkbookApi extends AbstractApi {
  constructor(axiosInstances: AxiosInstances) {
    super(axiosInstances)
    this.getWorkbook = this.getWorkbook.bind(this)
  }

  async getWorkbook(workbookId: number): Promise<Workbook> {
    const { data } = await this.api.get(`/workbooks/${workbookId}`)
    return parseWorkbook(data)
  }
}
