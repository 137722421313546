import { UserFormType, UserRegistrationDetails } from '@netpurpose/types'
import { BackendUserFormType } from './parseUser'

export const formatUser = (user: UserFormType): BackendUserFormType => ({
  active: user.active,
  first_name: user.firstName,
  last_name: user.lastName,
  type: user.type,
  email: user.email,
  group_id: user.group,
  default_metrics_config_id: user.defaultMetricsConfigId,
  last_login: user.lastLogin,
})

type BackendUserRegistrationDetails = {
  first_name: string
  last_name: string
  email: string
  password: string
  token: string
}

export const formatRegistrationDetails = (
  user: UserRegistrationDetails,
): BackendUserRegistrationDetails => ({
  first_name: user.firstName,
  last_name: user.lastName,
  email: user.email,
  password: user.password,
  token: user.token,
})
