import { Kpi } from '@netpurpose/types'
import { convertStringToEnum } from '@netpurpose/utils'
import { DisplayTheme } from '../../generated/facts'
import { DisplayThemeEnum } from '../../generated/facts'
import { formatStandard } from '../../hooks/models/utils'
import { ReverseFieldMap } from '../../queryBuilder'
import { BackendKpi } from './parseKpi'

// @ts-expect-error
export const formatKpi = (kpi: Partial<Kpi>): Partial<BackendKpi> => ({
  kpi_id: kpi.id,
  name: kpi.name,
  description: kpi.description,
  formula: kpi.formula,
  formula_description: kpi.formulaDescription,
  theme: kpi.theme,
  display_themes: kpi.displayThemes?.map((theme) =>
    convertStringToEnum<DisplayTheme>(theme, DisplayThemeEnum),
  ),
  kpi_type: kpi.kpiType,
  unit_id: kpi.unitId,
  active_export: kpi.activeExport,
  question_id: kpi.questionId,
  goal: kpi.goal,
  impact_level: kpi.impactLevel,
  reporting_standards: kpi.reportingStandards?.map(formatStandard),
  is_sdg_eligible: kpi.isSdgEligible,
  display_priority: kpi.displayPriority,
})

export const reverseKpiFieldMap: ReverseFieldMap<keyof Kpi | 'unitName'> = {
  id: 'kpi_id',
  name: 'name',
  description: 'description',
  formula: 'formula',
  formulaDescription: 'formula_description',
  theme: 'theme',
  displayThemes: 'display_themes',
  kpiType: 'kpi_type',
  unitId: 'unit_id',
  unitName: 'unit.name',
  activeExport: 'active_export',
  questionId: 'question_id',
  goal: 'goal',
  impactLevel: 'impact_level',
  reportingStandards: 'reporting_standards',
  isSdgEligible: 'is_sdg_eligible',
  displayPriority: 'display_priority',
}
