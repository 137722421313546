import { FC } from 'react'

const EyeIcon: FC = () => (
  <>
    <path
      d="M21 12C21 12 16.42 18 12 18C7.58 18 3 12 3 12C3 12 7.58 6 12 6C16.42 6 21 12 21 12Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.5 12C12.67 12 12 11.33 12 10.5C12 9.9 12.36 9.38 12.87 9.14C12.59 9.06 12.3 9 12 9C10.34 9 9 10.34 9 12C9 13.66 10.34 15 12 15C13.66 15 15 13.66 15 12C15 11.7 14.94 11.41 14.86 11.13C14.62 11.64 14.1 12 13.5 12Z"
      fill="currentColor"
    />
  </>
)

export default EyeIcon
