import { FC } from 'react'

const LockIcon: FC = () => (
  <svg viewBox="0 0 20 20" fill="none">
    <path
      d="M5.00016 18.3333C4.54183 18.3333 4.14961 18.1703 3.8235 17.8442C3.49738 17.5181 3.33405 17.1256 3.3335 16.6667V8.33333C3.3335 7.875 3.49683 7.48277 3.8235 7.15666C4.15016 6.83055 4.54238 6.66722 5.00016 6.66666H5.8335V5C5.8335 3.84722 6.23989 2.86472 7.05266 2.0525C7.86544 1.24027 8.84794 0.833884 10.0002 0.833328C11.1529 0.833328 12.1357 1.23972 12.9485 2.0525C13.7613 2.86527 14.1674 3.84777 14.1668 5V6.66666H15.0002C15.4585 6.66666 15.851 6.83 16.1777 7.15666C16.5043 7.48333 16.6674 7.87555 16.6668 8.33333V16.6667C16.6668 17.125 16.5038 17.5175 16.1777 17.8442C15.8516 18.1708 15.4591 18.3339 15.0002 18.3333H5.00016ZM5.00016 16.6667H15.0002V8.33333H5.00016V16.6667ZM10.0002 14.1667C10.4585 14.1667 10.851 14.0036 11.1777 13.6775C11.5043 13.3514 11.6674 12.9589 11.6668 12.5C11.6668 12.0417 11.5038 11.6494 11.1777 11.3233C10.8516 10.9972 10.4591 10.8339 10.0002 10.8333C9.54183 10.8333 9.14961 10.9967 8.8235 11.3233C8.49739 11.65 8.33405 12.0422 8.3335 12.5C8.3335 12.9583 8.49683 13.3508 8.8235 13.6775C9.15016 14.0042 9.54238 14.1672 10.0002 14.1667ZM7.50016 6.66666H12.5002V5C12.5002 4.30555 12.2571 3.71527 11.771 3.22916C11.2849 2.74305 10.6946 2.49999 10.0002 2.49999C9.30572 2.49999 8.71544 2.74305 8.22933 3.22916C7.74322 3.71527 7.50016 4.30555 7.50016 5V6.66666Z"
      fill="currentColor"
    />
  </svg>
)

export default LockIcon
