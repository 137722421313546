import { Kpi, KpiType, QuestionGoal, QuestionImpactLevel } from '@netpurpose/types'
import { DisplayTheme, KPI } from '../../generated/facts'
import { ReverseFieldMap } from '../../queryBuilder'
import { Camelize } from '../../utils'
import { BackendStandard, parseStandard } from '../standard'

export type BackendKpi = {
  kpi_id: string
  name: string
  description: string | null
  formula: string
  formula_description: string | null
  theme: string
  display_themes: DisplayTheme[]
  kpi_type: KpiType | null
  unit_id: number | null
  active_export: boolean
  question_id: number
  goal: QuestionGoal | null
  impact_level: QuestionImpactLevel | null
  reporting_standards: BackendStandard[] | null
  is_sdg_eligible: boolean
  display_priority: number | null
}

export const parseKpi = (kpi: BackendKpi): Kpi => ({
  id: kpi.kpi_id,
  name: kpi.name,
  description: kpi.description,
  formula: kpi.formula,
  formulaDescription: kpi.formula_description,
  theme: kpi.theme,
  displayThemes: kpi.display_themes,
  kpiType: kpi.kpi_type,
  unitId: kpi.unit_id,
  activeExport: kpi.active_export,
  questionId: kpi.question_id,
  goal: kpi.goal,
  impactLevel: kpi.impact_level,
  reportingStandards: kpi.reporting_standards?.map(parseStandard) || [],
  isSdgEligible: kpi.is_sdg_eligible,
  displayPriority: kpi.display_priority,
})

export const transformKpi = <KpiProps extends Camelize<KPI>>({ kpiId, ...rest }: KpiProps) => ({
  id: kpiId,
  ...rest,
})

export const kpiTableToApiFieldMap: ReverseFieldMap<keyof Kpi & { unitName: string }> = {
  questionId: 'question_id',
  id: 'kpi_id',
  name: 'name',
  description: 'description',
  formula: 'formula',
  theme: 'theme',
  displayThemes: 'display_themes',
  kpiType: 'kpi_type',
  unitId: 'unit_id',
  unitName: 'unit.name',
  activeExport: 'active_export',
  displayPriority: 'display_priority',
}
