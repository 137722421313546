import styled from 'styled-components'

export const SpinContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  > .ant-spin {
    display: flex;
  }

  .ant-spin-dot-item {
    background-color: ${({ theme }) => theme.colors.typeSecondary};
  }
`
