import { FC } from 'react'

const HelpIcon: FC = () => (
  <>
    <path
      d="M12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9 10C9 8.34 10.34 7 12 7C13.66 7 15 8.34 15 10C15 11.66 13.66 13 12 13"
      stroke="currentColor"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 13V15"
      stroke="currentColor"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 18.9992C11.74 18.9992 11.48 18.8892 11.29 18.7092C11.11 18.5192 11 18.2592 11 17.9992C11 17.7392 11.11 17.4792 11.29 17.2892C11.66 16.9192 12.33 16.9192 12.71 17.2892C12.89 17.4792 13 17.7392 13 17.9992C13 18.2592 12.89 18.5192 12.71 18.7092C12.52 18.8892 12.26 18.9992 12 18.9992Z"
      fill="black"
    />
  </>
)

export default HelpIcon
