import firebase from 'firebase/compat/app'
import { FC, useEffect } from 'react'
import { routes } from '#config'

export const CurrentUserProvider: FC<{ children: React.ReactNode }> = ({ children }) => {
  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      const { location } = window
      if (!user && location.pathname !== routes.login) {
        // Need to use location.assign rather than router.push, as the latter
        // causes document.referrer to be lost.
        location.assign(`${location.origin}/login`)
      }
    })
  }, [])

  return children
}
