import { ById } from '../utils'
import { User } from './user'

export enum SourceFileType {
  PDF = 'pdf',
  CDP = 'cdp',
  Factset = 'factset',
  Excel = 'xlsx',
  Website = 'website',
  Other = 'other',
}

export enum SourceReportType {
  AcademicStudy = 'Academic Study',
  AnnualReport = 'Annual Report',
  CDPReport = 'CDP Report',
  CitizenshipReport = 'Citizenship Report',
  CompanySponsoredStudy = 'Company Sponsored Study',
  CorporateGovernance = 'Corporate Governance',
  CRReport = 'CR Report',
  CSRReport = 'CSR Report',
  DiversityReport = 'Diversity Report',
  EarningsTranscript = 'Earnings Transcript',
  EnviromentReport = 'Environment Report',
  ESGReport = 'ESG Report',
  Factset = 'factset', // internal only
  FinancialStatement = 'Financial Statement',
  GenderPayGapReport = 'Gender Pay Gap Report',
  GRIIndex = 'GRI Index',
  ImpactReport = 'Impact Report',
  IndustryReport = 'Industry Report',
  IntegratedReport = 'Integrated Report',
  InvestorPresentation = 'Investor Presentation',
  InvestorReport = 'Investor Report',
  PressRelease = 'Press Release',
  ProxyStatement = 'Proxy Statement',
  NGOGovernmentWebsite = 'NGO/Government Website',
  Other = 'Other',
  ProductSpecification = 'Product Specification',
  QuarterlyReport = 'Quarterly Report',
  TenK = '10-K',
  TwentyF = '20-F',
  ScientificJournal = 'Scientific Journal',
  SustainabilityReport = 'Sustainability Report',
  VerificationStatement = 'Verification Statement',
  Website = 'Website',
  WriteUp = 'Write Up',
  Factsheet = 'Factsheet',
  SASBIndex = 'SASB Index',
  BlogArticle = 'Blog Article',
  CompanyWebsite = 'Company Website',
  MerchantWebsite = 'Merchant Website',
  NewsArticle = 'News Article',
  UNGCCommunicationOnProgress = 'UNGC Communication on Progress',
  UNGCLetterOfCommitment = 'UNGC Letter of Commitment',
}

export type ReportTypeOccurrences = Partial<Record<SourceReportType, number>>

export type SourcePredictions = {
  modelId: string
  confidencePerLabel: Record<string, number>
}

export type PageConfidence = {
  pageNumber: number | undefined
  confidence: number | undefined
}

export type PredictionSummary = Partial<PageConfidence> & {
  numPredictions: number
  currentPredictionIndex: number
  allPredictions: PageConfidence[]
}

export type PagePredictions = {
  modelId: string
  topPagesPerLabel: Record<string, PageConfidence[]>
}

export type Source = {
  id: number
  name: string
  originalUrl: string | undefined
  url: string
  fileType: SourceFileType
  thumbnailUrl?: string
  start?: Date
  end?: Date
  publishingDate?: Date
  reportType?: SourceReportType
  checksum?: string
  entityId?: number
  predictions?: SourcePredictions | undefined
  numUniqueEntityOccurrences?: number | undefined
  status?: SourceStatus
}

export type SourcesById = ById<Source>

export type SourceWithSearchStatus = Source & { isSearched?: boolean }
export type SourcesWithSearchStatusById = ById<SourceWithSearchStatus>

export enum SourceTaskState {
  Blocked = 'BLOCKED',
  Pending = 'PENDING',
  InProgress = 'IN_PROGRESS',
  Complete = 'COMPLETE',
  Archived = 'ARCHIVED',
}

export enum SourceTaskStep {
  Extract = 'EXTRACT',
  Verify = 'VERIFY',
}

export enum SourceStatus {
  Candidate = 'CANDIDATE',
  CheckEntity = 'CHECK ENTITY',
  Extracted = 'EXTRACTED',
  New = 'NEW',
  Rejected = 'REJECTED',
  Verified = 'VERIFIED',
}

export enum SourceTaskType {
  NewEntity = 'NEW_ENTITY',
  Backfill = 'BACKFILL',
  Update = 'UPDATE',
}

export type SearchLink = {
  url: string
  entityName: string
  year: number
  reportType: string
  description: string
}

export type SourceBatch = {
  id: string
  phase: number
  step: SourceTaskStep
  state: SourceTaskState
  totalTasks: number
  completedTasks: number
  outstandingTasks: number
  totalTimeSpent: number
  expectedTimeRemaining: number
  entityId: number
  priority: number
}

export type SourceBatchWithTasks = SourceBatch & {
  tasks: SourceTask[]
}

export type SourceBatchesById = ById<SourceBatch>

export type SourceTask = {
  id: number
  batchId: SourceBatch['id']
  entityId: number
  entityName?: string
  priority: number
  state: SourceTaskState
  years: number[]
  userId?: User['id']
  username?: string
  step: SourceTaskStep
  expectedTimeRemaining: number
  totalTimeSpent?: number
  searchLinks: SearchLink[]
  message: string
  type: SourceTaskType
  sources?: Source[]
}

export type SourceTaskWithWorkflow = SourceTask & {
  workflow: 'source'
}

export type SourceTasksById = ById<SourceTask>

export type NextSourceTask = {
  taskId?: SourceTask['id']
  batchId?: SourceBatch['id']
  noNextTask?: boolean
}

export enum SourceUploadMode {
  Url = 'url',
  File = 'file',
}

interface BaseSourceUpload {
  sourceType: SourceFileType
  fileName: string
  reportType: SourceReportType
  startDate: Date | undefined
  endDate: Date
  publishingDate?: Date
  entityId: number | undefined
  sourceUrl: string | undefined
  status: SourceStatus | undefined
}

export interface UrlSourceUpload extends BaseSourceUpload {
  mode: SourceUploadMode.Url
}

export interface FileSourceUpload extends BaseSourceUpload {
  mode: SourceUploadMode.File
  sourceFile: File
}

export type SourceUpload = UrlSourceUpload | FileSourceUpload

export type SourceWithEntityName = Source & {
  entityName?: string
  searchTerm?: string
}
