import {
  MetricLegacy,
  MetricPolymorph,
  MetricPolymorphs,
  MetricType,
  QuestionGoal,
  QuestionImpactLevel,
  SimpleMetric,
} from '@netpurpose/types'
import { DisplayTheme } from '../../generated/facts'
import { ReverseFieldMap } from '../../queryBuilder'
import { BackendKeyword, parseKeyword } from '../keyword'
import { BackendStandard, parseStandard } from '../standard'

export enum BackendMetricPolymorphs {
  Simple = 'Simple metric',
  Major = 'Metric',
}

type BaseBackendMetric = {
  metric_id: string
  name: string
  display_name: string
  question_id: number
  is_estimated: boolean
  is_reported: boolean
  display_priority: number | null
}

export type BackendSimpleMetric = BaseBackendMetric & {
  // unit_id and description can be null for a simple metric, but are always
  // required for a major metric.
  unit_id: number | null
  description: string | null
  type: BackendMetricPolymorphs.Simple
}

export type BackendMetric = BaseBackendMetric & {
  type: BackendMetricPolymorphs.Major
  unit_id: number
  description: string
  instructions?: string
  themes: string[]
  display_themes: DisplayTheme[]
  active_export?: boolean
  active_extraction?: boolean
  extraction_priority?: number
  metric_type: MetricType
  keywords?: BackendKeyword[]
  reporting_standards: BackendStandard[] | null
  goal: QuestionGoal
  impact_level: QuestionImpactLevel
  is_sdg_eligible?: boolean
}

export type BackendMetricPolymorph = BackendSimpleMetric | BackendMetric

const parseSimpleMetric = (metric: BackendMetricPolymorph): SimpleMetric => ({
  id: metric.metric_id,
  name: metric.name,
  // Simple metrics do have a display name, but can't be edited so will always
  // be the same as the name, unlike for a major metric which can be edited to
  // be something different.
  displayName: metric.display_name,
  description: metric.description ?? undefined,
  unitId: metric.unit_id ?? undefined,
  type: MetricPolymorphs.Simple,
  questionId: metric.question_id,
  isEstimated: metric.is_estimated,
  isReported: metric.is_reported,
  displayPriority: metric.display_priority,
})

// @ts-expect-error
export const parseMajorMetric = (metric: BackendMetric): MetricLegacy => ({
  ...parseSimpleMetric(metric),
  type: MetricPolymorphs.Major,
  description: metric.description,
  unitId: metric.unit_id,
  themes: metric.themes,
  displayThemes: metric.display_themes,
  metricType: metric.metric_type,
  instructions: metric.instructions,
  activeExport: metric.active_export,
  activeExtraction: metric.active_extraction,
  extractionPriority: metric.extraction_priority,
  keywords: metric.keywords?.map(parseKeyword),
  reportingStandards: metric.reporting_standards?.map(parseStandard) || [],
  goal: metric.goal,
  impactLevel: metric.impact_level,
  isSdgEligible: metric.is_sdg_eligible,
})

export const parseMetric = (metric: BackendMetricPolymorph): MetricPolymorph => {
  switch (metric.type) {
    case BackendMetricPolymorphs.Simple:
      return parseSimpleMetric(metric)
    case BackendMetricPolymorphs.Major:
      return parseMajorMetric(metric)
    default:
      throw new Error('Unknown metric type')
  }
}

export const reverseMetricFieldMap: ReverseFieldMap<keyof MetricLegacy | 'unitName'> = {
  id: 'metric_id',
  name: 'name',
  displayName: 'display_name',
  description: 'description',
  instructions: 'instructions',
  themes: 'themes',
  displayThemes: 'display_themes',
  keywords: 'keywords.text',
  activeExport: 'active_export',
  activeExtraction: 'active_extraction',
  extractionPriority: 'extraction_priority',
  metricType: 'metric_type',
  unitName: 'unit.name',
  reportingStandards: 'reporting_standards',
  type: 'type',
  unitId: 'unit_id',
  questionId: 'question_id',
  isEstimated: 'is_estimated',
  isReported: 'is_reported',
  goal: 'goal',
  impactLevel: 'impact_level',
  isSdgEligible: 'is_sdg_eligible',
  displayPriority: 'display_priority',
}
