import { Keyword } from '@netpurpose/types'
import { ReverseFieldMap } from '../../queryBuilder'

export type BackendKeyword = {
  keyword_id: number
  text: string
}

export const parseKeyword = (keyword: BackendKeyword): Keyword => ({
  id: keyword.keyword_id,
  text: keyword.text,
})

export const reverseKeywordFieldMap: ReverseFieldMap<keyof Keyword> = {
  id: 'keyword_id',
  text: 'text',
}
