import {
  Context,
  createContext,
  ReactNode,
  Reducer,
  useCallback,
  useContext,
  useReducer,
} from 'react'

type AlertBannerState<BannerType> = {
  isVisible: boolean
  text?: string | undefined
  type?: BannerType | undefined
}

export type IAlertBannerContext<BannerType> = AlertBannerState<BannerType> & {
  showAlertBanner: (type: BannerType, text?: string | undefined) => void
  hideAlertBanner: (type?: BannerType | undefined) => void
}

const defaultState: AlertBannerState<unknown> = { isVisible: false }

export const defaultAlertBannerContext: IAlertBannerContext<unknown> = {
  ...defaultState,
  showAlertBanner: () => {},
  hideAlertBanner: () => {},
}

type Action<BannerType> =
  | { actionType: 'show'; text?: string | undefined; bannerType: BannerType }
  | { actionType: 'hide'; bannerType?: BannerType | undefined }

const reducer = <BannerType,>(
  state: AlertBannerState<BannerType>,
  action: Action<BannerType>,
): AlertBannerState<BannerType> => {
  switch (action.actionType) {
    case 'show':
      return {
        ...state,
        isVisible: true,
        type: action.bannerType,
        text: action.text,
      }
    case 'hide':
      if (action.bannerType && state.type !== action.bannerType) {
        return state
      }
      return {
        ...state,
        isVisible: false,
        text: '',
        type: undefined,
      }
    default:
      throw new Error(`Unhandled action type: ${action}`)
  }
}

export const AlertBannerContext = createContext(defaultAlertBannerContext)

export const AlertBannerProvider = <BannerType,>(props: { children: ReactNode }) => {
  const TypedContext = AlertBannerContext as Context<IAlertBannerContext<BannerType>>

  const [state, dispatch] = useReducer<Reducer<AlertBannerState<BannerType>, Action<BannerType>>>(
    reducer,
    defaultState as AlertBannerState<BannerType>,
  )

  const showAlertBanner: IAlertBannerContext<BannerType>['showAlertBanner'] = useCallback(
    (bannerType, text) => dispatch({ actionType: 'show', bannerType, text }),
    [],
  )

  const hideAlertBanner: IAlertBannerContext<BannerType>['hideAlertBanner'] = useCallback(
    (bannerType) => dispatch({ actionType: 'hide', bannerType }),
    [],
  )

  return <TypedContext.Provider value={{ ...state, showAlertBanner, hideAlertBanner }} {...props} />
}

export const useAlertBanner = <BannerType,>(): IAlertBannerContext<BannerType> => {
  const context = useContext(AlertBannerContext) as IAlertBannerContext<BannerType>
  if (context === defaultAlertBannerContext) {
    throw new Error('useAlertBanner must be used within an AlertBannerProvider')
  }
  return context
}
