import { FC } from 'react'

const SdgWasteIcon: FC = () => (
  <>
    <g transform="translate(4 0)">
      <path
        d="M36 10L34.22 42.22C34.1 44.34 32.34 46 30.22 46H9.80002C7.68002 46 5.92002 44.34 5.80002 42.22L4.02002 10"
        stroke="white"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14 2H26C28.2 2 30 3.8 30 6V10H10V6C10 3.8 11.8 2 14 2Z"
        stroke="white"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2 10H38"
        stroke="white"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </>
)

export default SdgWasteIcon
