import { stringify } from 'qs'
import { AxiosInstances } from '../client'
import { BackendPaginationParams, ReverseFieldMap } from '../queryBuilder'
import { joinPaths } from '../utils'
import { AbstractApi } from './AbstractApi'

export type ListResult<Model> = {
  totalCount: number
  results: Model[]
}

export type PaginatedListResult<Model> = ListResult<Model> & {
  hasNext: boolean
}

export type AdditionalParams = { [key: string]: string | number | boolean }

export abstract class AbstractModelApi<
  BackendListModel,
  BackendDetailModel,
  ListModel extends { id: number } | { id: string },
  DetailModel extends { id: number } | { id: string },
> extends AbstractApi {
  abstract endpoint: string
  abstract listResultParser: (backendModel: BackendListModel) => ListModel
  abstract detailResultParser: (backendModel: BackendDetailModel) => DetailModel
  modelFormatter: (model: Partial<DetailModel>) => Partial<BackendDetailModel | DetailModel> = (
    model,
  ) => model
  abstract reverseFieldMap: ReverseFieldMap<keyof ListModel | keyof DetailModel> | undefined

  constructor(axiosInstances: AxiosInstances) {
    super(axiosInstances)
    this.create = this.create.bind(this)
    this.update = this.update.bind(this)
    this.delete = this.delete.bind(this)
    this.getPaginatedList = this.getPaginatedList.bind(this)
    this.getListFromPaginatedApi = this.getListFromPaginatedApi.bind(this)
    this.getById = this.getById.bind(this)
    this.getListByIds = this.getListByIds.bind(this)
  }

  async create(model: Partial<DetailModel>): Promise<DetailModel> {
    const { data } = await this.api.post<BackendDetailModel>(
      this.endpoint,
      this.modelFormatter(model),
    )
    return this.detailResultParser(data)
  }

  async update(
    id: DetailModel['id'],
    model: Partial<DetailModel>,
    params: AdditionalParams = {},
  ): Promise<DetailModel> {
    const { data } = await this.api.put<BackendDetailModel>(
      joinPaths(this.endpoint, id),
      this.modelFormatter(model),
      { params },
    )
    return this.detailResultParser(data)
  }

  async delete(id: DetailModel['id'], params: AdditionalParams = {}): Promise<true> {
    await this.api.delete(joinPaths(this.endpoint, id), { params })
    return true
  }

  async getPaginatedList<Params extends BackendPaginationParams>(
    params: Params,
    additionalParams: AdditionalParams,
  ): Promise<PaginatedListResult<ListModel>> {
    const {
      data: { total, results },
    } = await this.queryBuilderApi.get<{ total: number; results: BackendListModel[] }>(
      this.endpoint,
      {
        params: {
          ...params,
          ...additionalParams,
        },
      },
    )

    const hasNext = total - (params.page + 1) * params.limit > 0
    return {
      totalCount: total,
      results: results.map(this.listResultParser),
      hasNext,
    }
  }

  async getListFromPaginatedApi(params?: Record<string, unknown>): Promise<ListResult<ListModel>> {
    const {
      data: { total, results },
    } = await this.queryBuilderApi.get(this.endpoint, {
      // In general, we want everything from the backend so default to high number (can be overridden)
      params: { limit: 9999, ...params },
    })

    return {
      totalCount: total,
      results: results.map(this.listResultParser),
    }
  }

  async getById(
    id: DetailModel['id'] | undefined,
    featureHeader: { [key: string]: string } = {},
    params: AdditionalParams = {},
  ): Promise<DetailModel> {
    if (typeof id === 'undefined') {
      return Promise.reject(new Error(`Invalid id of ${id}`))
    }
    const hasParams = Object.entries(params).length
    const { data } = await this.queryBuilderApi.get<BackendDetailModel>(
      `${joinPaths(this.endpoint, id)}${hasParams ? `?${stringify(params)}` : ''}`,
      { headers: featureHeader },
    )
    return this.detailResultParser(data)
  }

  async getListByIds(
    ids: ListModel['id'][],
    additionalParams: AdditionalParams = {},
  ): Promise<ListModel[]> {
    const params = ids.length
      ? { ids, limit: ids.length, ...additionalParams }
      : { limit: 9999, ...additionalParams }

    const { data } = await this.queryBuilderApi.get<{ results?: BackendListModel[] }>(
      this.endpoint,
      { params },
    )
    return (data.results || []).map((d) => this.listResultParser(d))
  }
}
