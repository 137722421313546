import { FC } from 'react'

const ModeBrightIcon: FC = () => (
  <>
    <path
      d="M12 17C14.7614 17 17 14.7614 17 12C17 9.23858 14.7614 7 12 7C9.23858 7 7 9.23858 7 12C7 14.7614 9.23858 17 12 17Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 3.99922C11.74 3.99922 11.48 3.88922 11.29 3.70922C11.11 3.51922 11 3.25922 11 2.99922C11 2.73922 11.11 2.47922 11.29 2.28922C11.66 1.91922 12.33 1.91922 12.71 2.28922C12.89 2.47922 13 2.73922 13 2.99922C13 3.06922 12.99 3.12922 12.98 3.19922C12.97 3.25922 12.95 3.31922 12.92 3.37922C12.9 3.43922 12.87 3.49922 12.83 3.54922C12.79 3.60922 12.75 3.65922 12.71 3.70922C12.52 3.88922 12.26 3.99922 12 3.99922Z"
      fill="currentColor"
    />
    <path
      d="M7.5 5.20929C7.24 5.20929 6.98 5.09929 6.79 4.90929C6.61 4.72929 6.5 4.46929 6.5 4.20929C6.5 3.93929 6.61 3.67929 6.79 3.49929C7.03 3.26929 7.37 3.15929 7.7 3.21929C7.76 3.23929 7.82 3.25929 7.88 3.27929C7.94 3.30929 8 3.33929 8.05 3.36929C8.11 3.40929 8.16 3.44929 8.21 3.49929C8.25 3.53929 8.29 3.59929 8.33 3.64929C8.37 3.70929 8.4 3.75929 8.42 3.81929C8.45 3.87929 8.47 3.94929 8.48 4.00929C8.49 4.06929 8.5 4.13929 8.5 4.20929C8.5 4.46929 8.39 4.72929 8.21 4.90929C8.02 5.09929 7.76 5.20929 7.5 5.20929Z"
      fill="currentColor"
    />
    <path
      d="M4.21094 8.49922C3.94094 8.49922 3.68094 8.38922 3.50094 8.20922C3.31094 8.01922 3.21094 7.75922 3.21094 7.49922C3.21094 7.23922 3.31094 6.97922 3.50094 6.78922C3.87094 6.41922 4.54094 6.41922 4.91094 6.78922C5.10094 6.97922 5.21094 7.23922 5.21094 7.49922C5.21094 7.75922 5.10094 8.01922 4.91094 8.20922C4.73094 8.38922 4.47094 8.49922 4.21094 8.49922Z"
      fill="currentColor"
    />
    <path
      d="M3 12.9986C2.87 12.9986 2.74 12.9686 2.62 12.9186C2.49 12.8686 2.39 12.7986 2.29 12.7086C2.11 12.5186 2 12.2586 2 11.9986C2 11.8686 2.03 11.7386 2.08 11.6186C2.13 11.4986 2.2 11.3886 2.29 11.2886C2.39 11.1986 2.5 11.1286 2.62 11.0786C2.86 10.9686 3.14 10.9686 3.38 11.0786C3.5 11.1286 3.61 11.1986 3.71 11.2886C3.8 11.3886 3.87 11.4986 3.92 11.6186C3.98 11.7386 4 11.8686 4 11.9986C4 12.0686 3.99 12.1286 3.98 12.1986C3.97 12.2586 3.95 12.3186 3.92 12.3786C3.9 12.4386 3.87 12.4986 3.83 12.5586C3.79 12.6086 3.75 12.6586 3.71 12.7086C3.61 12.7986 3.5 12.8686 3.38 12.9186C3.26 12.9686 3.13 12.9986 3 12.9986Z"
      fill="currentColor"
    />
    <path
      d="M4.21094 17.4989C3.94094 17.4989 3.68094 17.3889 3.50094 17.2089C3.41094 17.1089 3.33094 16.9989 3.28094 16.8789C3.23094 16.7589 3.21094 16.6289 3.21094 16.4989C3.21094 16.2389 3.31094 15.9789 3.50094 15.7889C3.78094 15.5089 4.22094 15.4189 4.59094 15.5789C4.71094 15.6289 4.82094 15.6989 4.91094 15.7889C5.10094 15.9789 5.21094 16.2389 5.21094 16.4989C5.21094 16.6289 5.18094 16.7589 5.13094 16.8789C5.08094 16.9989 5.01094 17.1089 4.91094 17.2089C4.82094 17.2989 4.71094 17.3689 4.59094 17.4189C4.47094 17.4689 4.34094 17.4989 4.21094 17.4989Z"
      fill="currentColor"
    />
    <path
      d="M7.5 20.8009C7.24 20.8009 6.98 20.6909 6.79 20.5009C6.61 20.3209 6.5 20.0609 6.5 19.7909C6.5 19.6609 6.53 19.5309 6.58 19.4109C6.63 19.2909 6.7 19.1809 6.79 19.0909C6.89 18.9909 6.99 18.9209 7.12 18.8709C7.3 18.8009 7.5 18.7709 7.7 18.8109C7.76 18.8309 7.82 18.8509 7.88 18.8709C7.94 18.9009 8 18.9309 8.06 18.9609C8.11 19.0009 8.16 19.0409 8.21 19.0909C8.3 19.1809 8.37 19.2909 8.42 19.4109C8.48 19.5409 8.5 19.6609 8.5 19.7909C8.5 19.8609 8.49 19.9309 8.48 19.9909C8.47 20.0509 8.45 20.1209 8.42 20.1809C8.4 20.2409 8.37 20.3009 8.33 20.3509C8.29 20.4009 8.25 20.4609 8.21 20.5009C8.16 20.5509 8.11 20.5909 8.06 20.6309C8 20.6609 7.94 20.6909 7.88 20.7209C7.82 20.7409 7.76 20.7609 7.7 20.7809C7.63 20.7909 7.57 20.8009 7.5 20.8009Z"
      fill="currentColor"
    />
    <path
      d="M12 21.9984C11.93 21.9984 11.87 21.9884 11.8 21.9784C11.74 21.9684 11.68 21.9484 11.62 21.9184C11.56 21.8984 11.5 21.8684 11.44 21.8284C11.39 21.7884 11.34 21.7483 11.29 21.7083C11.25 21.6583 11.21 21.6083 11.17 21.5583C11.13 21.4984 11.1 21.4383 11.08 21.3783C11.05 21.3183 11.03 21.2584 11.02 21.1984C11.01 21.1284 11 21.0684 11 20.9984C11 20.7384 11.11 20.4784 11.29 20.2884C11.57 20.0084 12.02 19.9284 12.38 20.0784C12.51 20.1284 12.61 20.1984 12.71 20.2884C12.8 20.3884 12.87 20.4984 12.92 20.6184C12.97 20.7384 13 20.8684 13 20.9984C13 21.0684 12.99 21.1284 12.98 21.1984C12.97 21.2584 12.95 21.3183 12.92 21.3783C12.9 21.4383 12.87 21.4984 12.83 21.5583C12.8 21.6083 12.75 21.6583 12.71 21.7083C12.61 21.7983 12.5 21.8684 12.38 21.9184C12.26 21.9784 12.13 21.9984 12 21.9984Z"
      fill="currentColor"
    />
    <path
      d="M16.5 20.7988C16.44 20.7988 16.37 20.7888 16.3 20.7788C16.24 20.7588 16.18 20.7388 16.12 20.7188C16.06 20.6888 16 20.6588 15.94 20.6288C15.89 20.5888 15.84 20.5488 15.79 20.4988C15.75 20.4588 15.7 20.3988 15.67 20.3488C15.63 20.2988 15.6 20.2388 15.58 20.1788C15.55 20.1188 15.53 20.0488 15.52 19.9888C15.51 19.9288 15.5 19.8588 15.5 19.7888C15.5 19.6588 15.53 19.5288 15.58 19.4088C15.63 19.2888 15.7 19.1788 15.79 19.0888C16.07 18.8088 16.51 18.7188 16.88 18.8688C17.01 18.9188 17.11 18.9888 17.21 19.0888C17.3 19.1788 17.37 19.2888 17.42 19.4088C17.47 19.5288 17.5 19.6588 17.5 19.7888C17.5 20.0588 17.39 20.3188 17.21 20.4988C17.02 20.6888 16.76 20.7988 16.5 20.7988Z"
      fill="currentColor"
    />
    <path
      d="M19.8008 17.5009C19.5308 17.5009 19.2708 17.3909 19.0908 17.2109C19.0008 17.1109 18.9208 17.0009 18.8708 16.8809C18.8208 16.7609 18.8008 16.6309 18.8008 16.5009C18.8008 16.2409 18.9008 15.9809 19.0908 15.7909C19.3208 15.5609 19.6708 15.4509 19.9908 15.5209C20.0508 15.5309 20.1208 15.5509 20.1808 15.5809C20.2408 15.6009 20.3008 15.6309 20.3508 15.6709C20.4008 15.7009 20.4608 15.7509 20.5008 15.7909C20.5508 15.8409 20.5908 15.8909 20.6308 15.9409C20.6608 16.0009 20.6908 16.0609 20.7208 16.1209C20.7408 16.1809 20.7608 16.2409 20.7808 16.3009C20.7908 16.3709 20.8008 16.4309 20.8008 16.5009C20.8008 16.6309 20.7708 16.7609 20.7208 16.8809C20.6708 17.0009 20.5908 17.1109 20.5008 17.2109C20.4608 17.2509 20.4008 17.2909 20.3508 17.3309C20.3008 17.3709 20.2408 17.4009 20.1808 17.4209C20.1208 17.4509 20.0508 17.4709 19.9908 17.4809C19.9308 17.4909 19.8608 17.5009 19.8008 17.5009Z"
      fill="currentColor"
    />
    <path
      d="M21 13.0009C20.74 13.0009 20.48 12.8909 20.29 12.7109C20.11 12.5209 20 12.2609 20 12.0009C20 11.8709 20.03 11.7409 20.08 11.6209C20.13 11.5009 20.2 11.3909 20.29 11.2909C20.52 11.0609 20.87 10.9509 21.2 11.0209C21.26 11.0309 21.32 11.0509 21.38 11.0809C21.44 11.1009 21.5 11.1309 21.56 11.1709C21.61 11.2109 21.66 11.2509 21.71 11.2909C21.8 11.3909 21.87 11.5009 21.92 11.6209C21.97 11.7409 22 11.8709 22 12.0009C22 12.2609 21.89 12.5209 21.71 12.7109C21.52 12.8909 21.26 13.0009 21 13.0009Z"
      fill="currentColor"
    />
    <path
      d="M19.7991 8.49922C19.5291 8.49922 19.2691 8.38922 19.0891 8.20922C18.8991 8.01922 18.7891 7.75922 18.7891 7.49922C18.7891 7.23922 18.8991 6.97922 19.0891 6.78922C19.4591 6.41922 20.1291 6.41922 20.4991 6.78922C20.6891 6.97922 20.7891 7.23922 20.7891 7.49922C20.7891 7.75922 20.6891 8.01922 20.4991 8.20922C20.3191 8.38922 20.0591 8.49922 19.7991 8.49922Z"
      fill="currentColor"
    />
    <path
      d="M16.5 5.21001C16.24 5.21001 15.98 5.10001 15.79 4.91001C15.61 4.73001 15.5 4.47001 15.5 4.21001C15.5 3.94001 15.61 3.68001 15.79 3.50001C16.17 3.13001 16.83 3.12001 17.21 3.50001C17.25 3.54001 17.29 3.60001 17.33 3.65001C17.37 3.71001 17.4 3.76001 17.42 3.82001C17.45 3.88001 17.47 3.95001 17.48 4.01001C17.49 4.07001 17.5 4.14001 17.5 4.21001C17.5 4.47001 17.39 4.73001 17.21 4.91001C17.11 5.01001 17 5.08001 16.88 5.13001C16.76 5.18001 16.63 5.21001 16.5 5.21001Z"
      fill="currentColor"
    />
  </>
)

export default ModeBrightIcon
