export type FiltersOperator = 'and' | 'or'
export enum Operator {
  Contains = '%',
  DoesNotContain = '~',
  Equals = '',
  DoesNotEqual = '!',
  Before = 'before',
  After = 'after',
  GreaterThan = '>',
  LessThan = '<',
  Overlaps = ':',
}

export type Filter<T> = {
  operator: Operator
  value: T
}

export type Filters<Model> = {
  [Key in keyof Model]?: Filter<Model[Key]>[]
}

// keeping sorting separate simplifies null checks and backend formatting
export type FilterConfig<T> = {
  filters: Filters<T>
  setFilters: <FilterValue>(column: keyof T, filters: Filter<FilterValue>[]) => void
  clearFilter: (column: keyof T) => void
  clearAllFilters: () => void
  sorting: Sorting<T>
  sortOrder: SortOrder<T>
  setSortDirection: (column: keyof T, direction: SortDirection | null) => void
  isActive: (column: keyof T) => boolean
}

export enum SortDirection {
  ASC = 'Ascending',
  DESC = 'Descending',
}

export type Sorting<T> = Partial<Record<keyof T, SortDirection>>

export type SortOrder<T> = (keyof T)[]
