import { KpisByQuestionId } from './kpi'
import { MetricsByQuestionId } from './metric'
import { Reference } from './reference'
import { UnitsByIdLegacy } from './unit'

export enum DatapointStatus {
  Created = 'created',
  Accepted = 'accepted',
}

export enum DatapointType {
  Datapoint = 'Datapoint',
  Revenue = 'Revenue',
  Output = 'Output',
  MacroFactor = 'Macro Factor',
  OutcomeLevel1 = 'Outcome Level 1',
  OutcomeLevel2 = 'Outcome Level 2',
  TechnologyCost = 'Technology Cost',
  Impact = 'Impact',
}

export type Datapoint = {
  id: string
  type: DatapointType
  value: number
  raw: boolean
  reportedValue?: number | undefined
  unitId?: number
  reportedUnitId?: number | undefined
  status: DatapointStatus
  reference?: Partial<Reference> | undefined
  originalWorkbookId?: number | undefined
  cell?: string | undefined
  formula?: string | undefined
  readOnly?: boolean
  isPrimaryCost?: boolean
  name?: string
  // Can't import from @netpurpose/api but the type here is ImpactThemesEnum
  impactTheme?: string
  reportingEndDate?: string
  questionId?: number | null
  fxRateUsed?: number | null
  fxRateDate?: string | null
}

export type TechnologyForCost = {
  id: string
  name: string
  baseline?: boolean
  taxonId: number
}

export type TechnologyCost = Datapoint & {
  type: DatapointType.TechnologyCost
  numeratorUnitId: number
  numeratorReportedUnitId?: number | undefined
  denominatorUnitId: number
  denominatorReportedUnitId?: number | undefined
  technology: TechnologyForCost | undefined
  isPrimaryCost: boolean
  impactQuestionId?: number | undefined
}

export type MacroFactorLegacy = Datapoint & {
  type: DatapointType.MacroFactor
  domainName: string
  macroFactorName: string
  taxonId: number | null
}

export type MacroFactorFilters = MacroFactorLegacy & {
  'macroFactor.name': string
  'macroFactor.domainName': string
  'macroFactor.taxonId': number
}

export type DatapointPolymorph = Datapoint | TechnologyCost | MacroFactorLegacy

export type MaybeDatapoint = {
  datapoint: DatapointPolymorph | null
}

// A type with guaranteed id and type which is building a DatapointPolymorph
export type ProtoDatapointPolymorph = {
  id: string
  type: DatapointType
} & Partial<DatapointPolymorph>

// As ProtoDatapointPolymorph, can guarantee id and type as being defined,
// but all other properties are optional.
export type PartialDatapoint = Partial<Datapoint> & {
  id: string
  type: DatapointType
}

export type PartialTechnologyCost = Partial<TechnologyCost> & {
  id: string
  type: DatapointType.TechnologyCost
}

export type PartialMacroFactor = Partial<MacroFactorLegacy> & {
  id: string
  type: DatapointType.MacroFactor
}

export type PartialDatapointPolymorph =
  | PartialDatapoint
  | PartialTechnologyCost
  | PartialMacroFactor

export type DatapointRelatedMaps = {
  unitsById: UnitsByIdLegacy
  metricsByQuestionId: MetricsByQuestionId
  kpisByQuestionId: KpisByQuestionId
}

export type DatapointValidationErrors = Record<PartialDatapointPolymorph['id'], string[]>

type WorkbookOnlyProperties = {
  isAnswer: boolean
}

export type WorkbookDatapoint = PartialDatapoint & WorkbookOnlyProperties

export type WorkbookTechnologyCost = PartialTechnologyCost & WorkbookOnlyProperties

export type WorkbookMacroFactor = PartialMacroFactor & WorkbookOnlyProperties

export type WorkbookDatapointPolymorph =
  | WorkbookDatapoint
  | WorkbookTechnologyCost
  | WorkbookMacroFactor

type UnsubmittedWorkbookDatapoint = Omit<PartialDatapoint, 'id'> & WorkbookOnlyProperties

type UnsubmittedWorkbookTechnologyCost = Omit<PartialTechnologyCost, 'id'> & WorkbookOnlyProperties

type UnsubmittedWorkbookMacroFactor = Omit<PartialMacroFactor, 'id'> & WorkbookOnlyProperties

export type UnsubmittedWorkbookDatapointPolymorph =
  | UnsubmittedWorkbookDatapoint
  | UnsubmittedWorkbookTechnologyCost
  | UnsubmittedWorkbookMacroFactor

export type DatapointPostSubmitAction = (submittedDatapoint: WorkbookDatapointPolymorph) => void
