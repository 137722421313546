import { ById } from '../utils'

export enum PermissionsResourceType {
  IngestionTask = 'ingestion_task',
  SourceTask = 'source_task',
  Technologies = 'technologies',
  EstimationsData = 'estimations_data',
  Batch = 'batch',
  Fact = 'fact',
  KPI = 'kpi',
  Users = 'users',
  AnalystStats = 'analyst_stats',
  Portfolio = 'portfolio',
  PAIsExport = 'pai',
  SDGRevenueAlignment = 'sdg_revenue_alignment',
  Segments = 'segments',
  MetricsConfig = 'metrics_config',
  Entity = 'entity',
  Errors = 'errors',
  ReportedData = 'reported_data',
  Source = 'source',
  Metric = 'metric',
  Unit = 'unit',
  Group = 'group',
  AppFeatures = 'app_features',
}

export type Qualifier = (string | number)[] | boolean | undefined

export type PermissionResource = {
  [action: string]: Qualifier
}

export type Permissions = Partial<{
  [key in PermissionsResourceType]: PermissionResource
}>

export type UserGroup = {
  groupId: string
  name: string
  permissions: Permissions
}

export type UserInfo = {
  userId: string
  email: string
  firstName: string
  lastName: string
  groups: UserGroup[]
  type: UserTypes
  // TODO: update types to UserList and UserDetail
  permissions?: Permissions
  defaultMetricsConfigId: string
  active: boolean
  lastLogin: Date | null
  combinedName?: string // Field used for filtering, is not returned by backend
}

type PermissionQuery = [PermissionsResourceType, string, ('?' | string)?]

export type ServiceName = 'facts' | 'users'

export type LabelsURIType = {
  service: ServiceName
  path: string
}

export type PermissionsResourceSchema = {
  labels_uri: LabelsURIType | null
  actions: string[]
}

export type PermissionsResourceSchemaStrict = PermissionsResourceSchema & {
  labels_uri: LabelsURIType
}

export type PermissionsSchema = Record<PermissionsResourceType, PermissionsResourceSchema>

export type UserFormType = Pick<
  User,
  'active' | 'firstName' | 'lastName' | 'email' | 'type' | 'defaultMetricsConfigId' | 'lastLogin'
> & {
  group: string
}

export enum UserTypes {
  Internal = 'internal',
  External = 'external',
}

export class User {
  active: boolean

  id: string

  email: string

  firstName: string

  lastName: string

  groups: UserGroup[]

  type: UserTypes

  lastLogin: Date | null

  permissions: Permissions | undefined

  canExtract: boolean

  canAccessErrors: boolean

  canAccessFacts: boolean

  canAccessMetrics: boolean

  canAccessUnits: boolean

  canAccessEntities: boolean

  canAccessSources: boolean

  canAccessSDGRevenueAlignment: boolean

  canAccessSegments: boolean

  canAccessTechnologies: boolean

  canAccessEstimations: boolean

  canAccessLookup: boolean

  canAccessUsers: boolean

  canAccessCreate: boolean

  canAccessGroups: boolean

  canAccessKpis: boolean

  defaultMetricsConfigId: string

  constructor(data: UserInfo) {
    this.active = data.active
    this.id = data.userId
    this.email = data.email
    this.firstName = data.firstName
    this.lastName = data.lastName
    this.lastLogin = data.lastLogin
    this.permissions = data?.permissions
    this.groups = data.groups

    this.type = data.type
    this.canExtract = this.hasAnyPerm([
      [PermissionsResourceType.IngestionTask, 'GET', '?'],
      [PermissionsResourceType.IngestionTask, 'EXTRACT', '?'],
      [PermissionsResourceType.IngestionTask, 'VERIFY', '?'],
      [PermissionsResourceType.IngestionTask, 'QA', '?'],
      [PermissionsResourceType.SourceTask, 'GET', '?'],
      [PermissionsResourceType.SourceTask, 'EXTRACT', '?'],
      [PermissionsResourceType.SourceTask, 'VERIFY', '?'],
      [PermissionsResourceType.SourceTask, 'QA', '?'],
    ])
    this.canAccessErrors = this.hasPerm(PermissionsResourceType.Errors, 'LIST')
    this.canAccessFacts = this.hasAllPerms([
      [PermissionsResourceType.Fact, 'LIST'],
      [PermissionsResourceType.Fact, 'GET'],
    ])
    this.canAccessMetrics = this.hasPerm(PermissionsResourceType.Metric, 'LIST')
    this.canAccessUnits = this.hasPerm(PermissionsResourceType.Unit, 'LIST')
    this.canAccessEntities = this.hasPerm(PermissionsResourceType.Entity, 'LIST')
    this.canAccessSources = this.hasPerm(PermissionsResourceType.Source, 'LIST')
    this.canAccessSegments = this.hasPerm(PermissionsResourceType.Segments, 'LIST')
    this.canAccessTechnologies = this.hasPerm(PermissionsResourceType.Technologies, 'LIST')
    this.canAccessEstimations = this.hasPerm(PermissionsResourceType.EstimationsData, 'LIST')
    this.canAccessSDGRevenueAlignment = this.hasPerm(
      PermissionsResourceType.SDGRevenueAlignment,
      'UPLOAD',
    )
    this.canAccessLookup = this.hasPerm(PermissionsResourceType.Entity, 'LOOKUP')
    this.canAccessCreate = this.hasAnyPerm([
      [PermissionsResourceType.Entity, 'CREATE'],
      [PermissionsResourceType.IngestionTask, 'CREATE'],
      [PermissionsResourceType.SourceTask, 'CREATE'],
    ])
    this.canAccessUsers = this.hasPerm(PermissionsResourceType.Users, 'LIST', '?')
    this.canAccessGroups = this.hasPerm(PermissionsResourceType.Group, 'LIST')
    this.canAccessKpis = this.hasPerm(PermissionsResourceType.KPI, 'LIST')
    this.defaultMetricsConfigId = data.defaultMetricsConfigId
  }

  /**
   * Checks if a given permission set (e.g. user permissions or group permissions) has a resource enabled.
   * Returns undefined if a resource is not specified.
   */
  private static permissionSetHasPerm(
    permissions: Permissions | undefined,
    resource: PermissionsResourceType,
    action: string,
    label?: '?' | string | number,
  ): boolean | undefined {
    const qualifier = permissions?.[resource]?.[action]
    if (qualifier === undefined) {
      return undefined
    }
    if (typeof qualifier === 'boolean') {
      return qualifier
    }
    // from this point we know qualifier is an array
    if (label === undefined) {
      return false
    }
    if (label === '?') {
      return qualifier.length > 0
    }
    return qualifier.includes(label)
  }

  /**
   *  Determines if user has permission, optionally qualified by a label.
   *  No label means unqualified permissions .
   *  Label "?" means wildcard (any label).
   */
  hasPerm(
    resource: PermissionsResourceType,
    action: string,
    label?: '?' | string | number,
  ): boolean {
    const ownPermission = User.permissionSetHasPerm(this.permissions, resource, action, label)
    if (ownPermission !== undefined) {
      return ownPermission
    }
    for (const membership of this.groups) {
      const groupPermission = User.permissionSetHasPerm(
        membership.permissions,
        resource,
        action,
        label,
      )
      if (groupPermission !== undefined) {
        return groupPermission
      }
    }
    return false
  }

  hasAnyPerm(permissions: PermissionQuery[]): boolean {
    return permissions.some((args) => this.hasPerm(...args))
  }

  hasAllPerms(permissions: PermissionQuery[]): boolean {
    return permissions.every((args) => this.hasPerm(...args))
  }

  getLabels(resource: PermissionsResourceType, action: string): (string | number)[] | undefined {
    const qualifier = this.permissions?.[resource]?.[action]
    if (Array.isArray(qualifier)) {
      return qualifier
    }
    return
  }

  getGroupIds(): string[] {
    return this.groups.map((group) => group.groupId)
  }

  getGroupMembership(groupId: string): UserGroup | undefined {
    return this.groups.find((group) => group.groupId === groupId)
  }

  isMemberOfGroup(groupId: string): boolean {
    const membership = this.getGroupMembership(groupId)
    return Boolean(membership)
  }
}

export type UsersById = ById<User>

export type UserRegistrationDetails = {
  email: string
  token: string
  password: string
  firstName: string
  lastName: string
}
