import { ById } from '../utils'
import { DataValue } from './dataValue'
import { MetricLegacy } from './metric'
import { Source } from './source'
import { Theme } from './theme'

export const factErrorTypes = [
  'Inappropriate external comment',
  'Missing segments',
  'Premature update',
  'restatement',
  'Should be found',
  'Should not have been found',
  'Wrong aggregation',
  'Wrong derivation status',
  'Wrong end time',
  'Wrong page selected',
  'Wrong qualifier used',
  'Wrong reporting period',
  'Wrong segments',
  'Wrong source used',
  'Wrong start time',
  'Wrong units (for recorded value)',
  'Wrong value (for recorded value)',
] as const

export type FactErrorType = (typeof factErrorTypes)[number]

export type FactAnnotationType = 'High YoY variance'

export type Segment = {
  id: number
  name: string
  category?: string
  class?: string
  instance?: string
}

export type Aggregation = 'AVG' | 'DIFF' | 'NONE' | 'OTHER' | 'OR' | 'SUM'

export type HowWasValueFoundAnswer = 'Metric page predictions' | 'Keyword search' | 'Other'
export const yesNo = ['Yes', 'No'] as const
export type YesNo = (typeof yesNo)[number]

export type HowWasValueFound = {
  answer?: HowWasValueFoundAnswer | undefined
  keyword?: string | undefined
  other?: string | undefined
}

export type FactError = {
  id: number
  correct: boolean | null
  type: FactErrorType
  autoQAId: string | null
  cfUserId: number | null
  description: string | null
  extractionStepId: number | null
  npUserId: string | null
  severity: number | null
  selfAssessed: boolean
  jsonFeedback: {
    feedback?: string | undefined
    howWasValueFound?: HowWasValueFound | undefined
    wrongValueRepresents?: string | undefined
    wasSourceAlreadyUploaded?: YesNo | undefined
  } | null
}

export type SupportingFact = {
  id: number
  factId: Fact['id']
  recordedValue: DataValue
  standardizedValue: DataValue
  isTotal: boolean
  isDerived: boolean
  isLowConfidence: boolean
  externalComment?: string
  internalComment?: string
  qualifier: string
  segmentId: number | null
  segment?: Segment
  sourceId?: number
  page?: number | null
}

export type SupportingFactsById = ById<SupportingFact>

type BaseFact = {
  id: number
  value: DataValue
  start?: Date
  end: Date
  metricId: MetricLegacy['id']
  entityId: number
  year: number
  sourcesSearched?: number[]
  supportingFacts: SupportingFact[]
  aggregation: Aggregation
  status: FactState
}

export type Fact = BaseFact & {
  variant: 'Fact'
  lowConfidence?: boolean
  errors: FactError[]
  derived?: boolean
  // only true when a fact has been fetched from task endpoint
  taskId?: number
  metricName?: string
  feedback: string | null
  totalTimeSpent: number
  annotations: FactAnnotation[]
}

export type AcceptedFact = BaseFact & {
  variant: 'AcceptedFact'
  isHighYoY: boolean
  highYoYReasons: HighYoYReason[]
}

export const NO_REASON = 'No Apparent Reason'
export const LARGE_VARIANCE_REASONS = [
  {
    name: 'External Factors',
    description:
      'External factors - change driven by market conditions, and/or other factors (e.g. COVID-19)',
  },
  {
    name: 'Reporting / Disclosure Change',
    description:
      'Framework/Methodology change - better data granularity, different analysis done, different data capture (restatements), value reported by company is not in alignment with CDP, etc.',
  },
  {
    name: 'Company Restructuring',
    description:
      'M&A (Mergers and Acquisition) and Disposal - company acquired (or “lost”) a company.',
  },
  {
    name: 'Internal Factors',
    description:
      'Own company performance - variance caused by internal changes within the company - e.g. improved recycling schemes, construction of new offices, etc. ',
  },
  {
    name: 'Scope Change',
    description: 'Scope change - operational coverage expansion.',
  },
  {
    name: NO_REASON,
    description: 'No obvious explanation found',
  },
] as const
export type LargeVarianceReason = (typeof LARGE_VARIANCE_REASONS)[number]['name']

export type FactAnnotationBase = {
  id: number | undefined
  type: string | undefined
  factId?: Fact['id'] | undefined
}

export type FactAnnotation =
  | (FactAnnotationBase & {
      variant: 'base'
      detail: string
    })
  | (FactAnnotationBase & {
      variant: 'withReference'
      detail: string
      sourceId: number
      pageNum: number | undefined
      referenceId: number | undefined
      source?: Source | undefined
    })

export type HighYoYReason = FactAnnotation & { metricName: string | undefined }

//NOTE: any fields added here need to be in the reverseFieldMap
export type FactFilters = Fact & {
  // Alias for status due to Navigation table bug
  state: FactState
  stateNotEquals?: FactState
  entityName?: string
  entitySector?: string
  entityIndustry?: string
  metricName?: string
  withContextFacts?: boolean
  withErrors?: boolean
  valueUnit?: string
  valueUnitNotEquals?: string
  theme?: string
  startBefore?: string
  startAfter?: string
  endBefore?: string
  endAfter?: string
}

export type FactsById = ById<Fact>

export enum FactState {
  Created = 'created',
  Extracted = 'extracted',
  Verified = 'verified',
  FlaggedForExpert = 'flagged for expert',
  Accepted = 'accepted',
  Deprecated = 'deprecated',
  PrematureUpdate = 'premature update',
}

export type CreateFactsSetPayload = {
  entityId: number
  theme: Theme
  start: Date
  end: Date
}
