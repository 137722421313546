import { FC } from 'react'

const ArrowUp: FC = () => (
  <>
    <path
      d="M7 11L12 6L17 11"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 8L12 17"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
)

export default ArrowUp
