import { FC } from 'react'

const ModeDarkIcon: FC = () => (
  <path
    d="M11 12C11 9.21 12.64 6.81 15 5.68C14.09 5.25 13.08 5 12 5C8.13 5 5 8.13 5 12C5 15.87 8.13 19 12 19C13.08 19 14.09 18.75 15 18.32C12.64 17.19 11 14.79 11 12Z"
    stroke="currentColor"
    strokeWidth="2"
    strokeMiterlimit="10"
    strokeLinecap="round"
    strokeLinejoin="round"
  />
)

export default ModeDarkIcon
