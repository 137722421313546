import { FC } from 'react'

const ExcelIcon: FC = () => (
  <>
    <g opacity="0.2">
      <rect
        x="8.73291"
        y="1.64453"
        width="23.8271"
        height="30.3553"
        fill="url(#pattern0_6897_2778)"
      />
      <g opacity="0.2">
        <path
          d="M30.1845 1.76172H11.0022C10.3413 1.76172 9.80554 2.34977 9.80554 3.07517V28.5172C9.80554 29.2426 10.3413 29.8306 11.0022 29.8306H30.1845C30.8454 29.8306 31.3812 29.2426 31.3812 28.5172V3.07517C31.3812 2.34977 30.8454 1.76172 30.1845 1.76172Z"
          fill="white"
        />
      </g>
    </g>
    <g opacity="0.12">
      <rect
        x="9.29138"
        y="1.32129"
        width="22.7634"
        height="29.1877"
        fill="url(#pattern1_6897_2778)"
      />
      <g opacity="0.12">
        <path
          d="M30.1845 1.75879H11.0022C10.3413 1.75879 9.80554 2.34684 9.80554 3.07224V28.5142C9.80554 29.2396 10.3413 29.8277 11.0022 29.8277H30.1845C30.8454 29.8277 31.3812 29.2396 31.3812 28.5142V3.07224C31.3812 2.34684 30.8454 1.75879 30.1845 1.75879Z"
          fill="white"
        />
      </g>
    </g>
    <path
      d="M20.9922 1.76074H11.0022C10.8454 1.76074 10.6903 1.79478 10.5456 1.8609C10.4009 1.92703 10.2695 2.02393 10.1591 2.14603C10.0487 2.26812 9.96139 2.41299 9.90222 2.57229C9.84306 2.73159 9.81319 2.90217 9.81436 3.07419V8.77553H20.9922V1.76074Z"
      fill="#21A366"
    />
    <path
      d="M30.2022 1.76074H20.9922V8.77553H31.3722V3.07419C31.3746 2.73009 31.2529 2.39891 31.0338 2.15286C30.8146 1.90682 30.5156 1.76587 30.2022 1.76074V1.76074Z"
      fill="#33C481"
    />
    <path d="M31.3811 15.79H20.9922V22.8048H31.3811V15.79Z" fill="#107C41" />
    <path
      d="M20.9922 22.8048V15.79H9.81436V28.5062C9.81318 28.679 9.84333 28.8504 9.90305 29.0103C9.96277 29.1703 10.0509 29.3156 10.1622 29.4378C10.2736 29.56 10.406 29.6567 10.5517 29.7223C10.6974 29.7878 10.8536 29.8209 11.011 29.8196H30.2022C30.3596 29.8209 30.5158 29.7878 30.6615 29.7223C30.8072 29.6567 30.9396 29.56 31.051 29.4378C31.1623 29.3156 31.2504 29.1703 31.3101 29.0103C31.3699 28.8504 31.4 28.679 31.3988 28.5062V22.8048H20.9922Z"
      fill="#185C37"
    />
    <path d="M20.9921 8.77539H9.80542V15.7902H20.9921V8.77539Z" fill="#107C41" />
    <path d="M31.3811 8.77539H20.9922V15.7902H31.3811V8.77539Z" fill="#21A366" />
    <g opacity="0.48">
      <rect
        x="0.560059"
        y="6.21582"
        width="20.2105"
        height="21.9492"
        fill="url(#pattern2_6897_2778)"
      />
      <g opacity="0.48">
        <path
          d="M16.5955 7.89941H4.60219C3.94129 7.89941 3.40552 8.48747 3.40552 9.21286V22.3765C3.40552 23.1019 3.94129 23.69 4.60219 23.69H16.5955C17.2564 23.69 17.7922 23.1019 17.7922 22.3765V9.21286C17.7922 8.48747 17.2564 7.89941 16.5955 7.89941Z"
          fill="white"
        />
      </g>
    </g>
    <g opacity="0.24">
      <rect
        x="2.91797"
        y="7.63672"
        width="15.5302"
        height="16.8121"
        fill="url(#pattern3_6897_2778)"
      />
      <g opacity="0.24">
        <path
          d="M16.5955 7.89844H4.60219C3.94129 7.89844 3.40552 8.48649 3.40552 9.21189V22.3756C3.40552 23.101 3.94129 23.689 4.60219 23.689H16.5955C17.2564 23.689 17.7922 23.101 17.7922 22.3756V9.21189C17.7922 8.48649 17.2564 7.89844 16.5955 7.89844Z"
          fill="white"
        />
      </g>
    </g>
    <path
      d="M16.5955 7.90039H4.60219C3.94129 7.90039 3.40552 8.48844 3.40552 9.21384V22.3775C3.40552 23.1029 3.94129 23.691 4.60219 23.691H16.5955C17.2564 23.691 17.7922 23.1029 17.7922 22.3775V9.21384C17.7922 8.48844 17.2564 7.90039 16.5955 7.90039Z"
      fill="#107C41"
    />
    <path
      d="M7.15503 20.0608L9.64589 15.7897L7.33232 11.5186H9.19381L10.4525 14.2427C10.5678 14.4957 10.6475 14.6903 10.6919 14.8168C10.7716 14.6125 10.8603 14.4081 10.9489 14.2136L12.2963 11.5186H14.0071L11.6492 15.7897L14.078 20.0998H12.3052L10.8426 17.1129C10.7761 16.9855 10.7197 16.852 10.6741 16.714V16.714C10.6318 16.8504 10.5753 16.9809 10.5057 17.1031L8.92788 20.0608H7.15503Z"
      fill="white"
    />
    <g opacity="0.5">
      <path
        opacity="0.5"
        d="M16.5954 7.89941H4.60207C3.94117 7.89941 3.4054 8.48747 3.4054 9.21286V22.3765C3.4054 23.1019 3.94117 23.69 4.60207 23.69H16.5954C17.2563 23.69 17.7921 23.1019 17.7921 22.3765V9.21286C17.7921 8.48747 17.2563 7.89941 16.5954 7.89941Z"
        fill="url(#paint0_linear_6897_2778)"
      />
    </g>
    <defs>
      <pattern id="pattern0_6897_2778" patternContentUnits="objectBoundingBox" width="1" height="1">
        <use transform="scale(0.00884956 0.00763359)" />
      </pattern>
      <pattern id="pattern1_6897_2778" patternContentUnits="objectBoundingBox" width="1" height="1">
        <use transform="scale(0.00925926 0.00793651)" />
      </pattern>
      <pattern id="pattern2_6897_2778" patternContentUnits="objectBoundingBox" width="1" height="1">
        <use transform="scale(0.0104167 0.0105263)" />
      </pattern>
      <pattern id="pattern3_6897_2778" patternContentUnits="objectBoundingBox" width="1" height="1">
        <use transform="scale(0.0135135 0.0136986)" />
      </pattern>
      <linearGradient
        id="paint0_linear_6897_2778"
        x1="5.90512"
        y1="6.86811"
        x2="16.6632"
        y2="23.8428"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" stopOpacity="0.5" />
        <stop offset="1" stopOpacity="0.7" />
      </linearGradient>
    </defs>
  </>
)

export default ExcelIcon
