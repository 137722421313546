import { AnyObject, Result, ResultStatus, ResultType } from '@netpurpose/types'

type BackendResult = {
  status: ResultStatus
  type: ResultType
  data: string | null | AnyObject
}

// @ts-expect-error
export const parseResult = <T>(result: BackendResult): Result<T> => ({
  status: result.status,
  type: result.type,
  data: result.data !== null ? (result.data as T) : undefined,
})
