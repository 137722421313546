import {
  FileSourceUpload,
  MetricLegacy,
  PagePredictions,
  ReportTypeOccurrences,
  Source,
  SourceUpload,
  SourceUploadMode,
  UrlSourceUpload,
} from '@netpurpose/types'
import { AxiosInstances } from '../../client'
import { NotFoundError } from '../../errors/errors/NotFoundError'
import { joinPaths } from '../../utils'
import { convertToFormData } from '../convertToFormData'
import { SimpleAbstractModelApi } from '../SimpleAbstractModelApi'
import { formatFileUpload, formatSource, formatUrlUpload } from './formatSource'
import {
  BackendPagePredictions,
  BackendSource,
  parsePagePredictions,
  parseSource,
  reverseSourceFieldMap,
} from './parseSource'

export default class SourceApi extends SimpleAbstractModelApi<BackendSource, Source> {
  endpoint = '/sources'
  modelFormatter = formatSource
  reverseFieldMap = reverseSourceFieldMap

  constructor(axiosInstances: AxiosInstances) {
    super(axiosInstances, parseSource)
    this.uploadSource = this.uploadSource.bind(this)
    this.uploadUrlSource = this.uploadUrlSource.bind(this)
    this.uploadFileSource = this.uploadFileSource.bind(this)
    this.getPagePredictions = this.getPagePredictions.bind(this)
    this.merge = this.merge.bind(this)
    this.getEstimationsWriteUp = this.getEstimationsWriteUp.bind(this)
    this.getReportTypeOccurrences = this.getReportTypeOccurrences.bind(this)
  }

  async uploadSource(_reactQueryCacheKey: string, sourceUpload: SourceUpload): Promise<Source> {
    if (sourceUpload.mode === SourceUploadMode.Url) {
      return this.uploadUrlSource(_reactQueryCacheKey, sourceUpload)
    }
    return this.uploadFileSource(_reactQueryCacheKey, sourceUpload)
  }

  async uploadUrlSource(_reactQueryCacheKey: string, sourceUpload: UrlSourceUpload) {
    const { data } = await this.api.post(
      joinPaths(this.endpoint, 'upload'),
      formatUrlUpload(sourceUpload),
    )
    return parseSource(data)
  }

  async uploadFileSource(_reactQueryCacheKey: string, sourceUpload: FileSourceUpload) {
    const { data } = await this.api.post(
      joinPaths(this.endpoint, 'upload-file'),
      convertToFormData(formatFileUpload(sourceUpload)),
    )
    return parseSource(data)
  }

  async getEstimationsWriteUp(entityId: number): Promise<Source | null> {
    try {
      const { status, data } = await this.api.get<BackendSource>(
        joinPaths(this.endpoint, 'write-up', entityId),
      )
      if (status === 200) {
        return parseSource(data)
      }
      // Expect 204 status when no writeup is found
      return null
    } catch (err) {
      if (err instanceof NotFoundError) {
        return null
      }
      throw err
    }
  }

  async getPagePredictions(
    sourceId: Source['id'] | null,
    metricId: MetricLegacy['id'] | undefined,
  ): Promise<PagePredictions | null> {
    if (!sourceId || !metricId) {
      return Promise.reject(
        new Error(
          `Cannot get page predictions with a source id of ${sourceId} and a metric Id of ${metricId}.`,
        ),
      )
    }
    const { data } = await this.api.get<BackendPagePredictions>(
      joinPaths(this.endpoint, sourceId, 'metric_page_predictions'),
      {
        params: {
          metrics: [metricId],
        },
      },
    )
    return data ? parsePagePredictions(data) : null
  }

  async merge({
    sourceIdToKeep,
    sourceIdsToRemove,
  }: {
    sourceIdToKeep: Source['id']
    sourceIdsToRemove: Source['id'][]
  }) {
    return this.api.post(joinPaths(this.endpoint, 'merge'), {
      keep: sourceIdToKeep,
      remove: sourceIdsToRemove,
    })
  }

  async getReportTypeOccurrences({ metricId }: { metricId: MetricLegacy['id'] | undefined }) {
    if (!metricId) {
      return Promise.reject(
        new Error(
          `Metric ID of: ${metricId} provided to request report type occurrences. Requires a valid metric ID.`,
        ),
      )
    }
    const { data } = await this.api.get<ReportTypeOccurrences>(
      joinPaths(this.endpoint, 'report_types_confidence', metricId),
    )
    return data
  }
}
