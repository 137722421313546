import { FC } from 'react'

const SdgGenderDiversityIcon: FC = () => (
  <>
    <path
      d="M14 36V46"
      stroke="white"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2 30V28C2 23.58 5.58 20 10 20C14.42 20 18 23.58 18 28V30"
      stroke="white"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6 46V36"
      stroke="white"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 12C12.7614 12 15 9.76142 15 7C15 4.23858 12.7614 2 10 2C7.23858 2 5 4.23858 5 7C5 9.76142 7.23858 12 10 12Z"
      stroke="white"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M40 38V46"
      stroke="white"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M26 38L28.7 25.86C29.46 22.44 32.5 20 36 20C39.5 20 42.54 22.44 43.3 25.86L46 38"
      stroke="white"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M32 46V38"
      stroke="white"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M36 12C38.7614 12 41 9.76142 41 7C41 4.23858 38.7614 2 36 2C33.2386 2 31 4.23858 31 7C31 9.76142 33.2386 12 36 12Z"
      stroke="white"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
)

export default SdgGenderDiversityIcon
