import { FC } from 'react'

const ArrowCircle: FC = () => (
  <>
    <g clipPath="url(#clip0_7216_4160)">
      <path
        d="M12 3C16.97 3 21 7.03 21 12C21 15.52 18.98 18.57 16.03 20.05"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 21C7.03 21 3 16.97 3 12C3 8.47995 5.02 5.42995 7.97 3.94995"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20 20.5H16V16.5"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4 3.5H8V7.5"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_7216_4160">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </>
)

export default ArrowCircle
