import { SourceTask } from '@netpurpose/types'
import { BackendSourceTask } from './types'

// @ts-expect-error
export const formatSourceTask = (sourceTask: Partial<SourceTask>): Partial<BackendSourceTask> => ({
  task_id: sourceTask.id,
  batch_id: sourceTask.batchId,
  entity_id: sourceTask.entityId,
  priority: sourceTask.priority,
  state: sourceTask.state,
  step: sourceTask.step,
  type: sourceTask.type,
  years: sourceTask.years,
  user_id: sourceTask.userId,
  expected_time_remaining: sourceTask.expectedTimeRemaining,
  total_time_spent: sourceTask?.totalTimeSpent,
  message: sourceTask.message,
})
