import { FC } from 'react'

const CircleOutlineIcon: FC = () => (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37256 18.6274 0 12 0C5.37256 0 0 5.37256 0 12C0 18.6274 5.37256 24 12 24ZM12 18C15.3137 18 18 15.3137 18 12C18 8.68628 15.3137 6 12 6C8.68628 6 6 8.68628 6 12C6 15.3137 8.68628 18 12 18Z"
    fill="currentColor"
  />
)

export default CircleOutlineIcon
