import { FC } from 'react'

const ChevronLeft: FC = () => (
  <path
    d="M13 16L9 12L13 8"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  />
)

export default ChevronLeft
