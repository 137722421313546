import { IngestionTask } from '@netpurpose/types'
import { BackendIngestionTask } from './types'

export const formatIngestionTask = (
  ingestionTask: Partial<IngestionTask>,
  // @ts-expect-error
): Partial<BackendIngestionTask> => ({
  task_id: ingestionTask.id,
  batch_id: ingestionTask.batchId,
  entity_id: ingestionTask.entityId,
  state: ingestionTask.state,
  step: ingestionTask.step,
  priority: ingestionTask.priority,
  theme: ingestionTask.theme,
  phase: ingestionTask.phase,
  years: ingestionTask.years,
  user_id: ingestionTask.userId,
  total_time_spent: ingestionTask?.totalTimeSpent,
})
