import { FC } from 'react'

const KeyIcon: FC = () => (
  <path
    d="M16 11a3 3 0 1 0 0-6 3 3 0 0 0 0 6ZM13 11l-8 8M5 19l2 2M7 17l2 2"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  />
)

export default KeyIcon
