import { Segment } from '@netpurpose/types'
import { Segment as BackendSegment } from '../../generated/facts'

// @ts-expect-error
export const parseSegment = (segment: BackendSegment): Segment => ({
  // due to BE type being too loosely typed, but this should never be the case
  // TODO: update once the BE type is fixed
  // https://linear.app/netpurpose/issue/TD-523/debt-segment-schema-is-too-loosely-typed
  id: segment.segment_id || 1,
  name: `${segment.segment_category}:${segment.segment_class}:${segment.segment_instance}`,
  category: segment.segment_category,
  class: segment.segment_class,
  instance: segment.segment_instance,
})
