import { Keyword } from '@netpurpose/types'
import { AxiosInstances } from '../../client'
import { SimpleAbstractModelApi } from '../SimpleAbstractModelApi'
import { formatKeyword } from './formatKeyword'
import { BackendKeyword, parseKeyword, reverseKeywordFieldMap } from './parseKeyword'

export default class KeywordApi extends SimpleAbstractModelApi<BackendKeyword, Keyword> {
  endpoint = '/keywords/'
  modelFormatter = formatKeyword
  reverseFieldMap = reverseKeywordFieldMap

  constructor(axiosInstances: AxiosInstances) {
    super(axiosInstances, parseKeyword)
  }
}
