import { KpiResult } from '@netpurpose/types'
import { AxiosInstances } from '../../client'
import { SimpleAbstractModelApi } from '../SimpleAbstractModelApi'
import { BackendKpiResult, parseKpiResult } from './parseKpiResult'

export class KpiResultApi extends SimpleAbstractModelApi<BackendKpiResult, KpiResult> {
  endpoint = '/kpi_results'
  reverseFieldMap = undefined

  constructor(axiosInstances: AxiosInstances) {
    super(axiosInstances, parseKpiResult)
  }
}
