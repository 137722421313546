import { FC } from 'react'

const ProcessingIcon: FC = () => (
  <>
    <path
      d="M13 3C13 2.44772 12.5523 2 12 2C11.4477 2 11 2.44772 11 3L13 3ZM11 5C11 5.55228 11.4477 6 12 6C12.5523 6 13 5.55228 13 5H11ZM11 3V5H13V3L11 3Z"
      fill="currentColor"
    />
    <path opacity="0.4998" d="M12 19V21" stroke="black" strokeWidth="2" strokeLinecap="round" />
    <path opacity="0.2499" d="M21 12L19 12" stroke="black" strokeWidth="2" strokeLinecap="round" />
    <path opacity="0.7497" d="M5 12L3 12" stroke="black" strokeWidth="2" strokeLinecap="round" />
    <path
      opacity="0.9163"
      d="M7.5 4.20703L8.5 5.93908"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      opacity="0.4165"
      d="M15.5 18.0625L16.5 19.7946"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      opacity="0.1666"
      d="M19.7949 7.5L18.0629 8.5"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      opacity="0.6664"
      d="M5.9375 15.5L4.20545 16.5"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      opacity="0.0833"
      d="M16.5 4.20703L15.5 5.93908"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      opacity="0.5831"
      d="M8.5 18.0625L7.5 19.7946"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      opacity="0.3332"
      d="M19.7949 16.5L18.0629 15.5"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      opacity="0.833"
      d="M5.9375 8.5L4.20545 7.5"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </>
)

export default ProcessingIcon
