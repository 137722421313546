import { FC } from 'react'

const BreadcrumbSeparatorIcon: FC = () => (
  <path
    d="M10 5L15 12L10 19"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  />
)

export default BreadcrumbSeparatorIcon
