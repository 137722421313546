import { FC } from 'react'

const SdgEducationIcon: FC = () => (
  <>
    <path
      d="M10 17V33.58C10 33.58 14 38 24 38C34 38 38 33.58 38 33.58V17"
      stroke="white"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2 13L24 2L46 13"
      stroke="white"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M46 13L24 24L2 13"
      stroke="white"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M46 14.5V29.5"
      stroke="white"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
)

export default SdgEducationIcon
