import { AnyObject } from '@netpurpose/types'

type Entries<T> = {
  [K in keyof T]: [K, T[K]]
}[keyof T][]

export const entries = <T extends AnyObject>(obj: T): Entries<T> => {
  return Object.entries(obj) as Entries<T>
}

export const getOptionalProperty = <Value, ParsedValue>(
  keyValuePair: [string, Value | undefined],
  parser?: (val: Value) => ParsedValue,
) => {
  if (keyValuePair[1] === undefined) {
    return {}
  }

  return parser
    ? { [keyValuePair[0]]: parser(keyValuePair[1]) }
    : { [keyValuePair[0]]: keyValuePair[1] }
}
