import {
  DatapointPolymorph,
  DatapointType,
  MacroFactorLegacy,
  TechnologyCost,
} from '@netpurpose/types'
import { formatPartialDatapoint } from './formatPartialDatapoint'
import { formatPartialMacroFactor } from './formatPartialMacroFactor'
import { formatPartialTechnologyCost } from './formatPartialTechnologyCost'
import { BackendDatapointPolymorph } from './types'

export const formatPartialDatapointPolymorph = (
  datapoint: Partial<DatapointPolymorph>,
): Partial<BackendDatapointPolymorph> => {
  switch (datapoint.type) {
    case DatapointType.TechnologyCost:
      return formatPartialTechnologyCost(datapoint as Partial<TechnologyCost>)
    case DatapointType.MacroFactor:
      return formatPartialMacroFactor(datapoint as Partial<MacroFactorLegacy>)
    default:
      return formatPartialDatapoint(datapoint)
  }
}
