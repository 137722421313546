import { FC } from 'react'

const ApproxEqualsIcon: FC = () => (
  <>
    <path
      d="M7.33008 10C7.63092 10.3152 7.99243 10.5663 8.39285 10.7382C8.79328 10.91 9.22435 10.9991 9.6601 11C12.0001 11 12.0001 9 14.3301 9C14.7674 9.00013 15.2002 9.08879 15.6024 9.26065C16.0045 9.43251 16.3677 9.68402 16.6701 10"
      fill="currentColor"
    />
    <path
      d="M7.33008 10C7.63092 10.3152 7.99243 10.5663 8.39285 10.7382C8.79328 10.91 9.22435 10.9991 9.6601 11C12.0001 11 12.0001 9 14.3301 9C14.7674 9.00013 15.2002 9.08879 15.6024 9.26065C16.0045 9.43251 16.3677 9.68402 16.6701 10"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.33008 14C7.63092 14.3152 7.99243 14.5663 8.39285 14.7382C8.79328 14.91 9.22435 14.9991 9.6601 15C12.0001 15 12.0001 13 14.3301 13C14.7674 13.0001 15.2002 13.0888 15.6024 13.2607C16.0045 13.4325 16.3677 13.684 16.6701 14"
      stroke="currentColor"
    />
    <path
      d="M7.33008 14C7.63092 14.3152 7.99243 14.5663 8.39285 14.7382C8.79328 14.91 9.22435 14.9991 9.6601 15C12.0001 15 12.0001 13 14.3301 13C14.7674 13.0001 15.2002 13.0888 15.6024 13.2607C16.0045 13.4325 16.3677 13.684 16.6701 14"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
)

export default ApproxEqualsIcon
