import { FC } from 'react'

const DoubleChevronRight: FC = () => (
  <path
    d="M11.7 34.9q-.45-.45-.45-1.05 0-.6.45-1.05l8.85-8.85-8.9-8.85q-.45-.45-.425-1.025.025-.575.475-1.025.45-.45 1.05-.45.6 0 1.05.45l9.85 9.85q.25.25.35.5.1.25.1.55 0 .3-.1.575-.1.275-.35.525l-9.9 9.9q-.45.45-1.025.425-.575-.025-1.025-.475Zm12.65 0q-.45-.45-.45-1.05 0-.6.45-1.05l8.85-8.85-8.9-8.85q-.45-.45-.425-1.025.025-.575.475-1.025.45-.45 1.05-.45.6 0 1.05.45l9.85 9.85q.25.25.35.5.1.25.1.55 0 .3-.1.575-.1.275-.35.525l-9.9 9.9q-.45.45-1.025.425-.575-.025-1.025-.475Z"
    fill="currentColor"
    strokeWidth="2"
  />
)

export default DoubleChevronRight
