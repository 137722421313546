import { FC } from 'react'

const ArrowRight: FC = () => (
  <path
    d="M16.15 13H5C4.71667 13 4.47933 12.904 4.288 12.712C4.09667 12.52 4.00067 12.2827 4 12C4 11.7167 4.096 11.4793 4.288 11.288C4.48 11.0967 4.71733 11.0007 5 11H16.15L13.3 8.14999C13.1 7.94999 13.004 7.71666 13.012 7.44999C13.02 7.18333 13.116 6.94999 13.3 6.74999C13.5 6.54999 13.7377 6.44599 14.013 6.43799C14.2883 6.42999 14.5257 6.52566 14.725 6.72499L19.3 11.3C19.4 11.4 19.471 11.5083 19.513 11.625C19.555 11.7417 19.5757 11.8667 19.575 12C19.575 12.1333 19.5543 12.2583 19.513 12.375C19.4717 12.4917 19.4007 12.6 19.3 12.7L14.725 17.275C14.525 17.475 14.2877 17.571 14.013 17.563C13.7383 17.555 13.5007 17.4507 13.3 17.25C13.1167 17.05 13.0207 16.8167 13.012 16.55C13.0033 16.2833 13.0993 16.05 13.3 15.85L16.15 13Z"
    fill="currentColor"
  />
)

export default ArrowRight
