import { SourceBatch, SourceBatchWithTasks } from '@netpurpose/types'
import { AbstractModelApi } from '../AbstractModelApi'
import {
  parseSourceBatch,
  parseSourceBatchWithTasks,
  reverseSourceBatchFieldMap,
} from './parseSourceBatch'
import { BackendSourceBatch, BackendSourceBatchWithTasks } from './types'

export class SourceBatchApi extends AbstractModelApi<
  BackendSourceBatch,
  BackendSourceBatchWithTasks,
  SourceBatch,
  SourceBatchWithTasks
> {
  endpoint = '/batches/source'
  listResultParser = parseSourceBatch
  detailResultParser = parseSourceBatchWithTasks
  reverseFieldMap = reverseSourceBatchFieldMap
}
