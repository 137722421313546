import { ISSB_MAJOR_CODE, Standard } from '@netpurpose/types'
import { ReportingStandard, StandardClassEnum } from '../../generated/facts'

export type BackendStandard = ReportingStandard

export const parseStandard = (standard: BackendStandard): Standard => ({
  id: standard.reporting_standard_id,
  standardClass: standard.standard_class,
  majorCode:
    standard.standard_class === StandardClassEnum.ISSB ? ISSB_MAJOR_CODE : standard.major_code,
  fullCode: standard.full_code,
  description: standard.description,
  link: standard.link,
  colour: standard.colour,
})
