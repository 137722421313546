export const noop = () => {}

export const debounce = (func: CallableFunction, delay: number) => {
  let timeout: ReturnType<typeof setTimeout>

  return (...args: unknown[]) => {
    const later = () => {
      clearTimeout(timeout)
      func(...args)
    }

    clearTimeout(timeout)
    timeout = setTimeout(later, delay)
  }
}
