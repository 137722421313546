import { FC } from 'react'

const SdgFoodIcon: FC = () => (
  <>
    <path
      d="M32.6199 14.96C30.6799 6.78 23.9999 2 23.9999 2C23.9999 2 17.3199 6.78 15.3799 14.96"
      stroke="white"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M23.9996 33.9399C22.9996 31.5199 21.4596 28.9599 18.9196 26.6799C11.8796 19.8199 2.01958 20.3399 2.01958 20.3399C2.01958 20.3399 1.45958 29.5599 8.79958 36.1399C13.2396 40.4599 18.7996 41.8599 22.2996 42.2799C22.2996 42.3399 25.6996 42.3399 25.6996 42.2799C29.1996 41.8399 34.7596 40.4599 39.2196 36.1399C46.5596 29.5599 45.9996 20.3399 45.9996 20.3399C45.9996 20.3399 36.1196 19.7999 29.0796 26.6599C26.5396 28.9199 24.9996 31.4999 23.9996 33.9199"
      stroke="white"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
)

export default SdgFoodIcon
