import { AxiosError } from 'axios'
import { logger } from '@netpurpose/utils'

// Where configData is either a JSON string or a FormData instance.
const stringifyConfigData = (configData?: string | FormData): string | null => {
  if (!configData) {
    return null
  }

  if (configData instanceof FormData) {
    return JSON.stringify(Object.fromEntries(configData.entries()))
  }

  // NOTE: this parse/stringify combo is to remove some extra escape characters
  // in the presented context data on Sentry
  return JSON.stringify(JSON.parse(configData))
}

export const log4XXApiErrors = <ErrorType extends AxiosError>(err: ErrorType) => {
  if (Number(err?.response?.status.toString()[0]) === 4) {
    const { config, message, response } = err

    const context = {
      request: {
        url: config ? `${config.baseURL}${config.url}` : '',
        method: config?.method || null,
        params: config?.params ? JSON.stringify(config.params) : null,
        data: stringifyConfigData(config?.data),
      },
      response: {
        status: response?.status || null,
        data: response?.data ? JSON.stringify(response.data) : null,
        headers: response?.headers ? JSON.stringify(response.headers) : null,
      },
      message: message || 'AxiosError',
    }

    logger.error({
      err,
      context: {
        name: 'Axios Error',
        value: context,
      },
    })
  }
}
