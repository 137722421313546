import { DatapointPolymorph, DatapointType } from '@netpurpose/types'
import { ReverseFieldMap } from '../../queryBuilder'
import { parseDatapoint } from './datapoint'
import { datapointTypeMap } from './datapointTypeMap'
import { parseMacroFactor } from './macroFactor'
import { parseTechnologyCost } from './technologyCost'
import { BackendDatapointPolymorph, BackendMacroFactor, BackendTechnologyCost } from './types'

export const parseDatapointPolymorph = (
  datapoint: BackendDatapointPolymorph,
): DatapointPolymorph => {
  switch (datapoint.type) {
    case 'technology_cost':
      return parseTechnologyCost(datapoint as BackendTechnologyCost)
    case 'macro_factor':
      return parseMacroFactor(datapoint as BackendMacroFactor)
    default:
      return parseDatapoint(datapoint)
  }
}

export const parseMaybeDatapointPolymorph = <T extends DatapointPolymorph>(
  datapoint: BackendDatapointPolymorph | null,
): T | undefined => (datapoint ? (parseDatapointPolymorph(datapoint) as T) : undefined)

export const reverseDatapointPolymorphFieldMap: ReverseFieldMap<string> = {
  type: {
    key: 'type',
    transform: (value: DatapointType) => datapointTypeMap[value],
  },
  'macroFactor.domainName': 'macro_factors.technology_domain_name',
  'macroFactor.name': 'macro_factors.macro_factor_name',
  'macroFactor.taxonId': 'macro_factors.taxon_id',
}
