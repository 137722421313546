// This file should be temporary. Ideally we should just use the BE enum types in the FE.
import { DatapointStatus, DatapointType } from '@netpurpose/types'
import {
  DatapointStatus as BackendDatapointStatus,
  DatapointStatusEnum as BackendDatapointStatusEnum,
  DatapointTypes,
  DatapointTypesEnum,
} from '../../../generated/modelling'

export const mapDatapointTypeBEToFE = (type: DatapointTypes | undefined): DatapointType => {
  switch (type) {
    case DatapointTypesEnum.IMPACT:
      return DatapointType.Impact
    case DatapointTypesEnum.MACRO_FACTOR:
      return DatapointType.MacroFactor
    case DatapointTypesEnum.OUTCOME_LEVEL_1:
      return DatapointType.OutcomeLevel1
    case DatapointTypesEnum.OUTCOME_LEVEL_2:
      return DatapointType.OutcomeLevel2
    case DatapointTypesEnum.OUTPUT:
      return DatapointType.Output
    case DatapointTypesEnum.REVENUE:
      return DatapointType.Revenue
    case DatapointTypesEnum.TECHNOLOGY_COST:
      return DatapointType.TechnologyCost
    default:
      return DatapointType.Datapoint
  }
}

export const mapDatapointStatusBEToFE = (
  status: BackendDatapointStatus | undefined,
): DatapointStatus =>
  status === BackendDatapointStatusEnum.ACCEPTED
    ? DatapointStatus.Accepted
    : DatapointStatus.Created
