import { AnalystStats, IngestionTaskStep, TeamStats, Theme } from '@netpurpose/types'
import { AxiosInstances } from '../../client'
import { AbstractApi } from '../AbstractApi'
import { parseAnalystStats, parseTeamStats } from './parseStats'

export class StatsApi extends AbstractApi {
  constructor(axiosInstances: AxiosInstances) {
    super(axiosInstances)
    this.getTeamStats = this.getTeamStats.bind(this)
    this.getAnalystStats = this.getAnalystStats.bind(this)
  }

  async getTeamStats(theme?: Theme, step?: IngestionTaskStep): Promise<TeamStats> {
    if (!theme || !step) {
      return Promise.reject(new Error('All params required'))
    }
    const { data } = await this.api.get('/stats/analysis', { params: { theme, step } })
    return parseTeamStats(data)
  }

  async getAnalystStats(
    userId?: string,
    theme?: Theme,
    step?: IngestionTaskStep,
  ): Promise<AnalystStats> {
    if (!userId || !theme || !step) {
      return Promise.reject(new Error('All params required'))
    }
    const { data } = await this.api.get(`/stats/analysis/${userId}`, { params: { theme, step } })
    return parseAnalystStats(data)
  }
}
