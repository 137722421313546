import { AxiosResponse } from 'axios'
import { getMessageFromErrorResponse } from '@netpurpose/utils'
import { GeneralError } from './errors/GeneralError'
import { NotFoundError } from './errors/NotFoundError'
import { UnprocessableError } from './errors/UnprocessableError'
import { UserNotFoundError } from './errors/UserNotFoundError'

export type StandardError = UnprocessableError | GeneralError

export const mapError = (response?: AxiosResponse): StandardError => {
  if (!response || !response.status) {
    return new GeneralError()
  }

  switch (response.status) {
    case 404:
      return new NotFoundError()
    case 422:
      return new UnprocessableError(getMessageFromErrorResponse(response))
    default:
      return new GeneralError()
  }
}

export type UserApiError = StandardError & UserNotFoundError

export const mapUserApiError = (response?: AxiosResponse): UserApiError => {
  if (!response || !response.status) {
    return new GeneralError()
  }

  switch (response.status) {
    case 422:
      return new UnprocessableError(getMessageFromErrorResponse(response))
    case 404:
      return new UserNotFoundError()
    default:
      return new GeneralError(response)
  }
}
