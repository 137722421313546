import { MetricLegacy, MetricPolymorph, MetricPolymorphs, SimpleMetric } from '@netpurpose/types'
import { convertStringToEnum } from '@netpurpose/utils'
import { DisplayTheme } from '../../generated/facts'
import { DisplayThemeEnum } from '../../generated/facts'
import { formatStandard } from '../../hooks/models/utils'
import { BackendKeyword, formatKeyword } from '../keyword'
import {
  BackendMetric,
  BackendMetricPolymorph,
  BackendMetricPolymorphs,
  BackendSimpleMetric,
} from './parseMetric'

const formatSimpleMetric = (metric: Partial<MetricPolymorph>): Partial<BackendSimpleMetric> => {
  // @ts-expect-error
  return {
    metric_id: metric.id,
    name: metric.name,
    // Display name should always be the same as the name for a simple metric.
    display_name: metric.name,
    description: metric.description,
    unit_id: metric.unitId,
    type: BackendMetricPolymorphs.Simple,
    question_id: metric.questionId,
    is_estimated: metric.isEstimated,
    is_reported: metric.isReported,
  }
}

const formatMajorMetric = (metric: Partial<MetricLegacy>): Partial<BackendMetric> => {
  // @ts-expect-error
  return {
    ...formatSimpleMetric(metric),
    display_name: metric.displayName,
    description: metric.description,
    unit_id: metric.unitId,
    type: BackendMetricPolymorphs.Major,
    themes: metric.themes,
    display_themes: metric.displayThemes?.map((theme) =>
      convertStringToEnum<DisplayTheme>(theme, DisplayThemeEnum),
    ),
    metric_type: metric.metricType,
    instructions: metric.instructions,
    active_export: metric.activeExport,
    active_extraction: metric.activeExtraction,
    extraction_priority: metric.extractionPriority,
    keywords: metric.keywords?.map((kw) => {
      const formattedKeyword = formatKeyword(kw)
      return formattedKeyword as BackendKeyword
    }),
    reporting_standards: metric.reportingStandards?.map(formatStandard),
    goal: metric.goal,
    impact_level: metric.impactLevel,
    is_sdg_eligible: metric.isSdgEligible,
    // display_priority appears to be returned for all metrics, but is only
    // really relevant to major metrics.
    display_priority: metric.displayPriority,
  }
}

export const formatMetric = (metric: Partial<MetricPolymorph>): Partial<BackendMetricPolymorph> => {
  switch (metric.type) {
    case MetricPolymorphs.Simple:
      return formatSimpleMetric(metric as Partial<SimpleMetric>)
    case MetricPolymorphs.Major:
      return formatMajorMetric(metric as Partial<MetricLegacy>)
    default:
      throw new Error('Trying to parse unknown metric type')
  }
}
