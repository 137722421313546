import { Segment } from '@netpurpose/types'
import { AxiosInstances } from '../../client'
import { Segment as BackendSegment } from '../../generated/facts'
import { SimpleAbstractModelApi } from '../SimpleAbstractModelApi'
import { formatSegment, reverseSegmentFieldMap } from './formatSegment'
import { parseSegment } from './parseSegment'

export default class SegmentApi extends SimpleAbstractModelApi<BackendSegment, Segment> {
  endpoint = '/segments'
  modelFormatter = formatSegment
  reverseFieldMap = reverseSegmentFieldMap

  constructor(axiosInstances: AxiosInstances) {
    super(axiosInstances, parseSegment)
  }
}
