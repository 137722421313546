import styled from 'styled-components'
import { zIndex } from '../zIndex'

export const StyledAlertBanner = styled.div<{
  $fgColor: string
  $bgColor: string
  $overlay: boolean
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ $bgColor }) => $bgColor};
  color: ${({ $fgColor }) => $fgColor};
  padding: 0.9375rem;
  font-size: 0.75rem;
  box-shadow: ${({ theme }) => theme.shadow.regular};
  ${({ $overlay }) =>
    $overlay
      ? `
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    z-index: ${zIndex.alertBanner};
  `
      : ''}

  > * + * {
    margin-left: 0.9375rem;
  }
`

export const ContentContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`

export const DecorationContainer = styled.div`
  margin-right: 0.5rem;
`

export const CloseButton = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  align-self: flex-start;
`

export const ErrorAlertContainer = styled.div`
  display: flex;
  flex-direction: column;
`
