import { IngestionTask, IngestionTaskState, IngestionTaskStep, Theme } from '@netpurpose/types'
import { convertStringToEnum } from '@netpurpose/utils'
import { BackendIngestionTask } from './types'

// @ts-expect-error
export const parseIngestionTask = (ingestionTask: BackendIngestionTask): IngestionTask => ({
  id: ingestionTask.task_id,
  batchId: ingestionTask.batch_id,
  entityId: ingestionTask.entity_id,
  state: convertStringToEnum<IngestionTaskState>(ingestionTask.state, IngestionTaskState),
  step: convertStringToEnum<IngestionTaskStep>(ingestionTask.step, IngestionTaskStep),
  priority: ingestionTask.priority,
  theme: convertStringToEnum<Theme>(ingestionTask.theme, Theme),
  phase: ingestionTask.phase,
  years: ingestionTask.years,
  userId: ingestionTask.user_id,
  totalTimeSpent: ingestionTask?.total_time_spent,
})
