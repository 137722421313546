import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import { useEffect, useState } from 'react'
import { useUser } from '@netpurpose/api'
import { User } from '@netpurpose/types'

export const useLoggedIn = (): { user?: User | undefined } => {
  const [firebaseAuthUserId, setFirebaseAuthUserId] = useState<string | undefined>(
    firebase.auth().currentUser?.uid,
  )

  const {
    data: { user },
  } = useUser({
    fetchIsEnabled: Boolean(firebaseAuthUserId),
  })

  useEffect(() => {
    firebase.auth().onAuthStateChanged((firebaseUser) => {
      const userId = firebaseUser?.uid
      if (userId && !firebaseAuthUserId) {
        setFirebaseAuthUserId(userId)
      }
    })
  }, [firebaseAuthUserId])

  if (!firebaseAuthUserId) {
    return {}
  }

  return {
    user,
  }
}
