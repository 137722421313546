const WHITE = '#FFFFFF'
const GREY1 = '#F3F3F3'
const GREY2 = '#E7E7E7'
const GREY3 = '#CCCCCC'
const GREY4 = '#979797'
const GREY5 = '#555555'
const GREY6 = '#252525'
const BLACK = '#1B1B1B'

const NP_GREEN = '#2AFD62'
const PURPLE = '#552CF6'
const RED = '#D30034'
const GREEN = '#019000'
const BLUE = '#0074E0'
const TEAL = '#285F68'

export const colors = {
  // New colour system

  bgPrimary: GREY1,
  bgSecondary: WHITE,
  bgDark: BLACK,

  typePrimary: BLACK,
  typeSecondary: GREY5,
  typeTertiary: GREY4,
  typeDarkPrimary: GREY1,
  typeInlineCode: TEAL,

  actionPrimary: NP_GREEN,
  actionSecondary: BLACK,
  actionLink: PURPLE,
  actionDarkLink: NP_GREEN,

  semanticNegative: RED,
  semanticPositive: GREEN,

  blue: BLUE,

  architecture1: WHITE,
  architecture2: GREY1,
  architecture3: GREY2,
  architecture4: GREY3,
  architecture5: GREY4,
  architecture6: GREY5,
  architecture7: GREY6,
  architecture8: BLACK,

  // ---------------------------

  // Old colour system

  sdgAir: '#80B418', // Green
  sdgAffordableHousing: '#FF5E27', // Orange
  sdgCleanEnergy: '#FFD000', // Yellow
  sdgClimateChange: '#00953E', // Green
  sdgDecentWork: '#FF0003', // Red
  sdgEducation: '#BF0002', // Maroon
  sdgFinancialInclusion: '#FF004D', // Red
  sdgFood: '#CFA042', // Taupe
  sdgGenderDiversity: '#FF00A6', // Pink
  sdgHealthcare: '#00BA50', // Green
  sdgIndustry: '#FF502D', // Orange
  sdgSustainableFinance: '#005CC8', // Blue
  sdgWaste: '#FFA057', // Brown
  sdgWater: '#0094FF', // Blue

  lightYellow: '#FFF9C4',
  yellow: '#FFD741',
  orange: '#FF9714',
  red: '#FF2057',
  green: GREEN,
  purple: PURPLE,
  darkBlue: '#061235',
  black: '#000000',
  white: WHITE,

  goalRed: RED,
  goalGreen: '#3F8E27',
} as const
