import { MacroFactorLegacy } from '@netpurpose/types'
import { formatPartialDatapoint } from './formatPartialDatapoint'
import { BackendMacroFactor } from './types'

export const formatPartialMacroFactor = (
  macroFactor: Partial<MacroFactorLegacy>,
): Partial<BackendMacroFactor> => {
  // @ts-expect-error
  return {
    ...formatPartialDatapoint(macroFactor),
    macro_factor_name: macroFactor.macroFactorName,
    domain_name: macroFactor.domainName,
  }
}
