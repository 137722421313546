import { DatapointType, MacroFactorLegacy } from '@netpurpose/types'
import { parseDatapoint } from './datapoint'
import { BackendDatapoint, BackendMacroFactor } from './types'

export const parseMacroFactor = (macroFactor: BackendMacroFactor): MacroFactorLegacy => {
  return {
    ...parseDatapoint(macroFactor as BackendDatapoint),
    type: DatapointType.MacroFactor,
    domainName: macroFactor.domain_name || '',
    taxonId: macroFactor.taxon_id,
    macroFactorName: macroFactor.macro_factor_name,
  }
}
