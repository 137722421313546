import { AxiosResponse } from 'axios'

export class GeneralError extends Error {
  originalError: AxiosResponse | undefined

  constructor(originalError?: AxiosResponse) {
    super('Something went wrong')
    this.originalError = originalError
  }
}
