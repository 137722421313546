import { FC } from 'react'
import { useTheme } from 'styled-components'
import { ThemeColor } from '../../themes'
import { Icon } from '../Icon'
import Spinner from '../Spinner'
import {
  CloseButton,
  ContentContainer,
  DecorationContainer,
  StyledAlertBanner,
} from './AlertBanner.style'

export type AlertBannerType = 'error' | 'loading' | 'success'

type Props = {
  type: AlertBannerType
  isOverlay?: boolean
  onClose: () => void
  children?: React.ReactNode
}

export const AlertBanner: FC<Props> = ({ type, children, onClose, isOverlay = false }) => {
  const theme = useTheme()

  const bannerStyles: Record<AlertBannerType, { $fgColor: string; $bgColor: string }> = {
    error: { $fgColor: theme.colors.typeDarkPrimary, $bgColor: theme.colors.semanticNegative },
    loading: { $fgColor: theme.colors.typePrimary, $bgColor: theme.colors.bgSecondary },
    success: { $fgColor: theme.colors.typePrimary, $bgColor: theme.colors.bgSecondary },
  }
  const closeIconColorMap: Record<AlertBannerType, ThemeColor> = {
    error: 'typeDarkPrimary',
    loading: 'black',
    success: 'black',
  }

  return (
    <StyledAlertBanner {...bannerStyles[type]} $overlay={isOverlay} data-testid="alert-banner">
      <ContentContainer>
        {type === 'loading' && (
          <DecorationContainer>
            <Spinner size="small" />
          </DecorationContainer>
        )}
        {type === 'success' && (
          <DecorationContainer>
            <Icon icon="Success" size="s" color="semanticPositive" alt="success" />
          </DecorationContainer>
        )}

        {children}
      </ContentContainer>
      <CloseButton onClick={onClose}>
        <Icon icon="XSmall" alt="close banner" color={closeIconColorMap[type]} />
      </CloseButton>
    </StyledAlertBanner>
  )
}
