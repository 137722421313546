import { FC } from 'react'

const ArrowBack: FC = () => (
  <path
    d="M7.82503 13L12.725 17.9C12.925 18.1 13.021 18.3333 13.013 18.6C13.005 18.8667 12.9007 19.1 12.7 19.3C12.5 19.4833 12.2667 19.5793 12 19.588C11.7334 19.5967 11.5 19.5007 11.3 19.3L4.70003 12.7C4.60003 12.6 4.52903 12.4917 4.48703 12.375C4.44503 12.2583 4.42469 12.1333 4.42603 12C4.42736 11.8667 4.44836 11.7417 4.48903 11.625C4.52969 11.5083 4.60036 11.4 4.70103 11.3L11.301 4.69999C11.4844 4.51665 11.7137 4.42499 11.989 4.42499C12.2644 4.42499 12.5017 4.51665 12.701 4.69999C12.901 4.89999 13.001 5.13765 13.001 5.41299C13.001 5.68832 12.901 5.92565 12.701 6.12499L7.82503 11H19C19.2834 11 19.521 11.096 19.713 11.288C19.905 11.48 20.0007 11.7173 20 12C19.9994 12.2827 19.9034 12.5203 19.712 12.713C19.5207 12.9057 19.2834 13.0013 19 13H7.82503Z"
    fill="currentColor"
  />
)

export default ArrowBack
