import { Datapoint, DatapointStatus, DatapointType, referenceHasSource } from '@netpurpose/types'
import { getOptionalProperty } from '@netpurpose/utils'
import { ImpactThemes } from '../../generated/modelling'
import { datapointStatusMap } from './datapointStatusMap'
import { datapointTypeMap } from './datapointTypeMap'
import { formatReference } from './formatReference'
import { BackendDatapoint } from './types'

export const formatPartialDatapoint = (
  datapoint: Partial<Datapoint>,
): Partial<BackendDatapoint> => ({
  ...getOptionalProperty(['datapoint_id', datapoint.id]),
  type: datapointTypeMap[datapoint.type || DatapointType.Datapoint],
  ...getOptionalProperty(['original_workbook_id', datapoint.originalWorkbookId]),
  ...getOptionalProperty(['value', datapoint.value]),
  ...getOptionalProperty(['reported_value', datapoint.reportedValue]),
  ...getOptionalProperty(['unit_id', datapoint.unitId]),
  ...getOptionalProperty(['reported_unit_id', datapoint.reportedUnitId]),
  // remove the '='
  ...getOptionalProperty(['formula', datapoint.formula?.substring(1)]),
  ...getOptionalProperty(['raw', datapoint.raw]),
  status: datapointStatusMap[datapoint.status || DatapointStatus.Created],
  ...(datapoint.reference && referenceHasSource(datapoint.reference)
    ? { reference: formatReference(datapoint.reference) }
    : {}),
  ...getOptionalProperty(['cell', datapoint.cell]),
  ...getOptionalProperty(['impact_theme', datapoint.impactTheme as ImpactThemes | undefined]),
  ...getOptionalProperty(['question_id', datapoint.questionId]),
  ...getOptionalProperty(['fx_rate_used', datapoint.fxRateUsed]),
  ...getOptionalProperty(['fx_rate_date', datapoint.fxRateDate]),
})
