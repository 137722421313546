import { AxiosConfig, getAxiosInstances } from './client'
import {
  AcceptedFactApi,
  ApiKeyApi,
  CollectionTaskApi,
  DatapointApi,
  EnumApi,
  ErrorApi,
  EstimationApi,
  FactApi,
  FeedbackApi,
  IngestionBatchApi,
  IngestionTaskApi,
  KeywordApi,
  KpiApi,
  KpiResultApi,
  MetricApi,
  MetricsConfigApi,
  ResultApi,
  SegmentApi,
  SourceApi,
  SourceBatchApi,
  SourceTaskApi,
  StandardApi,
  StatsApi,
  TechnologyCostApi,
  UnitApi,
  UserGroupsApi,
  UsersApi,
  WorkbookApi,
} from './models'

export type ApiConfig = { axios: AxiosConfig }

export type ModelApiName =
  | 'error'
  | 'acceptedFact'
  | 'fact'
  | 'ingestionBatch'
  | 'ingestionTask'
  | 'metric'
  | 'segment'
  | 'source'
  | 'sourceBatch'
  | 'sourceTask'
  | 'standard'
  | 'stats'
  | 'unit'
  | 'datapoint'
  | 'technologyCost'
  | 'estimation'
  | 'kpi'
  | 'kpiResult'
  | 'apiKey'
  | 'keyword'
  | 'metricsConfig'

export class Api {
  enum: EnumApi
  error: ErrorApi
  acceptedFact: AcceptedFactApi
  fact: FactApi
  feedback: FeedbackApi
  ingestionBatch: IngestionBatchApi
  ingestionTask: IngestionTaskApi
  metric: MetricApi
  result: ResultApi
  segment: SegmentApi
  source: SourceApi
  sourceBatch: SourceBatchApi
  sourceTask: SourceTaskApi
  standard: StandardApi
  stats: StatsApi
  unit: UnitApi
  datapoint: DatapointApi
  estimation: EstimationApi
  workbook: WorkbookApi
  technologyCost: TechnologyCostApi
  userGroups: UserGroupsApi
  users: UsersApi
  kpi: KpiApi
  kpiResult: KpiResultApi
  apiKey: ApiKeyApi
  keyword: KeywordApi
  collectionTask: CollectionTaskApi
  metricsConfig: MetricsConfigApi

  constructor(config: ApiConfig) {
    const {
      facts: factsAxiosInstances,
      modelling: modellingAxiosInstances,
      users: usersAxiosInstances,
    } = getAxiosInstances(config.axios)

    this.enum = new EnumApi(factsAxiosInstances)
    this.error = new ErrorApi(factsAxiosInstances)
    this.acceptedFact = new AcceptedFactApi(factsAxiosInstances)
    this.fact = new FactApi(factsAxiosInstances)
    this.feedback = new FeedbackApi(factsAxiosInstances)
    this.ingestionBatch = new IngestionBatchApi(factsAxiosInstances)
    this.ingestionTask = new IngestionTaskApi(factsAxiosInstances)
    this.metric = new MetricApi(factsAxiosInstances)
    this.result = new ResultApi(factsAxiosInstances)
    this.segment = new SegmentApi(factsAxiosInstances)
    this.source = new SourceApi(factsAxiosInstances)
    this.standard = new StandardApi(factsAxiosInstances)
    this.sourceBatch = new SourceBatchApi(factsAxiosInstances)
    this.sourceTask = new SourceTaskApi(factsAxiosInstances)
    this.stats = new StatsApi(factsAxiosInstances)
    this.unit = new UnitApi(factsAxiosInstances)
    this.kpi = new KpiApi(factsAxiosInstances)
    this.kpiResult = new KpiResultApi(factsAxiosInstances)
    this.keyword = new KeywordApi(factsAxiosInstances)
    this.collectionTask = new CollectionTaskApi(factsAxiosInstances)

    this.datapoint = new DatapointApi(modellingAxiosInstances)
    this.estimation = new EstimationApi(modellingAxiosInstances)
    this.workbook = new WorkbookApi(modellingAxiosInstances)
    this.technologyCost = new TechnologyCostApi(modellingAxiosInstances)

    this.users = new UsersApi(usersAxiosInstances)
    this.userGroups = new UserGroupsApi(usersAxiosInstances)
    this.apiKey = new ApiKeyApi(usersAxiosInstances)
    this.metricsConfig = new MetricsConfigApi(usersAxiosInstances)
  }

  getApiByName(apiName: ModelApiName) {
    return this[apiName]
  }
}
