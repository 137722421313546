import { AnyObject } from '@netpurpose/types'

export const convertStringToEnum = <OutputEnum>(
  inputString: string,
  outputEnum: AnyObject,
): OutputEnum => {
  if (!Object.values(outputEnum).includes(inputString)) {
    throw new Error(`String of value: ${inputString} cannot be mapped to enum property`)
  }
  return inputString as unknown as OutputEnum
}
