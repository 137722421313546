export enum Theme {
  AccessToCleanEnergy = 'Access to clean energy',
  AffordableHousing = 'Affordable housing and communities',
  AirQuality = 'Air quality',
  CleanEnergy = 'Clean energy',
  ClimateChange = 'Climate change',
  DecentWork = 'Decent work',
  Education = 'Education',
  FinancialServices = 'Access to financial services',
  FinancialServicesBanking = 'Access to financial services (banking)',
  FinancialServicesInsurance = 'Access to financial services (insurance)',
  FinancialServicesPayment = 'Access to financial services (payment)',
  SustainableFinance = 'Sustainable Finance',
  Fundamentals = 'Fundamentals',
  GenderEquality = 'Gender equality',
  HealthAndSafety = 'Health and Safety',
  Healthcare = 'Access to healthcare',
  JobsCreated = 'Jobs created',
  JustRevenue = 'Just revenue',
  Land = 'Land',
  PAIMandatory = 'PAI mandatory',
  RevenueSegments = 'Revenue Segments',
  SolidWaste = 'Solid waste',
  WageEquality = 'Wage equality',
  WaterUse = 'Water use',
}

export enum DomainName {
  Industry = 'Industry and innovation',
  // NOTE: these aren't currently returned, not sure if still relevant
  EnergyContent = 'Energy Content - Diesel',
  Food = 'Food',
  GenderEquality = 'Gender equality',
  General = 'General',
  SolarPower = 'Solar Power - Photovoltaic',
  SolarPCCF = 'Solar Photovoltaic Capacity Conversion Factor',
}
