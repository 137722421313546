import { Result } from '@netpurpose/types'
import { AxiosInstances } from '../../client'
import { AbstractApi } from '../AbstractApi'
import { parseResult } from './parseResult'

export default class ResultApi extends AbstractApi {
  constructor(axiosInstances: AxiosInstances) {
    super(axiosInstances)
    this.getResult = this.getResult.bind(this)
  }

  async getResult<ResultType>(token?: string | null): Promise<Result<ResultType>> {
    if (!token) {
      return Promise.reject(new Error(`Invalid token: ${token}`))
    }
    try {
      const { data } = await this.api.get('/results', { params: { result_token: token } })
      return parseResult(data)
    } catch (err) {
      return {
        status: 'unknown',
        type: 'data',
      }
    }
  }
}
