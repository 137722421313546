import { pickBy } from 'lodash'
import { Datapoint, TechnologyCost } from '@netpurpose/types'
import { valueIsDefined } from '@netpurpose/utils'
import { DatapointTypesEnum } from '../../generated/modelling'
import { ReverseFieldMap } from '../../queryBuilder'
import { formatPartialDatapoint } from './formatPartialDatapoint'
import { BackendTechnologyCost } from './types'

export const formatPartialTechnologyCost = (
  technologyCost: Partial<TechnologyCost>,
): Partial<BackendTechnologyCost> => {
  return {
    ...formatPartialDatapoint(technologyCost as Datapoint),
    type: DatapointTypesEnum.TECHNOLOGY_COST,
    ...pickBy(
      {
        unit_id: technologyCost.numeratorUnitId,
        numerator_unit_id: technologyCost.numeratorUnitId,
        reported_unit_id: technologyCost.numeratorReportedUnitId,
        numerator_reported_unit_id: technologyCost.numeratorReportedUnitId,
        denominator_unit_id: technologyCost.denominatorUnitId,
        denominator_reported_unit_id: technologyCost.denominatorReportedUnitId,
        is_primary_cost: technologyCost.isPrimaryCost,
        impact_question_id: technologyCost.impactQuestionId,
      },
      valueIsDefined,
    ),
    ...(technologyCost?.technology
      ? {
          technology: {
            baseline: technologyCost.technology?.baseline || false,
            technology_id: technologyCost.technology.id,
            technology_name: technologyCost.technology.name,
            taxon_id: technologyCost.technology.taxonId,
          },
        }
      : {}),
  }
}

export const technologyCostReverseFieldMap: ReverseFieldMap<string> = {
  id: 'datapoint_id',
  status: 'status',
  'technology.name': 'technology.name',
  'technology.domainName': 'technology.domain_name',
  'technology.baseline': 'technology.baseline',
}
