import { AcceptedFact } from '@netpurpose/types'
import { AxiosInstances } from '../../client'
import { SimpleAbstractModelApi } from '../SimpleAbstractModelApi'
import { BackendAcceptedFact, parseAcceptedFact } from './parseFact'

export default class AcceptedFactApi extends SimpleAbstractModelApi<
  BackendAcceptedFact,
  AcceptedFact
> {
  endpoint = '/accepted_facts/'
  reverseFieldMap = undefined

  constructor(axiosInstances: AxiosInstances) {
    super(axiosInstances, parseAcceptedFact)
  }
}
