import { AxiosInstance } from 'axios'
import { AxiosInstances } from '../client'

export abstract class AbstractApi {
  protected api: AxiosInstance
  protected queryBuilderApi: AxiosInstance

  constructor(axiosInstances: AxiosInstances) {
    const { api, queryBuilderApi } = axiosInstances
    // Basic api instances
    this.api = api
    // Api with 'correct' params encoding for our filterable endpoints
    // i.e. uses "q=" instead of just passing through as it is.
    this.queryBuilderApi = queryBuilderApi
  }
}
