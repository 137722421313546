import { Source } from './source'

export type Reference = {
  id?: number | undefined
  source: Source | undefined
  pageNumber?: number | undefined
}

export function referenceHasSource(ref: Partial<Reference>): ref is Reference {
  return !!ref.source
}
