import { FC } from 'react'

const SdgSustainableFinanceIcon: FC = () => (
  <>
    <path
      d="M41.9998 2H27.9798C26.9198 2 25.9198 2.42 25.1598 3.16L3.17977 24.82C1.61977 26.38 1.61977 28.92 3.17977 30.48L17.5198 44.82C19.0798 46.38 21.6198 46.38 23.1798 44.82L44.8598 22.82C45.5998 22.08 46.0198 21.06 46.0198 20V6C46.0198 3.8 44.2198 2 42.0198 2H41.9998Z"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M36 12V12.02"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M41.9998 2H27.9798C26.9198 2 25.9198 2.42 25.1598 3.16L3.17977 24.82C1.61977 26.38 1.61977 28.92 3.17977 30.48L17.5198 44.82C19.0798 46.38 21.6198 46.38 23.1798 44.82L44.8598 22.82C45.5998 22.08 46.0198 21.06 46.0198 20V6C46.0198 3.8 44.2198 2 42.0198 2H41.9998Z"
      stroke="white"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16 42.0017V39.3217C16 35.8217 16.82 32.3817 18.38 29.2617"
      stroke="white"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M29.9997 18.1018L29.4397 21.1018C29.2597 22.1218 28.1997 27.1818 25.8397 29.0818C23.2797 31.1218 20.0397 30.9618 17.8597 28.7618L16.9797 27.6618C15.2997 25.0618 15.8597 21.8418 18.3997 19.8018C20.7597 17.9018 25.9397 17.9818 26.9597 18.0218L29.9997 18.1218"
      stroke="white"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M36 12V12.02"
      stroke="white"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
)

export default SdgSustainableFinanceIcon
