import { FC } from 'react'

const MagnifyingGlass: FC = () => (
  <path
    fill="currentColor"
    d="M10 16q2.5 0 4.25-1.75T16 10t-1.75-4.25T10 4T5.75 5.75T4 10t1.75 4.25T10 16m0-3q-.425 0-.712-.288T9 12V7q0-.425.288-.712T10 6t.713.288T11 7v5q0 .425-.288.713T10 13m-3.5 0q-.425 0-.712-.288T5.5 12V9q0-.425.288-.712T6.5 8t.713.288T7.5 9v3q0 .425-.288.713T6.5 13m7 0q-.425 0-.712-.288T12.5 12v-2q0-.425.288-.712T13.5 9t.713.288t.287.712v2q0 .425-.288.713T13.5 13M10 18q-3.35 0-5.675-2.325T2 10t2.325-5.675T10 2t5.675 2.325T18 10q0 1.4-.437 2.65t-1.238 2.275L21.3 19.9q.275.275.275.7t-.275.7t-.7.275t-.7-.275l-4.975-4.975q-1.025.8-2.275 1.238T10 18"
  />
)

export default MagnifyingGlass
