import { FC } from 'react'

const Info: FC = () => (
  <svg viewBox="0 0 14 14">
    <path
      d="M7.00004 10.3333C7.18893 10.3333 7.34737 10.2693 7.47537 10.1413C7.60337 10.0133 7.66715 9.85511 7.66671 9.66667V7C7.66671 6.81111 7.60271 6.65267 7.47471 6.52467C7.34671 6.39667 7.18848 6.33289 7.00004 6.33333C6.81115 6.33333 6.65271 6.39733 6.52471 6.52533C6.39671 6.65333 6.33293 6.81156 6.33337 7V9.66667C6.33337 9.85555 6.39737 10.014 6.52537 10.142C6.65337 10.27 6.8116 10.3338 7.00004 10.3333ZM7.00004 5C7.18893 5 7.34737 4.936 7.47537 4.808C7.60337 4.68 7.66715 4.52178 7.66671 4.33333C7.66671 4.14444 7.60271 3.986 7.47471 3.858C7.34671 3.73 7.18848 3.66622 7.00004 3.66667C6.81115 3.66667 6.65271 3.73067 6.52471 3.85867C6.39671 3.98667 6.33293 4.14489 6.33337 4.33333C6.33337 4.52222 6.39737 4.68067 6.52537 4.80867C6.65337 4.93667 6.8116 5.00044 7.00004 5ZM7.00004 13.6667C6.07782 13.6667 5.21115 13.4916 4.40004 13.1413C3.58893 12.7911 2.88337 12.3162 2.28337 11.7167C1.68337 11.1167 1.20849 10.4111 0.858707 9.6C0.50893 8.78889 0.333818 7.92222 0.333374 7C0.333374 6.07778 0.508485 5.21111 0.858707 4.4C1.20893 3.58889 1.68382 2.88333 2.28337 2.28333C2.88337 1.68333 3.58893 1.20844 4.40004 0.858667C5.21115 0.508889 6.07782 0.333778 7.00004 0.333333C7.92226 0.333333 8.78893 0.508444 9.60004 0.858667C10.4112 1.20889 11.1167 1.68378 11.7167 2.28333C12.3167 2.88333 12.7918 3.58889 13.142 4.4C13.4923 5.21111 13.6672 6.07778 13.6667 7C13.6667 7.92222 13.4916 8.78889 13.1414 9.6C12.7912 10.4111 12.3163 11.1167 11.7167 11.7167C11.1167 12.3167 10.4112 12.7918 9.60004 13.142C8.78893 13.4922 7.92226 13.6671 7.00004 13.6667ZM7.00004 12.3333C8.48893 12.3333 9.75004 11.8167 10.7834 10.7833C11.8167 9.75 12.3334 8.48889 12.3334 7C12.3334 5.51111 11.8167 4.25 10.7834 3.21667C9.75004 2.18333 8.48893 1.66667 7.00004 1.66667C5.51115 1.66667 4.25004 2.18333 3.21671 3.21667C2.18337 4.25 1.66671 5.51111 1.66671 7C1.66671 8.48889 2.18337 9.75 3.21671 10.7833C4.25004 11.8167 5.51115 12.3333 7.00004 12.3333Z"
      fill="currentColor"
    />
  </svg>
)

export default Info
