import {
  PermissionLabels,
  Permissions,
  PermissionsSchema,
  User,
  UserFormType,
  UserInfo,
  UserRegistrationDetails,
} from '@netpurpose/types'
import { AxiosInstances } from '../../client'
import { BackendPaginationParams } from '../../queryBuilder/formatPaginationParams'
import { AbstractApi } from '../AbstractApi'
import { PaginatedListResult } from '../AbstractModelApi'
import { formatRegistrationDetails, formatUser } from './formatUser'
import { BackendUserInfo, parseUserInfo } from './parseUser'

export class UsersApi extends AbstractApi {
  constructor(axiosInstances: AxiosInstances) {
    super(axiosInstances)
    this.getLabels = this.getLabels.bind(this)
    this.getUserInfo = this.getUserInfo.bind(this)
    this.createUser = this.createUser.bind(this)
    this.updateUser = this.updateUser.bind(this)
    this.sendSignupEmail = this.sendSignupEmail.bind(this)
    this.sendPasswordResetEmail = this.sendPasswordResetEmail.bind(this)
    this.confirmRegistration = this.confirmRegistration.bind(this)
    this.updateUserPermissions = this.updateUserPermissions.bind(this)
    this.getPermissionsSchema = this.getPermissionsSchema.bind(this)
    this.getEditablePermissions = this.getEditablePermissions.bind(this)
    this.getUsers = this.getUsers.bind(this)
    this.getUser = this.getUser.bind(this)
  }

  // NOTE: separate out methods if this grows too much, currently this is just being used
  // as a "Users service" api class.
  // These are the "/labels" api methods
  async getLabels(uri: string | undefined): Promise<PermissionLabels> {
    if (!uri) {
      throw new Error(`Cannot get labels with a url of ${uri}.`)
    }

    const {
      data: { labels },
    } = await this.api.get(`labels/${uri}`)
    return labels
  }

  // These are "/users" api methods
  async getUsers<Params extends BackendPaginationParams>(
    params: Params,
  ): Promise<PaginatedListResult<UserInfo>> {
    const {
      data: { results, total },
    } = await this.queryBuilderApi.get<{ total: number; results: BackendUserInfo[] }>('/users', {
      params,
      headers: {
        'users-paginated-response': true,
      },
    })

    const hasNext = total - (params.page + 1) * params.limit > 0

    return {
      results: results.map((userInfo: BackendUserInfo) => parseUserInfo(userInfo)),
      hasNext,
      totalCount: total,
    }
  }

  async createUser(newUserInfo: UserFormType): Promise<UserInfo> {
    const { data } = await this.api.post('/users', formatUser(newUserInfo))
    return parseUserInfo(data)
  }

  async getUserInfo(): Promise<UserInfo> {
    const { data } = await this.api.get('/users/info')
    return parseUserInfo(data)
  }

  async getPermissionsSchema(): Promise<PermissionsSchema> {
    const { data } = await this.api.get('/users/permissions_schema')
    return data
  }

  // These are "/users/:user_id" api methods
  async getUser(userId: User['id'] | undefined): Promise<UserInfo> {
    if (!userId) {
      throw new Error(`Cannot get user with a user id of ${userId}.`)
    }
    const { data } = await this.api.get(`/users/${userId}`)
    return parseUserInfo(data)
  }

  async updateUser(
    updatedUserInfo: UserFormType,
    userId: User['id'] | undefined,
  ): Promise<UserInfo> {
    if (!userId) {
      throw new Error(`Cannot update user with a user id of ${userId}.`)
    }
    const { data } = await this.api.put(`/users/${userId}`, formatUser(updatedUserInfo))
    return parseUserInfo(data)
  }

  async updateUserPermissions(
    updatedUserPermissions: Permissions,
    userId: User['id'] | undefined,
  ): Promise<UserInfo> {
    if (!userId) {
      throw new Error(`Cannot update user permissions with a user id of ${userId}.`)
    }
    const { data } = await this.api.put(`/users/${userId}/permissions`, updatedUserPermissions)
    return data
  }

  async getEditablePermissions(userId: User['id'] | undefined): Promise<PermissionsSchema> {
    if (!userId) {
      throw new Error(`Cannot get user editable permissions with a user id of ${userId}.`)
    }
    const { data } = await this.api.get(`/users/${userId}/editable-permissions`)
    return data
  }

  async deactivateUser(userId: User['id'] | undefined): Promise<void> {
    if (!userId) {
      throw new Error(`Cannot deactivate user with a user id of ${userId}.`)
    }
    return this.api.delete(`/users/${userId}`)
  }

  async sendSignupEmail(email: string): Promise<void> {
    return this.api.get('/users/send-signup-email', { params: { email } })
  }

  async sendPasswordResetEmail(email: string): Promise<void> {
    return this.api.get('/users/reset-password', { params: { email } })
  }

  async confirmRegistration(details: UserRegistrationDetails): Promise<void> {
    return this.api.post('/users/set-password', formatRegistrationDetails(details))
  }
}
