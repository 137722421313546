import { FC } from 'react'

const UploadIcon: FC = () => (
  <>
    <path
      d="M10.0003 31.9445C9.08366 31.9445 8.29921 31.6319 7.64699 31.0069C6.99477 30.3818 6.6681 29.6296 6.66699 28.75V25.5556C6.66699 25.103 6.82699 24.724 7.14699 24.4184C7.46699 24.1128 7.86255 23.9594 8.33366 23.9584C8.80477 23.9573 9.20088 24.1106 9.52199 24.4184C9.8431 24.7261 10.0025 25.1052 10.0003 25.5556V28.75H30.0003V25.5556C30.0003 25.103 30.1603 24.724 30.4803 24.4184C30.8003 24.1128 31.1959 23.9594 31.667 23.9584C32.1381 23.9573 32.5342 24.1106 32.8553 24.4184C33.1764 24.7261 33.3359 25.1052 33.3337 25.5556V28.75C33.3337 29.6285 33.0075 30.3808 32.3553 31.0069C31.7031 31.633 30.9181 31.9455 30.0003 31.9445H10.0003ZM18.3337 12.5382L15.2087 15.533C14.8753 15.8525 14.4798 16.0058 14.022 15.993C13.5642 15.9802 13.1681 15.8136 12.8337 15.4931C12.5281 15.1736 12.3681 14.8009 12.3537 14.375C12.3392 13.9491 12.4992 13.5764 12.8337 13.257L18.8337 7.50696C19.0003 7.34724 19.1809 7.23437 19.3753 7.16835C19.5698 7.10233 19.7781 7.06879 20.0003 7.06773C20.2225 7.06666 20.4309 7.1002 20.6253 7.16835C20.8198 7.2365 21.0003 7.34937 21.167 7.50696L27.167 13.257C27.5003 13.5764 27.6603 13.9491 27.647 14.375C27.6337 14.8009 27.4737 15.1736 27.167 15.4931C26.8337 15.8125 26.4381 15.9792 25.9803 15.993C25.5225 16.0068 25.1264 15.8535 24.792 15.533L21.667 12.5382V23.9584C21.667 24.4109 21.507 24.7905 21.187 25.0972C20.867 25.4038 20.4714 25.5566 20.0003 25.5556C19.5292 25.5545 19.1337 25.4012 18.8137 25.0956C18.4937 24.79 18.3337 24.4109 18.3337 23.9584V12.5382Z"
      fill="currentColor"
    />
  </>
)

export default UploadIcon
