import { FC } from 'react'

const CopyIcon: FC = () => (
  <>
    <path
      d="M21 3H7v14h14V3Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17 17v4H3V7h4M12 10h4M14 8v4"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
)

export default CopyIcon
