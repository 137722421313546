import { Workbook } from '@netpurpose/types'
import { parseDatapointPolymorph } from '../datapoint/parseDatapointPolymorph'
import { BackendWorkbook } from './types'

export const parseWorkbook = (workbook: BackendWorkbook): Workbook => ({
  id: workbook.workbook_id,
  name: workbook.workbook_name,
  datapoints: workbook.datapoints.map((dp) => parseDatapointPolymorph(dp)),
  defines: workbook.defines.map((dp) => parseDatapointPolymorph(dp)),
})
