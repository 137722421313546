import { MetricPolymorph, MetricsById } from '@netpurpose/types'
import { AxiosInstances } from '../../client'
import { SimpleAbstractModelApi } from '../SimpleAbstractModelApi'
import { formatMetric } from './formatMetric'
import {
  BackendMetric,
  BackendMetricPolymorph,
  parseMetric,
  reverseMetricFieldMap,
} from './parseMetric'

export default class MetricApi extends SimpleAbstractModelApi<
  BackendMetricPolymorph,
  MetricPolymorph
> {
  endpoint = '/metrics/'
  modelFormatter = formatMetric
  reverseFieldMap = reverseMetricFieldMap

  constructor(axiosInstances: AxiosInstances) {
    super(axiosInstances, parseMetric)
    this.fetchAllMetrics = this.fetchAllMetrics.bind(this)
  }

  async fetchAllMetrics(includeSimple = false): Promise<MetricsById> {
    const { data } = await this.api.get<{ results: BackendMetric[] }>(this.endpoint, {
      // This API combines the "old" and "new" approach. Limit defaults to 1000 on
      // the backend, which as of November 2022 we've reached on prod, so it's
      // easiest just to set the limit in the request higher than it will ever reach.
      params: { q: 'limit=9999', include_simple: includeSimple },
    })

    return data.results.reduce(
      (acc, metric) => ({ ...acc, [metric.metric_id]: this.listResultParser(metric) }),
      {},
    )
  }
}
