import { FC } from 'react'

const DeleteIcon: FC = () => (
  <>
    <path
      d="M7 21C6.45 21 5.97933 20.8043 5.588 20.413C5.19667 20.0217 5.00067 19.5507 5 19V6C4.71667 6 4.47933 5.904 4.288 5.712C4.09667 5.52 4.00067 5.28267 4 5C4 4.71667 4.096 4.47933 4.288 4.288C4.48 4.09667 4.71733 4.00067 5 4H9C9 3.71667 9.096 3.47933 9.288 3.288C9.48 3.09667 9.71733 3.00067 10 3H14C14.2833 3 14.521 3.096 14.713 3.288C14.905 3.48 15.0007 3.71733 15 4H19C19.2833 4 19.521 4.096 19.713 4.288C19.905 4.48 20.0007 4.71733 20 5C20 5.28333 19.904 5.521 19.712 5.713C19.52 5.905 19.2827 6.00067 19 6V19C19 19.55 18.8043 20.021 18.413 20.413C18.0217 20.805 17.5507 21.0007 17 21H7ZM17 6H7V19H17V6ZM10 17C10.2833 17 10.521 16.904 10.713 16.712C10.905 16.52 11.0007 16.2827 11 16V9C11 8.71667 10.904 8.47933 10.712 8.288C10.52 8.09667 10.2827 8.00067 10 8C9.71667 8 9.47933 8.096 9.288 8.288C9.09667 8.48 9.00067 8.71733 9 9V16C9 16.2833 9.096 16.521 9.288 16.713C9.48 16.905 9.71733 17.0007 10 17ZM14 17C14.2833 17 14.521 16.904 14.713 16.712C14.905 16.52 15.0007 16.2827 15 16V9C15 8.71667 14.904 8.47933 14.712 8.288C14.52 8.09667 14.2827 8.00067 14 8C13.7167 8 13.4793 8.096 13.288 8.288C13.0967 8.48 13.0007 8.71733 13 9V16C13 16.2833 13.096 16.521 13.288 16.713C13.48 16.905 13.7173 17.0007 14 17Z"
      fill="currentColor"
    />
  </>
)

export default DeleteIcon
