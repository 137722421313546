import { Segment } from '@netpurpose/types'
import { Segment as BackendSegment } from '../../generated/facts'
import { ReverseFieldMap } from '../../queryBuilder'

// @ts-expect-error
export const formatSegment = (segment: Partial<Segment>): Partial<BackendSegment> => ({
  segment_id: segment.id,
  segment_category: segment.category,
  segment_class: segment.class,
  segment_instance: segment.instance,
})

export const reverseSegmentFieldMap: ReverseFieldMap<keyof Segment> = {
  id: 'segment_id',
  category: 'segment_category',
  class: 'segment_class',
  instance: 'segment_instance',
  // TODO: fix the types to make it not be so coupled between the api type and
  // the frontend type of an object, a ticket exists for this in Linear
  name: 'not_used',
}
