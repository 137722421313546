import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useMemo } from 'react'
import { User, UserFormType, UserInfo } from '@netpurpose/types'
import { noop } from '@netpurpose/utils'
import { USER_STALE_TIME } from '../../config'
import { useApi } from '../useApi'

type Options = {
  fetchIsEnabled?: boolean
  userId?: User['id'] | undefined
  onCreateSuccess?: (data: UserInfo | undefined) => void
  onActivateSuccess?: (data: UserInfo | undefined) => void
  onDeleteSuccess?: () => void
  updateLoggedInUser?: boolean
}

export const useUser = (userOptions?: Options) => {
  const defaultOptions: Options = {
    fetchIsEnabled: false,
  }
  const {
    fetchIsEnabled,
    userId,
    onCreateSuccess,
    onDeleteSuccess,
    onActivateSuccess,
    updateLoggedInUser,
  } = {
    ...defaultOptions,
    ...userOptions,
  }

  const { api } = useApi()
  const { data: userInfo, isFetching: isUserInfoFetching } = useQuery({
    queryKey: ['userInfo'],
    queryFn: api.users.getUserInfo,
    enabled: Boolean(fetchIsEnabled),
    staleTime: USER_STALE_TIME,
  })
  const {
    data: fetchedUserInfo,
    isFetching: isGetUserFetching,
    isError: isGetUserError,
  } = useQuery({
    queryKey: ['getUser', userId],
    queryFn: () => api.users.getUser(userId),
    enabled: Boolean(userId),
    staleTime: USER_STALE_TIME,
  })
  const {
    mutate: createUser,
    isPending: isCreateUserLoading,
    data: createUserResponse,
  } = useMutation({
    mutationFn: (values: UserFormType) => api.users.createUser(values),
    onSuccess: onCreateSuccess ? (data) => onCreateSuccess(data) : noop,
  })

  const {
    mutate: activateUser,
    isPending: isActivatingUser,
    data: activateUserResponse,
  } = useMutation({
    mutationFn: (values: UserFormType) => api.users.createUser(values),
    onSuccess: onActivateSuccess ? (data) => onActivateSuccess(data) : noop,
  })

  const queryClient = useQueryClient()

  const { mutate: updateUser, isPending: isUpdateUserLoading } = useMutation({
    mutationFn: (values: UserFormType) =>
      api.users.updateUser(values, updateLoggedInUser ? userInfo?.userId : userId),
    onSuccess: () => {
      queryClient.refetchQueries({ queryKey: ['getUser', userId], type: 'active', exact: true })
      // In case the logged-in user is editing their own profile.
      queryClient.refetchQueries({ queryKey: ['userInfo'], type: 'active', exact: true })
    },
  })

  const { mutate: deactivateUser, isPending: isDeactivatingUser } = useMutation({
    mutationFn: () => api.users.deactivateUser(userId),
    onSuccess: () => {
      queryClient
        .invalidateQueries({
          queryKey: ['users'],
        })
        .then(() => {
          onDeleteSuccess?.()
        })
    },
  })

  const fetchedUser = useMemo(() => {
    return fetchedUserInfo ? new User(fetchedUserInfo) : undefined
  }, [fetchedUserInfo])

  const user = useMemo(() => {
    return userInfo ? new User(userInfo) : undefined
  }, [userInfo])

  const { mutate: resendEmail, isPending: isResendingEmail } = useMutation({
    mutationFn: ({ userEmail }: { userEmail: string }) => api.users.sendSignupEmail(userEmail),
  })

  return {
    data: { user, fetchedUser, createUserResponse, activateUserResponse },
    actions: { createUser, updateUser, deactivateUser, activateUser, resendEmail },
    isFetching: {
      isUserInfoFetching,
      isCreateUserLoading,
      isUpdateUserLoading,
      isGetUserFetching,
      isDeactivatingUser,
      isActivatingUser,
      isResendingEmail,
    },
    isError: {
      isGetUserError,
    },
  }
}
