import { pickBy } from 'lodash'
import {
  PageConfidence,
  PagePredictions,
  Source,
  SourceFileType,
  SourceReportType,
  Theme,
} from '@netpurpose/types'
import { convertStringToEnum, valueIsDefined } from '@netpurpose/utils'
import { CreateSource, SourceWithLabelPredictions, StatusEnum } from '../../generated/facts'
import { ReverseFieldMap } from '../../queryBuilder'
import { Camelize } from '../../utils'
import { parseDateFromBackend } from '../datetime'

export type BackendPageConfidence = { page_number: number; confidence: number }

export type BackendPagePredictions = {
  model_id: string
  top_pages_per_label: Record<string, BackendPageConfidence[]>
}

export const parsePagesConfidence = (pages: BackendPageConfidence[]): PageConfidence[] =>
  pages.map((page: BackendPageConfidence) => ({
    // NOTE: this is 1 indexed
    pageNumber: page.page_number,
    confidence: page.confidence,
  }))

export const parsePagePredictions = (predictions: BackendPagePredictions): PagePredictions => ({
  modelId: predictions.model_id,
  topPagesPerLabel: Object.entries(predictions.top_pages_per_label).reduce(
    (acc, [key, pages]) => ({
      ...acc,
      [key]: parsePagesConfidence(pages),
    }),
    {} as Record<Theme, PageConfidence[]>,
  ),
})

export type BackendSource = CreateSource & SourceWithLabelPredictions

const getThumbnailUrl = (source: BackendSource) =>
  source.file_type === 'pdf' ? `${source.url.slice(0, -4)}_thumbnail.png` : undefined

export const parseSource = (source: BackendSource): Source => ({
  id: source.source_id || 0,
  name: source.name,
  originalUrl: source.original_url,
  url: source.url,
  fileType: convertStringToEnum(source.file_type as string, SourceFileType),
  status: convertStringToEnum(source.status as string, StatusEnum),
  ...pickBy(
    {
      start: source.start ? parseDateFromBackend(source.start) : undefined,
      end: source.end ? parseDateFromBackend(source.end) : undefined,
      publishingDate: source.publishing_date
        ? parseDateFromBackend(source.publishing_date)
        : undefined,
      reportType: source.report_type
        ? convertStringToEnum(source.report_type as string, SourceReportType)
        : undefined,
      checksum: source.checksum,
      entityId: source.entity_id || undefined,
      thumbnailUrl: getThumbnailUrl(source),
      predictions: source.predictions
        ? {
            modelId: source.predictions.model_id,
            confidencePerLabel: source.predictions.confidence_per_label,
          }
        : undefined,
      numUniqueEntityOccurrences: source.num_unique_entity_occurrences,
    },
    valueIsDefined,
  ),
})

export const reverseSourceFieldMap: ReverseFieldMap<keyof Source | 'entityName' | 'searchTerm'> = {
  id: 'source_id',
  entityId: 'entity_id',
  name: 'name',
  fileType: 'file_type',
  reportType: 'report_type',
  start: 'start',
  end: 'end',
  publishingDate: 'publishing_date',
  entityName: 'entity.name',
  checksum: 'checksum',
  originalUrl: 'original_url',
  predictions: 'predictions',
  status: 'status',
  // Just to keep types happy, this is front-end only.
  thumbnailUrl: '',
  url: 'url',
  numUniqueEntityOccurrences: 'num_unique_entity_occurences',
  searchTerm: 'search_term',
}

export const sourceStatusNames = {
  [StatusEnum.EXTRACTED]: 'Extracted',
  [StatusEnum.VERIFIED]: 'Verified',
  [StatusEnum.CHECK_ENTITY]: 'Check entity',
  [StatusEnum.NEW]: 'New',
  [StatusEnum.REJECTED]: 'Rejected',
  [StatusEnum.CANDIDATE]: 'Candidate',
} as const

export const transformSource = <
  SourceProps extends Omit<Camelize<SourceWithLabelPredictions>, 'sourceId'> & {
    sourceId?: number
  },
>({
  sourceId,
  name,
  entityId,
  url,
  originalUrl,
  fileType,
  start,
  end,
  publishingDate,
  reportType,
  checksum,
  predictions,
  numUniqueEntityOccurrences,
  status,
  ...rest
}: SourceProps): Source => ({
  ...rest,
  id: sourceId || 0,
  name: name || '',
  url,
  originalUrl,
  // NOTE: this is a temp shortcut, we should switch to use BE enums
  fileType: convertStringToEnum(fileType as string, SourceFileType),
  status: convertStringToEnum(status as string, StatusEnum),
  ...pickBy(
    {
      start: start ? parseDateFromBackend(start) : undefined,
      end: end ? parseDateFromBackend(end) : undefined,
      publishingDate: publishingDate ? parseDateFromBackend(publishingDate) : undefined,
      reportType: reportType
        ? // NOTE: this is a temp shortcut, we should switch to use BE enums
          convertStringToEnum(reportType as string, SourceReportType)
        : undefined,
      checksum,
      entityId,
      predictions: predictions
        ? { modelId: predictions.modelId, confidencePerLabel: predictions.confidencePerLabel }
        : undefined,
      numUniqueEntityOccurrences,
      thumbnailUrl: getThumbnailUrl({ file_type: fileType, url } as BackendSource),
    },
    valueIsDefined,
  ),
})
