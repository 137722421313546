import { useContext } from 'react'
import { Api } from '../Api'
import { ApiContext, defaultApiContext } from '../context'

export const useApi = (): { api: Api } => {
  const context = useContext(ApiContext)
  const { api } = context
  if (context === defaultApiContext || !api) {
    throw new Error('useApi must be used within a ApiProvider')
  }
  return { api }
}
