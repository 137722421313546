import { MetricsConfig } from '@netpurpose/types'
import { AxiosInstances } from '../../client'
import { SimpleAbstractModelApi } from '../SimpleAbstractModelApi'
import { formatMetricsConfig } from './formatMetricsConfig'
import { BackendMetricsConfig, parseMetricsConfig } from './parseMetricsConfig'

export default class MetricsConfigApi extends SimpleAbstractModelApi<
  BackendMetricsConfig,
  MetricsConfig
> {
  endpoint = '/metrics_config'
  reverseFieldMap = undefined
  modelFormatter = formatMetricsConfig

  constructor(axiosInstances: AxiosInstances) {
    super(axiosInstances, parseMetricsConfig)
  }
}
