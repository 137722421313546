import { IConfig as UnleashConfig } from '@unleash/proxy-client-react'

type FirebaseConfig = {
  apiKey: string
  authDomain: string
  projectId: string
  storageBucket: string
  messagingSenderId: string
  analyticsEnabled: boolean
  measurementId: string
  appId: string
}

type ApiConfig = {
  factsHost: string
  usersHost: string
  modellingHost: string
  semanticHost: string
  apiVersion: string
  env: string
  firebase: FirebaseConfig
  unleash: UnleashConfig
  // TODO: clean up use case of these properties, it's carried over tech debt from client-app
  // from when we combined the API configs into this package
  userServiceBaseUrl: string
  factServiceBaseUrl: string
  modellingServiceBaseUrl: string
}

const firebaseDevConfig = {
  apiKey: 'AIzaSyA-A1QTQJ_aJ_rdZSPh1SHTs4F9iA_0170',
  authDomain: 'net-purpose-staging.firebaseapp.com',
  projectId: 'net-purpose-staging',
  storageBucket: 'net-purpose-staging.appspot.com',
  messagingSenderId: '349694927709',
  analyticsEnabled: true,
  measurementId:
    process.env['NEXT_PUBLIC_NP_APP_NAME'] === 'clientApp' ? 'G-TQJWNH67YG' : 'G-YH97LEG0FY', // control tool is second option
  appId:
    process.env['NEXT_PUBLIC_NP_APP_NAME'] === 'clientApp'
      ? '1:349694927709:web:7a2afb978206f0020fbeb4'
      : '1:349694927709:web:6170c20d905ddd510fbeb4', // control tool
} as const satisfies FirebaseConfig

const firebaseProdConfig = {
  apiKey: 'AIzaSyBsTS3wK0vMLFRgQkB9SOZ92v3hbXLBVbo',
  authDomain: 'net-purpose-prod.firebaseapp.com',
  projectId: 'net-purpose-prod',
  storageBucket: 'net-purpose-prod.appspot.com',
  messagingSenderId: '802446705609',
  analyticsEnabled: true,
  measurementId:
    process.env['NEXT_PUBLIC_NP_APP_NAME'] === 'clientApp' ? 'G-ZBE0191NJQ' : 'G-N4LS3BJVJB', // control tool is second option
  appId:
    process.env['NEXT_PUBLIC_NP_APP_NAME'] === 'clientApp'
      ? '1:802446705609:web:5413ddde47fc889fb6de18'
      : '1:802446705609:web:ff0d856abaa89e93b6de18', // control tool
} as const satisfies FirebaseConfig

const unleashDevConfig = {
  url: 'https://unleash.staging.netpurpose.com/proxy',
  clientKey:
    process.env['NEXT_PUBLIC_NP_APP_NAME'] === 'clientApp'
      ? '*:development.532dc0a4b601ecaac4fe176890ae59a4c2485f3e834534e69efbae96'
      : '*:development.d24f4f59651b4ae080f933a57c8fe89be4712d8553fb5e445878c604', // control tool
  refreshInterval: 30, // seconds
  appName: 'np-unleash',
  environment: 'dev',
} as const satisfies UnleashConfig

const unleashProdConfig = {
  url: 'https://unleash.prod.netpurpose.com/proxy',
  clientKey:
    process.env['NEXT_PUBLIC_NP_APP_NAME'] === 'clientApp'
      ? '*:production.89ab13a2c02649b63121c8b5593cc66d6f098aa2fae2600bc8aef395'
      : '*:production.71acd85f1f7c5569fed2f9defb62c0fab356840f4e11ce55676a30fc', // control tool
  refreshInterval: 30, // seconds
  appName: 'np-unleash',
  environment: 'prod',
} as const satisfies UnleashConfig

const stagingConfig = {
  factsHost: 'https://facts.staging.netpurpose.com',
  usersHost: 'https://users.staging.netpurpose.com',
  modellingHost: 'https://modelling.staging.netpurpose.com',
  semanticHost: 'https://semantic-search.staging.netpurpose.com',
  apiVersion: 'v1',
  env: 'staging',
  firebase: firebaseDevConfig,
  unleash: unleashDevConfig,
  userServiceBaseUrl: 'https://users.staging.netpurpose.com/api/v1',
  factServiceBaseUrl: 'https://facts.staging.netpurpose.com/api/v1',
  modellingServiceBaseUrl: 'https://modelling.staging.netpurpose.com/api/v1',
} as const satisfies ApiConfig

const devStagingConfig = {
  ...stagingConfig,
  env: 'dev-staging',
  firebase: {
    ...firebaseDevConfig,
    analyticsEnabled: false,
  },
} as const satisfies ApiConfig

const devLocalConfig = {
  ...devStagingConfig,
  factsHost: 'http://facts.local:8000',
  usersHost: 'http://users.local:8080',
  modellingHost: 'http://modelling.local:8008',
  semanticHost: 'http://semantic-search.local:8090',
  env: 'dev-local',
  userServiceBaseUrl: 'http://users.local:8080/api/v1',
  factServiceBaseUrl: 'http://facts.local:8000/api/v1',
  modellingServiceBaseUrl: 'http://modelling.local:8008/api/v1',
} as const satisfies ApiConfig

const prodConfig = {
  factsHost: 'https://facts.prod.netpurpose.com',
  usersHost: 'https://users.prod.netpurpose.com',
  modellingHost: 'https://modelling.prod.netpurpose.com',
  semanticHost: 'https://semantic-search.prod.netpurpose.com',
  apiVersion: 'v1',
  env: 'prod',
  firebase: firebaseProdConfig,
  unleash: unleashProdConfig,
  userServiceBaseUrl: 'https://users.prod.netpurpose.com/api/v1',
  factServiceBaseUrl: 'https://facts.prod.netpurpose.com/api/v1',
  modellingServiceBaseUrl: 'https://modelling.prod.netpurpose.com/api/v1',
} as const satisfies ApiConfig

export const getApiConfig = (): ApiConfig => {
  switch (process.env['NEXT_PUBLIC_DEPLOYMENT_ENV']) {
    case 'prod':
      return prodConfig
    case 'staging':
      return stagingConfig
    case 'dev-local':
      return devLocalConfig
    case 'dev-staging':
      return devStagingConfig
    default:
      return devStagingConfig
  }
}

export const USER_STALE_TIME = 2500
const DEFAULT_PAGE_SIZE = 50
export const apiConfig = {
  ...getApiConfig(),
  defaultFetchSize: DEFAULT_PAGE_SIZE,
  defaultTablePagination: {
    showSizeChanger: false,
    hideOnSinglePage: true,
    pageSize: DEFAULT_PAGE_SIZE,
  },
  defaultStaleTime: process.env.NODE_ENV === 'test' ? 0 : 60 * 1000, // 1min
  defaultPaginatedStaleTime: process.env.NODE_ENV === 'test' ? 0 : 30 * 1000, // 30s
  defaultListStaleTime: process.env.NODE_ENV === 'test' ? 0 : 5 * 1000, // 5s
} as const
