import { FC } from 'react'

const SdgWaterIcon: FC = () => (
  <>
    <g transform="translate(0 9)">
      <path
        d="M2 2C7.5 2 7.5 6 12.98 6C18.46 6 18.48 2 23.98 2C29.48 2 29.48 6 34.98 6C40.48 6 40.48 2 45.98 2"
        stroke="white"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2 14C7.5 14 7.5 18 12.98 18C18.46 18 18.48 14 23.98 14C29.48 14 29.48 18 34.98 18C40.48 18 40.48 14 45.98 14"
        stroke="white"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2 26C7.5 26 7.5 30 12.98 30C18.46 30 18.48 26 23.98 26C29.48 26 29.48 30 34.98 30C40.48 30 40.48 26 45.98 26"
        stroke="white"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </>
)

export default SdgWaterIcon
