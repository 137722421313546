import { FC } from 'react'

const No: FC = () => (
  <>
    <g clipPath="url(#clip0_7216_4123)">
      <path
        d="M12 19C15.866 19 19 15.866 19 12C19 8.13401 15.866 5 12 5C8.13401 5 5 8.13401 5 12C5 15.866 8.13401 19 12 19Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.5 16.5L16.5 7.5"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_7216_4123">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </>
)

export default No
