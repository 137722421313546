import { FC } from 'react'

const DataPage: FC = () => (
  <>
    <path
      d="M122 162H40V33"
      stroke="currentColor"
      strokeWidth="8"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M152 33V162"
      stroke="currentColor"
      strokeWidth="8"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M55 18H167"
      stroke="currentColor"
      strokeWidth="8"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M104 63V119"
      stroke="currentColor"
      strokeWidth="8"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M88 83V119"
      stroke="currentColor"
      strokeWidth="8"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M72 103V119"
      stroke="currentColor"
      strokeWidth="8"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M120 103V119"
      stroke="currentColor"
      strokeWidth="8"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M182 33C182 24.72 175.28 18 167 18C158.72 18 152 24.72 152 33"
      stroke="currentColor"
      strokeWidth="8"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M55 18C46.72 18 40 24.72 40 33"
      stroke="currentColor"
      strokeWidth="8"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M182 33H152"
      stroke="currentColor"
      strokeWidth="8"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M122 162C122 170.28 128.72 177 137 177C145.28 177 152 170.28 152 162"
      stroke="currentColor"
      strokeWidth="8"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M25 162C25 170.28 31.72 177 40 177"
      stroke="currentColor"
      strokeWidth="8"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M25 162H40"
      stroke="currentColor"
      strokeWidth="8"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M40 177H137"
      stroke="currentColor"
      strokeWidth="8"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
)

export default DataPage
