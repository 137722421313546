import { Kpi } from '@netpurpose/types'
import { AxiosInstances } from '../../client'
import { SimpleAbstractModelApi } from '../SimpleAbstractModelApi'
import { formatKpi, reverseKpiFieldMap } from './formatKpi'
import { BackendKpi, parseKpi } from './parseKpi'

export class KpiApi extends SimpleAbstractModelApi<BackendKpi, Kpi> {
  endpoint = '/kpis/'
  modelFormatter = formatKpi
  reverseFieldMap = reverseKpiFieldMap

  constructor(axiosInstances: AxiosInstances) {
    super(axiosInstances, parseKpi)
  }
}
