import {
  IngestionBatch,
  IngestionBatchWithTasks,
  IngestionTaskState,
  IngestionTaskStep,
  Theme,
} from '@netpurpose/types'
import { convertStringToEnum } from '@netpurpose/utils'
import { ReverseFieldMap } from '../../queryBuilder'
import { parseIngestionTask } from './parseIngestionTask'
import { BackendIngestionBatch, BackendIngestionBatchWithTasks } from './types'

export const parseIngestionBatch = (batch: BackendIngestionBatch): IngestionBatch => ({
  id: batch.batch_id,
  theme: convertStringToEnum(batch.theme, Theme),
  phase: batch.phase,
  step: convertStringToEnum(batch.step, IngestionTaskStep),
  state: convertStringToEnum(batch.state, IngestionTaskState),
  totalTasks: batch.total_tasks,
  completedTasks: batch.completed_tasks,
  outstandingTasks: batch.total_tasks - batch.completed_tasks,
  totalTimeSpent: batch.total_time_spent,
  expectedTimeRemaining: batch.expected_time_remaining,
  priority: batch.priority,
})

export const parseIngestionBatchWithTasks = (
  batch: BackendIngestionBatchWithTasks,
): IngestionBatchWithTasks => {
  const { tasks, ...rest } = batch
  return {
    ...parseIngestionBatch(rest),
    tasks: tasks.map((task) => parseIngestionTask(task)),
  }
}

export const reverseIngestionBatchFieldMap: ReverseFieldMap<keyof IngestionBatchWithTasks> = {
  id: 'batch_id',
  theme: 'theme',
  completedTasks: 'completed_tasks',
  totalTimeSpent: 'total_time_spent',
  expectedTimeRemaining: 'expected_time_remaining',
  phase: 'phase',
  step: 'step',
  state: 'state',
  priority: 'priority',
  // There is no such property on the back end, this is calculated on the front end. See above.
  outstandingTasks: '',
  totalTasks: 'total_tasks',
  tasks: 'tasks',
}
