import { stringify } from 'qs'
import { IngestionTask, IngestionTaskStep, SourceTaskType } from '@netpurpose/types'
import { AxiosInstances } from '../../client'
import { joinPaths } from '../../utils'
import { SimpleAbstractModelApi } from '../SimpleAbstractModelApi'
import { formatIngestionTask } from './formatIngestionTask'
import { parseIngestionTask } from './parseIngestionTask'
import { BackendIngestionTask } from './types'

export class IngestionTaskApi extends SimpleAbstractModelApi<BackendIngestionTask, IngestionTask> {
  endpoint = '/tasks/ingestion'
  modelFormatter = formatIngestionTask
  reverseFieldMap = undefined

  constructor(axiosInstances: AxiosInstances) {
    super(axiosInstances, parseIngestionTask)
    this.abandonTask = this.abandonTask.bind(this)
    this.returnTaskToQueue = this.returnTaskToQueue.bind(this)
    this.getNextTask = this.getNextTask.bind(this)
    this.submitTask = this.submitTask.bind(this)
    this.validateTask = this.validateTask.bind(this)
    this.createTasks = this.createTasks.bind(this)
  }

  async abandonTask(taskId: IngestionTask['id']) {
    await this.api.put(joinPaths(this.endpoint, taskId, 'abandon'))
  }

  async returnTaskToQueue({
    taskId,
    returnToUser,
  }: {
    taskId: IngestionTask['id']
    returnToUser: boolean
  }) {
    await this.api.put(`${this.endpoint}/${taskId}/return_to_queue?return_to_user=${returnToUser}`)
  }

  async getNextTask(step: IngestionTaskStep | null): Promise<IngestionTask | void> {
    if (!step) {
      return
    }

    const { data } = await this.api.get(joinPaths(this.endpoint, 'next', step))
    if (!data) {
      return
    }

    return parseIngestionTask(data)
  }

  async submitTask({
    taskId,
    cannotProgress,
  }: {
    taskId: IngestionTask['id']
    cannotProgress: boolean | undefined
  }): Promise<void> {
    await this.api.put(
      joinPaths(
        this.endpoint,
        taskId,
        `submit?${stringify({ cannot_progress: cannotProgress || false })}`,
      ),
    )
  }

  async validateTask({
    selfAssessed,
    taskId,
  }: {
    selfAssessed: boolean
    taskId: IngestionTask['id'] | undefined
  }): Promise<string> {
    if (!taskId) {
      return Promise.reject(new Error(`Task ID must be defined, received: ${taskId}`))
    }

    const { data } = await this.api.put(joinPaths(this.endpoint, taskId, 'autoqa'), null, {
      params: {
        self_assessed: selfAssessed,
      },
    })
    return data?.result_id as string
  }

  async addTaskElapsedTime({
    taskId,
    elapsedTime,
  }: {
    taskId: IngestionTask['id']
    elapsedTime: number
  }) {
    await this.api.put(joinPaths(this.endpoint, taskId, 'update_elapsed', elapsedTime))
  }

  async getTaskThemeInstructions({ theme }: { theme: IngestionTask['theme'] | null }) {
    if (!theme) {
      return Promise.reject(new Error(`Invalid theme: ${theme}`))
    }
    const { data } = await this.api.get(joinPaths(this.endpoint, 'theme_instructions', theme))
    return data
  }

  async createTasks(values: {
    file: File
    phase: number
    priority: number
    taskType: SourceTaskType
  }) {
    const formData = new FormData()
    formData.append('ingestion_tasks_csv', values.file)
    formData.append('phase', values.phase.toString())
    formData.append('priority', values.priority.toString())
    formData.append('task_type', values.taskType)

    const { data } = await this.api.post(joinPaths(this.endpoint, 'create_tasks'), formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    return data?.result_id as string
  }
}
