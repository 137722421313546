import { FC } from 'react'

const LogoIcon: FC = () => (
  <>
    <path
      d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.95431 0 0 8.9543 0 20C0 31.0457 8.95431 40 20 40Z"
      fill="#1B1B1B"
    />
    <path
      d="M13.3333 27.9991C13.3333 28.7349 12.7366 29.3316 12.0009 29.3316C11.2651 29.3316 10.6685 28.7349 10.6685 27.9991V11.9987C10.6685 11.2629 11.2651 10.6663 12.0009 10.6663C12.7366 10.6663 13.3333 11.2629 13.3333 11.9987V27.9991Z"
      fill="white"
    />
    <path
      d="M16.0005 27.9991C16.0005 28.7349 16.5972 29.3316 17.3329 29.3316C18.0687 29.3316 18.6654 28.7349 18.6654 27.9991V17.3329C18.6654 16.5972 18.0687 16.0005 17.3329 16.0005C16.5972 16.0005 16.0005 16.5972 16.0005 17.3329V27.9991Z"
      fill="white"
    />
    <path
      d="M26.6667 11.9987C26.6667 11.2629 27.2634 10.6663 27.9992 10.6663C28.7349 10.6663 29.3316 11.2629 29.3316 11.9987V27.9991C29.3316 28.7349 28.7349 29.3316 27.9992 29.3316C27.2634 29.3316 26.6667 28.7349 26.6667 27.9991V11.9987Z"
      fill="white"
    />
    <path
      d="M24.0018 11.9987C24.0018 11.2629 23.4051 10.6663 22.6694 10.6663C21.9336 10.6663 21.3369 11.2629 21.3369 11.9987V22.6649C21.3369 23.4007 21.9336 23.9974 22.6694 23.9974C23.4051 23.9974 24.0018 23.4007 24.0018 22.6649V11.9987Z"
      fill="white"
    />
  </>
)

export default LogoIcon
