import { isString } from '@netpurpose/types'

type TransformValueMapping = {
  key: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  transform: (v: any) => any
}

export type ReverseFieldMap<ModelKey extends PropertyKey> = Record<
  ModelKey,
  string | TransformValueMapping
>

export type QueryMapper<Model> = (k: keyof Model, values: unknown[]) => [string, unknown[]]

const isTransformValueMapping = (v: unknown): v is TransformValueMapping => !isString(v)

export const getQueryMapper =
  <T>(reverseFieldMap: Partial<ReverseFieldMap<keyof T>>): QueryMapper<T> =>
  (k, values) => {
    const mapping = reverseFieldMap[k]

    if (!mapping) {
      throw new Error(`reverse field map not implemented for ${String(k)}`)
    }

    return isTransformValueMapping(mapping)
      ? [mapping.key as string, values.map((v) => mapping.transform(v))]
      : [mapping, values]
  }

export type FieldMap<FrontendType, BackendType> = {
  format: {
    [K in keyof FrontendType]: string
  }
  parse: {
    [K in keyof BackendType]: keyof FrontendType
  }
}
