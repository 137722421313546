import { AxiosResponse } from 'axios'
import { isString } from '@netpurpose/types'

const unknownErrorMessage = 'Unknown error'

type DetailedErrorType = {
  msg: string
  loc?: string[]
}

const getDetailedErrorMessage = (input: DetailedErrorType) => {
  const location = input.loc?.join('.')
  if (location) {
    return `Error in ${location}:\n${input.msg}`
  }
  return input.msg
}

export type ErrorResponseType =
  // NOTE: added this union option below due to there being a test case for it, I (Fred) don't
  // actually think it exists, but better to be safe with the types
  | {
      message: string
      msg: string
    }
  | {
      message: string
    }
  | {
      msg: string
    }
  | {
      detail: string | DetailedErrorType | DetailedErrorType[]
    }

export const getMessageFromErrorResponse = (
  response: AxiosResponse<ErrorResponseType | undefined>,
): string => {
  if (response === undefined || 'data' in response === false) {
    return unknownErrorMessage
  }
  const { data } = response

  if (data === undefined) {
    return unknownErrorMessage
  }

  if ('message' in data && 'msg' in data) {
    return data.message
  }

  if ('message' in data) {
    return data.message
  }

  if ('msg' in data) {
    return data.msg
  }

  const { detail } = data

  if (isString(detail)) {
    return detail
  }

  if (!Array.isArray(detail)) {
    return getDetailedErrorMessage(detail)
  }

  const stringifiedErrors = detail.map((detailedError) => getDetailedErrorMessage(detailedError))

  // NOTE: Back end may return duplicate error messages, but only need to show one to users.
  return [...new Set(stringifiedErrors)].join('\n\n')
}
