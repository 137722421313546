import { UnitLegacy } from '@netpurpose/types'
import { AxiosInstances } from '../../client'
import { joinPaths } from '../../utils'
import { SimpleAbstractModelApi } from '../SimpleAbstractModelApi'
import { formatUnit, reverseUnitFieldMap } from './formatUnit'
import { BackendUnit, parseUnit } from './parseUnit'

export type ConvertUnitRequest = {
  value: number
  inUnitName: string
  outUnitName: string
  conversionDate: string
}

type ConvertUnitResponse = {
  value: number
  unit: BackendUnit
  fx_rate: number
}

export type ConvertUnitResult = {
  value: number
  unit: UnitLegacy
  fxRate: number
}

export default class UnitApi extends SimpleAbstractModelApi<BackendUnit, UnitLegacy> {
  endpoint = '/units'
  modelFormatter = formatUnit
  reverseFieldMap = reverseUnitFieldMap

  constructor(axiosInstances: AxiosInstances) {
    super(axiosInstances, parseUnit)
    this.convertUnit = this.convertUnit.bind(this)
  }

  async convertUnit(params: ConvertUnitRequest): Promise<ConvertUnitResult> {
    const { value, inUnitName, outUnitName, conversionDate } = params
    const { data } = await this.api.get<ConvertUnitResponse>(
      joinPaths(this.endpoint, 'convert_unit'),
      {
        params: {
          value,
          in_unit_name: inUnitName,
          out_unit_name: outUnitName,
          conversion_date: conversionDate,
        },
      },
    )

    return {
      value: data.value,
      unit: this.detailResultParser(data.unit),
      fxRate: data.fx_rate,
    }
  }
}
