import { FC } from 'react'

const DropdownArrowIcon: FC = () => (
  <>
    <path
      d="M11.475 14.475L7.84995 10.85C7.79995 10.8 7.76262 10.746 7.73795 10.688C7.71328 10.63 7.70062 10.5673 7.69995 10.5C7.69995 10.3667 7.74595 10.25 7.83795 10.15C7.92995 10.05 8.05062 10 8.19995 10H15.8C15.95 10 16.071 10.05 16.163 10.15C16.255 10.25 16.3006 10.3667 16.3 10.5C16.3 10.5333 16.25 10.65 16.15 10.85L12.525 14.475C12.4416 14.5583 12.3583 14.6167 12.275 14.65C12.1916 14.6833 12.1 14.7 12 14.7C11.9 14.7 11.8083 14.6833 11.725 14.65C11.6416 14.6167 11.5583 14.5583 11.475 14.475Z"
      fill="currentColor"
    />
  </>
)

export default DropdownArrowIcon
