import { MetricsConfig } from '@netpurpose/types'
import { ensureTimestampInUTCFormat } from '@netpurpose/utils'

export type BackendMetricsConfig = {
  created_by: {
    at: string // UTC timestamp
    name: string
    user_id: string // UUID
  } | null
  group_id: string // UUID
  metrics_config_id: string // UUID
  name: string
  question_ids: number[] | null
  updated_by: {
    at: string // UTC timestamp
    name: string
    user_id: string // UUID
  } | null
}

export const parseMetricsConfig = (metricsConfig: BackendMetricsConfig): MetricsConfig => ({
  id: metricsConfig.metrics_config_id,
  name: metricsConfig.name,
  groupId: metricsConfig.group_id,
  questionIds: metricsConfig.question_ids,
  createdBy: metricsConfig.created_by
    ? {
        at: ensureTimestampInUTCFormat(metricsConfig.created_by.at),
        name: metricsConfig.created_by.name,
        userId: metricsConfig.created_by.user_id,
      }
    : null,
  updatedBy: metricsConfig.updated_by
    ? {
        at: ensureTimestampInUTCFormat(metricsConfig.updated_by.at),
        name: metricsConfig.updated_by.name,
        userId: metricsConfig.updated_by.user_id,
      }
    : null,
})
