const Cross = () => (
  <svg viewBox="0 0 16 16">
    <path
      d="M4.70001 4.7L11.3 11.3"
      stroke="currentColor"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.70001 11.3L11.3 4.7"
      stroke="currentColor"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default Cross
