import '@react-pdf-viewer/core/lib/styles/index.css'
import '@react-pdf-viewer/highlight/lib/styles/index.css'
import '@react-pdf-viewer/toolbar/lib/styles/index.css'
import { colors } from './colors'
import './styles.css'

export const theme = {
  colors,
  sizing: {
    navbarHeight: '3rem',
    tableHeaderRowHeight: '2rem',
    tableRowHeight: '2.875rem',
    sidebarIconWidth: '3rem',
  },
  border: {
    width: '1px',
    radiusSmall: '4px',
    radius: '8px',
    radiusLarge: '10px',
    radiusExtraLarge: '15px',
  },
  opacity: {
    disabled: 0.4,
  },
  shadow: {
    regular: `0px 4px 8px ${colors.architecture7}0F`,
    upsideDown: `0px -4px 8px ${colors.architecture7}0F`,
  },
  breakpoints: {
    mobile: '768px',
  },
} as const
