import { UnitLegacy } from '@netpurpose/types'
import { ReverseFieldMap } from '../../queryBuilder'
import { BackendUnit } from './parseUnit'

// @ts-expect-error
export const formatUnit = (unit: Partial<UnitLegacy>): Partial<BackendUnit> => ({
  unit_id: unit.id,
  name: unit.name,
  description: unit.description,
  is_standard: unit.isStandard,
  unit_type: unit.type,
  iso_code: unit.iso,
  conversion_factor: unit.conversionFactor,
  standard_id: unit.standardUnitId,
})

export const reverseUnitFieldMap: ReverseFieldMap<keyof UnitLegacy> = {
  id: 'unit_id',
  name: 'name',
  description: 'description',
  isStandard: 'is_standard',
  type: 'unit_type',
  iso: 'iso_code',
  conversionFactor: 'conversion_factor',
  standardUnitId: 'standard_id',
}
