import { ApiKey } from '@netpurpose/types'
import { AxiosInstances } from '../../client'
import { AbstractApi } from '../AbstractApi'

export default class ApiKeyApi extends AbstractApi {
  constructor(axiosInstances: AxiosInstances) {
    super(axiosInstances)
    this.getApiKey = this.getApiKey.bind(this)
  }

  async getApiKey(): Promise<ApiKey> {
    const { data } = await this.api.post('/services')
    return data
  }
}
