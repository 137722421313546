import { CalculationTree, DataOrigin, DataOriginQuestion, KpiResult } from '@netpurpose/types'
import {
  CalculationTree as BackendCalculationTree,
  DataOrigin as BackendDataOrigin,
  OriginQuestion as BackendDataOriginQuestion,
} from '../../generated/facts'
import { parseSegment } from '../segment'

export type BackendKpiResult = {
  kpi_result_id: string
  value: [number | null, string]
  kpi_id: string
  entity_id: number
  start: string | null
  end: string
  year: number
  calculation_tree: BackendCalculationTree | null
}

const parseDataOriginQuestion = (question: BackendDataOriginQuestion): DataOriginQuestion => ({
  questionTypeId: question.question_type_id,
  questionType: question.question_type,
  questionId: question?.question_id,
  questionName: question?.question_name,
  year: question.year,
  segment: question?.segment ? parseSegment(question.segment) : undefined,
})

const parseDataOrigin = (dataOrigin: BackendDataOrigin): DataOrigin => ({
  dataSource: dataOrigin?.data_source,
  dataSourceId: dataOrigin?.data_source_id,
  factId: dataOrigin?.fact_id,
  question: parseDataOriginQuestion(dataOrigin.question),
})

const parseCalculationTree = (calculationTree: BackendCalculationTree): CalculationTree => ({
  value: calculationTree.value,
  date: calculationTree.date,
  dataOrigin:
    typeof calculationTree?.data_origin === 'string' || !calculationTree.data_origin
      ? calculationTree.data_origin
      : parseDataOrigin(calculationTree.data_origin),
  operation: calculationTree?.operation,
  children: !!calculationTree?.children?.length
    ? calculationTree.children.map(parseCalculationTree)
    : undefined,
})

export const parseKpiResult = (kpiResult: BackendKpiResult): KpiResult => ({
  id: kpiResult.kpi_result_id,
  value: { value: kpiResult.value[0], unit: kpiResult.value[1] },
  kpiId: kpiResult.kpi_id,
  entityId: kpiResult.entity_id,
  start: kpiResult.start,
  end: kpiResult.end,
  year: kpiResult.year,
  calculationTree: kpiResult.calculation_tree
    ? parseCalculationTree(kpiResult.calculation_tree)
    : null,
})
