import { FC } from 'react'

const FundamentalsIcon: FC = () => (
  <>
    <path
      d="M42 2H6C3.79086 2 2 3.79086 2 6V26C2 28.2091 3.79086 30 6 30H42C44.2091 30 46 28.2091 46 26V6C46 3.79086 44.2091 2 42 2Z"
      stroke="white"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M24 30V46"
      stroke="white"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 22L18 14L24 20L32 10L38 14"
      stroke="white"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14 46L24 36L34 46"
      stroke="white"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
)

export default FundamentalsIcon
