import { FC } from 'react'

const SdgDecentWorkIcon: FC = () => (
  <>
    <path
      d="M14 14V6C14 3.78 15.8 2 18 2H30C32.22 2 34 3.8 34 6V14"
      stroke="white"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2 23.8398L20.64 32.4398C22.76 33.4198 25.22 33.4198 27.34 32.4398L45.98 23.8398"
      stroke="white"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M42 14H6C3.79086 14 2 15.7909 2 18V42C2 44.2091 3.79086 46 6 46H42C44.2091 46 46 44.2091 46 42V18C46 15.7909 44.2091 14 42 14Z"
      stroke="white"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
)

export default SdgDecentWorkIcon
