import { FC } from 'react'

const XLargeIcon: FC = () => (
  <>
    <path
      d="M7.05078 7.05078L16.9508 16.9508"
      stroke="currentColor"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.05078 16.9508L16.9508 7.05078"
      stroke="currentColor"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
)

export default XLargeIcon
