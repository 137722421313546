import { FC } from 'react'

const ChevronDownIcon: FC = () => (
  <path
    d="M16 11L12 15L8 11"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  />
)

export default ChevronDownIcon
