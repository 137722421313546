import { MetricsConfig } from '@netpurpose/types'
import { BackendMetricsConfig } from './parseMetricsConfig'

export const formatMetricsConfig = (
  metricsConfig: Partial<MetricsConfig>,
  // @ts-expect-error
): Partial<BackendMetricsConfig> => ({
  metrics_config_id: metricsConfig.id,
  name: metricsConfig.name,
  question_ids: metricsConfig.questionIds,
})
