import { DeepCamelKeys as Camelize, deepCamelKeys, deepSnakeKeys } from 'string-ts'

export type { Camelize }

// Currently our types are generated so that:
//   - Type is defined in python code like `foo: str | None`
//   - Gets generated in OpenAPI spec as `foo: string` (as opposed to foo*: string,
//     indicating it is required).
//   - The TypeScript type gets generated as `foo?: string`
// However, the value is really `foo: string | null`. Therefore, by recursively checking
// for null values and converting them to undefined, we can be more confident the
// types are correct. Ideally we'd find a way to generate the OpenAPI spec to skip
// straight to generating `foo: string | null`, rendering this step unnecessary.
const replaceNullWithUndefined = <T extends object | unknown>(obj: T): T | unknown => {
  if (obj === null) {
    return undefined
  }

  if (typeof obj === 'object') {
    if (Array.isArray(obj)) {
      return obj.map((item) => replaceNullWithUndefined(item))
    } else {
      const result: Record<string, unknown> = {}
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          result[key] = replaceNullWithUndefined(obj[key])
        }
      }
      return result
    }
  }

  return obj
}

export const snakeToCamelKeys = <T>(inputObject: T) => {
  const inputUndefinedToNull = replaceNullWithUndefined(inputObject)
  return deepCamelKeys(inputUndefinedToNull as T)
}

export const camelToSnakeKeys = <T>(inputObject: T) => deepSnakeKeys(inputObject)
