import { Filters, FiltersOperator, Operator } from '@netpurpose/types'
import { getQueryMapper, ReverseFieldMap } from './fieldMapping'

const getKey = (column: string, operator: Operator): string => {
  switch (operator) {
    case Operator.Before:
      return `${column}_end`
    case Operator.After:
      return `${column}_start`
    default:
      return `${operator}${column}`
  }
}

export const formatFilterParams = <FilterModel>(
  filterState: Filters<FilterModel>,
  fieldMapper: Partial<ReverseFieldMap<keyof FilterModel>>,
  operator?: FiltersOperator,
) => {
  const queryMapper = getQueryMapper(fieldMapper)
  return Object.entries<Filters<FilterModel>[keyof FilterModel]>(filterState)
    .filter(([_, filters]) => !!filters)
    .reduce(
      (allFiltersAcc, [column, filters]) => {
        const [backendColumn, backendValues] = queryMapper(
          column as keyof FilterModel,
          filters ? filters.map((f) => f.value) : [],
        )
        const columnFilters = filters
          ? filters.reduce<Record<string, unknown>>((columnFiltersAcc, filter, index) => {
              const key = getKey(backendColumn, filter.operator)
              const backendValue = backendValues[index]

              const multipleFiltersOnKey = Boolean(columnFiltersAcc[key])
              const multiFilter =
                multipleFiltersOnKey && Array.isArray(columnFiltersAcc[key])
                  ? [...(columnFiltersAcc[key] as unknown[]), backendValue]
                  : [columnFiltersAcc[key], backendValue]

              return {
                ...columnFiltersAcc,
                [key]: multipleFiltersOnKey ? multiFilter : backendValue,
              }
            }, {})
          : {}
        return {
          ...allFiltersAcc,
          ...columnFilters,
          ...(operator ? { operator } : {}),
        }
      },
      {} as Record<string, unknown>,
    )
}
