import { EstimationBase, EstimationDetail, EstimationList } from '@netpurpose/types'
import { ReverseFieldMap } from '../../queryBuilder'
import { parseTechnologyCost } from '../datapoint'
import { parseMaybeDatapointPolymorph } from '../datapoint/parseDatapointPolymorph'
import { BackendEstimationBase, BackendEstimationDetail, BackendEstimationList } from './types'

// @ts-expect-error
export const parseEstimationBase = (estimationBase: BackendEstimationBase): EstimationBase => ({
  entityId: estimationBase.entity_id,
  technologyId: estimationBase.technology_id,
  technologyName: estimationBase.technology_name,
  reportingEndDate: new Date(estimationBase.reporting_end_date),
  workbookName: estimationBase.workbook_name,
  workbookId: estimationBase.workbook_id,
})

export const parseEstimationList = (estimation: BackendEstimationList): EstimationList => ({
  id: estimation.workbook_id,
  entityId: estimation.entity_id,
  technologyId: estimation.technology_id,
  technologyName: estimation.technology_name,
  reportingEndDate: estimation.reporting_end_date,
  workbookId: estimation.workbook_id,
  workbookName: estimation.workbook_name,
  status: estimation.status,
  impactTheme: estimation.impact_theme,
  revenue: parseMaybeDatapointPolymorph(estimation.revenue) || null,
  output: parseMaybeDatapointPolymorph(estimation.output) || null,
  outcomeLevel1: parseMaybeDatapointPolymorph(estimation.outcome_level_1) || null,
  outcomeLevel2: parseMaybeDatapointPolymorph(estimation.outcome_level_2) || null,
  activityCost: estimation.activity_cost ? parseTechnologyCost(estimation.activity_cost) : null,
  baselineCost: estimation.baseline_cost ? parseTechnologyCost(estimation.baseline_cost) : null,
  impact: parseMaybeDatapointPolymorph(estimation.impact) || null,
})

export const parseEstimationDetail = (estimation: BackendEstimationDetail): EstimationDetail => ({
  id: estimation.workbook_id,
  entityId: estimation.entity_id,
  technologyId: estimation.technology_id,
  technologyName: estimation.technology_name,
  reportingEndDate: estimation.reporting_end_date,
  workbookId: estimation.workbook_id,
  workbookName: estimation.workbook_name,
  status: estimation.status,
  revenue: parseMaybeDatapointPolymorph(estimation.revenue) || null,
  output: parseMaybeDatapointPolymorph(estimation.output) || null,
  outcomeLevel1: parseMaybeDatapointPolymorph(estimation.outcome_level_1) || null,
  outcomeLevel2s: estimation.outcome_level_2s?.map(parseMaybeDatapointPolymorph) || [],
  activityCosts: estimation.activity_costs?.map((ac) => parseMaybeDatapointPolymorph(ac)) || [],
  baselineCosts: estimation.baseline_costs?.map((bc) => parseMaybeDatapointPolymorph(bc)) || [],
  impact: parseMaybeDatapointPolymorph(estimation.impact) || null,
})

export const reverseEstimationFieldMap: ReverseFieldMap<
  keyof EstimationList | keyof EstimationDetail | 'impactTheme' | 'entityName'
> = {
  id: 'workbook_id',
  workbookId: 'workbook_id',
  workbookName: 'workbook_name',
  entityId: 'entity_id',
  technologyId: 'technology_id',
  technologyName: 'technology_name',
  status: 'status',
  reportingEndDate: 'reporting_end_date',
  impactTheme: 'impact_theme',
  entityName: 'entity.name',
  activityCosts: 'activity_costs',
  baselineCosts: 'baseline_costs',
  impact: 'impact',
  outcomeLevel1: 'outcome_level_1',
  outcomeLevel2s: 'outcome_level_2s',
  output: 'output',
  revenue: 'revenue',
  activityCost: 'activity_cost',
  baselineCost: 'baseline_cost',
  outcomeLevel2: 'outcome_level_2',
}
