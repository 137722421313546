import { FC } from 'react'

const CheckIcon: FC = () => (
  <path
    d="M22.3337 4L9.00033 20L3.66699 14.6667"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  />
)

export default CheckIcon
