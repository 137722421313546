import { ById } from '../utils'
import { Keyword } from './keyword'
import { QuestionGoal, QuestionImpactLevel } from './question'
import { Standard } from './standard'

export enum MetricPolymorphs {
  Simple = 'simple',
  Major = 'major',
}

export enum MetricType {
  Stock = 'stock',
  Flow = 'flow',
}

export type BaseMetric = {
  id: string
  name: string
  displayName: string
  questionId: number
  isEstimated: boolean
  isReported: boolean
  displayPriority: number | null
}

export type SimpleMetric = BaseMetric & {
  description: string | undefined
  unitId: number | undefined
  type: MetricPolymorphs.Simple
}

export type MetricLegacy = BaseMetric & {
  type: MetricPolymorphs.Major
  description: string
  unitId: number
  themes?: string[]
  displayThemes: string[]
  metricType: MetricType | undefined
  instructions?: string
  activeExport: boolean | undefined
  activeExtraction: boolean | undefined
  extractionPriority: number | undefined
  keywords?: Keyword[]
  reportingStandards: Standard[]
  goal: QuestionGoal
  impactLevel: QuestionImpactLevel
  isSdgEligible?: boolean
}

export type MetricPolymorph = SimpleMetric | MetricLegacy

export type MetricsById = ById<MetricPolymorph>

export type MetricsByQuestionId = { [questionId: MetricPolymorph['questionId']]: MetricPolymorph }
