import { FC } from 'react'

const DoubleChevronLeft: FC = () => (
  <path
    d="m34.25 34.9-9.9-9.85q-.25-.25-.35-.5-.1-.25-.1-.55 0-.3.1-.575.1-.275.35-.525l9.9-9.9q.45-.45 1.05-.425.6.025 1.05.475.45.45.45 1.05 0 .6-.45 1.05L27.5 24l8.85 8.85q.45.45.45 1.025t-.45 1.025q-.45.45-1.05.45-.6 0-1.05-.45Zm-12.65 0-9.9-9.85q-.25-.25-.35-.5-.1-.25-.1-.55 0-.3.1-.575.1-.275.35-.525l9.9-9.9q.45-.45 1.05-.425.6.025 1.05.475.45.45.45 1.05 0 .6-.45 1.05L14.85 24l8.85 8.85q.45.45.45 1.025T23.7 34.9q-.45.45-1.05.45-.6 0-1.05-.45Z"
    fill="currentColor"
    strokeWidth="2"
  />
)

export default DoubleChevronLeft
