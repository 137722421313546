import {
  SourceBatch,
  SourceBatchWithTasks,
  SourceTaskState,
  SourceTaskStep,
} from '@netpurpose/types'
import { convertStringToEnum } from '@netpurpose/utils'
import { ReverseFieldMap } from '../../queryBuilder'
import { parseSourceTask } from './parseSourceTask'
import { BackendSourceBatch, BackendSourceBatchWithTasks } from './types'

export const parseSourceBatch = (batch: BackendSourceBatch): SourceBatch => ({
  id: batch.batch_id,
  phase: batch.phase,
  step: convertStringToEnum(batch.step, SourceTaskStep),
  state: convertStringToEnum(batch.state, SourceTaskState),
  totalTasks: batch.total_tasks,
  completedTasks: batch.completed_tasks,
  outstandingTasks: batch.total_tasks - batch.completed_tasks,
  totalTimeSpent: batch.total_time_spent,
  expectedTimeRemaining: batch.expected_time_remaining,
  entityId: batch.entity_id,
  priority: batch.priority,
})

export const parseSourceBatchWithTasks = (
  batch: BackendSourceBatchWithTasks,
): SourceBatchWithTasks => {
  const { tasks, ...rest } = batch
  return {
    ...parseSourceBatch(rest),
    tasks: tasks.map(parseSourceTask),
  }
}

export const reverseSourceBatchFieldMap: ReverseFieldMap<keyof SourceBatchWithTasks> = {
  id: 'batch_id',
  completedTasks: 'completed_tasks',
  totalTimeSpent: 'total_time_spent',
  expectedTimeRemaining: 'expected_time_remaining',
  phase: 'phase',
  step: 'step',
  state: 'state',
  priority: 'priority',
  entityId: 'entity_id',
  // There is no such property on the back end, this is calculated on the front end. See above.
  outstandingTasks: '',
  tasks: 'tasks',
  totalTasks: 'total_tasks',
}
