import {
  SearchLink,
  SourceTask,
  SourceTaskState,
  SourceTaskStep,
  SourceTaskType,
} from '@netpurpose/types'
import { convertStringToEnum } from '@netpurpose/utils'
import { parseSource } from '../source'
import { BackendSearchLink, BackendSourceTask } from './types'

export const parseSearchLink = ({
  url,
  entity_name,
  year,
  report_type,
}: BackendSearchLink): SearchLink => {
  return {
    url,
    entityName: entity_name,
    year,
    reportType: report_type,
    description: `${report_type} ${year}`,
  }
}

// @ts-expect-error
export const parseSourceTask = (sourceTask: BackendSourceTask): SourceTask => ({
  id: sourceTask.task_id,
  batchId: sourceTask.batch_id,
  entityId: sourceTask.entity_id,
  priority: sourceTask.priority,
  state: convertStringToEnum(sourceTask.state, SourceTaskState),
  step: convertStringToEnum(sourceTask.step, SourceTaskStep),
  type: convertStringToEnum(sourceTask.type, SourceTaskType),
  years: sourceTask.years,
  userId: sourceTask.user_id,
  expectedTimeRemaining: sourceTask.expected_time_remaining,
  totalTimeSpent: sourceTask?.total_time_spent,
  searchLinks: sourceTask.search_links?.map(parseSearchLink),
  message: sourceTask.message,
  sources: sourceTask.sources?.map(parseSource),
})
