import { FC } from 'react'

const SdgIndustryIcon: FC = () => (
  <>
    <path
      d="M22 14C25.3137 14 28 11.3137 28 8C28 4.68629 25.3137 2 22 2C18.6863 2 16 4.68629 16 8C16 11.3137 18.6863 14 22 14Z"
      stroke="white"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2 46L18 12.48"
      stroke="white"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M26 12.48L30.92 22.8"
      stroke="white"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M37.9199 37.4399L41.9999 45.9999"
      stroke="white"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M41.6004 22C37.9604 29.12 30.5404 34 22.0004 34C13.4604 34 6.04039 29.12 2.40039 22"
      stroke="white"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
)

export default SdgIndustryIcon
