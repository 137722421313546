import {
  CreateFactsSetPayload,
  Fact,
  FactFilters,
  Filters,
  IngestionTask,
  IngestionTaskStep,
} from '@netpurpose/types'
import { AxiosInstances, encodeAndStringifyQueryParams } from '../../client'
import { formatFilterParams } from '../../queryBuilder'
import { joinPaths } from '../../utils'
import { formatDateForBackend } from '../datetime'
import { SimpleAbstractModelApi } from '../SimpleAbstractModelApi'
import { BackendFact, parseFact, reverseFactFieldMap } from './parseFact'

export default class FactApi extends SimpleAbstractModelApi<BackendFact, Fact> {
  endpoint = '/facts'
  reverseFieldMap = reverseFactFieldMap

  constructor(axiosInstances: AxiosInstances) {
    super(axiosInstances, parseFact)
    this.updateFact = this.updateFact.bind(this)
  }

  async updateFact({
    fact,
    ingestionTaskStep,
  }: {
    fact: BackendFact
    ingestionTaskStep: IngestionTaskStep | undefined
  }) {
    const path = joinPaths(this.endpoint, fact.fact_id, ingestionTaskStep || '')
    const { data } = await this.api.put(path, fact)
    return parseFact(data)
  }

  async addFactElapsedTime({
    factId,
    taskId: task_id,
    elapsedTimeSeconds: elapsed,
  }: {
    factId: Fact['id']
    taskId: IngestionTask['id']
    elapsedTimeSeconds: number
  }) {
    const path = joinPaths(this.endpoint, factId, 'update_elapsed')
    await this.api.put(path, { task_id, elapsed })
  }

  async createFactsSet({ entityId: entity_id, theme, start, end }: CreateFactsSetPayload) {
    const path = joinPaths(this.endpoint, 'factset')
    const { data } = await this.api.post(path, {
      entity_id,
      theme,
      start: formatDateForBackend(start),
      end: formatDateForBackend(end),
    })
    return data.map(parseFact)
  }

  async acceptFacts(appliedFilters: Filters<FactFilters>) {
    const formattedFilterParams = formatFilterParams<FactFilters>(
      appliedFilters,
      reverseFactFieldMap,
    )
    const queryParams = encodeAndStringifyQueryParams(formattedFilterParams)
    const path = joinPaths(this.endpoint, `/accept?q=${queryParams}`)
    await this.api.patch(path)
  }

  async validate(appliedFilters: Filters<FactFilters>) {
    const formattedFilterParams = formatFilterParams<FactFilters>(
      appliedFilters,
      reverseFactFieldMap,
    )
    const queryParams = encodeAndStringifyQueryParams(formattedFilterParams)
    const path = joinPaths(this.endpoint, `/validate?q=${queryParams}`)
    const { data } = await this.api.post(path, {}, { timeout: 30000 })
    return data
  }
}
