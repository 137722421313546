import { AxiosInstances } from '../../client'
import { AbstractApi } from '../AbstractApi'

export default class EnumApi extends AbstractApi {
  constructor(axiosInstances: AxiosInstances) {
    super(axiosInstances)
    this.getEnum = this.getEnum.bind(this)
  }

  async getEnum<Enum extends string>(enumName: string): Promise<Enum[]> {
    const { data } = await this.api.get<Enum[]>(`/enums/${enumName}`)
    return data
  }
}
