import { FC } from 'react'

const WarningIcon: FC = () => (
  <>
    <path
      d="M12 10V13"
      stroke="currentColor"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 16.9992C11.73 16.9992 11.48 16.8992 11.29 16.7092C11.2 16.6092 11.13 16.4992 11.08 16.3792C11.03 16.2592 11 16.1292 11 15.9992C11 15.7392 11.11 15.4792 11.29 15.2892C11.67 14.9192 12.33 14.9192 12.71 15.2892C12.89 15.4792 13 15.7392 13 15.9992C13 16.1292 12.98 16.2592 12.92 16.3792C12.87 16.4992 12.8 16.6092 12.71 16.7092C12.52 16.8992 12.27 16.9992 12 16.9992Z"
      fill="currentColor"
    />
    <path
      d="M11.1599 4.35172L2.95988 17.4817C2.53988 18.1417 3.00988 19.0017 3.79988 19.0017H20.1999C20.9799 19.0017 21.4599 18.1417 21.0399 17.4817L12.8399 4.35172C12.4499 3.73172 11.5499 3.73172 11.1599 4.35172Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
)

export default WarningIcon
