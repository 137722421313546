import { LoadingOutlined } from '@ant-design/icons'
import Spin, { SpinSize } from 'antd/es/spin'
import { FC } from 'react'
import { SpinContainer } from './Spinner.style'

type Props = {
  size?: SpinSize
  alternate?: boolean
}

const Spinner: FC<Props> = ({ size = 'default', alternate = false }: Props) => (
  <SpinContainer aria-label="Loading" data-testid="loading-spinner">
    <Spin
      size={size}
      {...(alternate ? { indicator: <LoadingOutlined style={{ fontSize: 24 }} spin /> } : {})}
    />
  </SpinContainer>
)

export default Spinner
