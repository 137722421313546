import { FC } from 'react'

const ChevronRightIcon: FC = () => (
  <path
    d="M11 8L15 12L11 16"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  />
)

export default ChevronRightIcon
