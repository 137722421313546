// Timestamps are returned from the back end as UTC, but without a Z suffix, so
// when we convert them into Date objects on the front end they end up an hour
// out during BST.
export const ensureTimestampInUTCFormat = (timestamp: string | undefined): string => {
  if (!timestamp) {
    return ''
  }

  return timestamp.endsWith('Z') ? timestamp : `${timestamp}Z`
}
