import { DatapointPolymorph } from '@netpurpose/types'
import { AxiosInstances } from '../../client'
import { SimpleAbstractModelApi } from '../SimpleAbstractModelApi'
import { formatPartialDatapointPolymorph } from './formatPartialDatapointPolymorph'
import {
  parseDatapointPolymorph,
  reverseDatapointPolymorphFieldMap,
} from './parseDatapointPolymorph'
import { BackendDatapointPolymorph } from './types'

export default class DatapointApi extends SimpleAbstractModelApi<
  BackendDatapointPolymorph,
  DatapointPolymorph
> {
  endpoint = '/datapoints'
  modelFormatter = formatPartialDatapointPolymorph
  reverseFieldMap = reverseDatapointPolymorphFieldMap

  constructor(axiosInstances: AxiosInstances) {
    super(axiosInstances, parseDatapointPolymorph)
  }
}
