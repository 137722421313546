import { TechnologyCost } from '@netpurpose/types'
import { AxiosInstances } from '../../client'
import {
  BackendTechnologyCost,
  formatPartialTechnologyCost,
  parseTechnologyCost,
  technologyCostReverseFieldMap,
} from '../datapoint'
import { SimpleAbstractModelApi } from '../SimpleAbstractModelApi'

export default class TechnologyCostApi extends SimpleAbstractModelApi<
  BackendTechnologyCost,
  TechnologyCost
> {
  endpoint = '/costs'
  modelFormatter = formatPartialTechnologyCost
  reverseFieldMap = technologyCostReverseFieldMap

  constructor(axiosInstances: AxiosInstances) {
    super(axiosInstances, parseTechnologyCost)
  }
}
