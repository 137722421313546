import { FC } from 'react'

const SdgAirIcon: FC = () => (
  <>
    <path
      d="M14.34 6C15.16 3.66 17.38 2 20 2C23.32 2 26 4.68 26 8C26 11.32 23.32 14 20 14H2"
      stroke="white"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M34.34 14C35.16 11.66 37.38 10 40 10C43.32 10 46 12.68 46 16C46 19.32 43.32 22 40 22H2"
      stroke="white"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M30.34 38C31.16 40.34 33.38 42 36 42C39.32 42 42 39.32 42 36C42 32.68 39.32 30 36 30H2"
      stroke="white"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
)

export default SdgAirIcon
