import { FC } from 'react'

const SdgHealthcareIcon: FC = () => (
  <>
    <path
      d="M38 2H10C5.58172 2 2 5.58172 2 10V38C2 42.4183 5.58172 46 10 46H38C42.4183 46 46 42.4183 46 38V10C46 5.58172 42.4183 2 38 2Z"
      stroke="white"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M34 20H28V14C28 12.9 27.1 12 26 12H22C20.9 12 20 12.9 20 14V20H14C12.9 20 12 20.9 12 22V26C12 27.1 12.9 28 14 28H20V34C20 35.1 20.9 36 22 36H26C27.1 36 28 35.1 28 34V28H34C35.1 28 36 27.1 36 26V22C36 20.9 35.1 20 34 20Z"
      stroke="white"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
)

export default SdgHealthcareIcon
