import { FC } from 'react'

const ListViewIcon: FC = () => (
  <>
    <path
      d="M19 17H9C8.45 17 8 16.55 8 16C8 15.45 8.45 15 9 15H19C19.55 15 20 15.45 20 16C20 16.55 19.55 17 19 17Z"
      fill="currentColor"
    />
    <path
      d="M5 16.9984C4.74 16.9984 4.48 16.8883 4.29 16.7083C4.25 16.6583 4.21 16.6084 4.17 16.5584C4.13 16.4984 4.1 16.4383 4.08 16.3783C4.05 16.3183 4.03 16.2584 4.02 16.1984C4.01 16.1284 4 16.0684 4 15.9984C4 15.7384 4.11 15.4784 4.29 15.2884C4.57 15.0084 5.01 14.9284 5.38 15.0784C5.5 15.1284 5.61 15.1984 5.71 15.2884C5.89 15.4784 6 15.7384 6 15.9984C6 16.0684 5.99 16.1284 5.98 16.1984C5.97 16.2584 5.95 16.3183 5.92 16.3783C5.9 16.4383 5.87 16.4984 5.83 16.5584C5.79 16.6084 5.75 16.6583 5.71 16.7083C5.61 16.7983 5.5 16.8684 5.38 16.9184C5.26 16.9684 5.13 16.9984 5 16.9984Z"
      fill="currentColor"
    />
    <path
      d="M19 13H9C8.45 13 8 12.55 8 12C8 11.45 8.45 11 9 11H19C19.55 11 20 11.45 20 12C20 12.55 19.55 13 19 13Z"
      fill="currentColor"
    />
    <path
      d="M5 13.0009C4.74 13.0009 4.48 12.8909 4.29 12.7109C4.25 12.6609 4.21 12.6109 4.17 12.5609C4.13 12.5009 4.1 12.4409 4.08 12.3809C4.05 12.3209 4.03 12.2609 4.02 12.2009C4.01 12.1309 4 12.0709 4 12.0009C4 11.7409 4.11 11.4809 4.29 11.2909C4.52 11.0609 4.87 10.9509 5.2 11.0209C5.26 11.0309 5.32 11.0509 5.38 11.0809C5.44 11.1009 5.5 11.1309 5.56 11.1709C5.61 11.2109 5.66 11.2509 5.71 11.2909C5.89 11.4809 6 11.7409 6 12.0009C6 12.0709 5.99 12.1309 5.98 12.2009C5.97 12.2609 5.95 12.3209 5.92 12.3809C5.9 12.4409 5.87 12.5009 5.83 12.5609C5.8 12.6109 5.75 12.6609 5.71 12.7109C5.66 12.7509 5.61 12.8009 5.56 12.8309C5.5 12.8709 5.44 12.9009 5.38 12.9209C5.32 12.9509 5.26 12.9709 5.2 12.9809C5.13 12.9909 5.07 13.0009 5 13.0009Z"
      fill="currentColor"
    />
    <path
      d="M19 9H9C8.45 9 8 8.55 8 8C8 7.45 8.45 7 9 7H19C19.55 7 20 7.45 20 8C20 8.55 19.55 9 19 9Z"
      fill="currentColor"
    />
    <path
      d="M5 8.99891C4.87 8.99891 4.74 8.96891 4.62 8.91891C4.5 8.86891 4.39 8.79891 4.29 8.70891C4.2 8.60891 4.13 8.49891 4.08 8.37891C4.03 8.25891 4 8.12891 4 7.99891C4 7.86891 4.03 7.73891 4.08 7.61891C4.13 7.49891 4.2 7.38891 4.29 7.28891C4.39 7.19891 4.5 7.12891 4.62 7.07891C4.86 6.97891 5.14 6.97891 5.38 7.07891C5.5 7.12891 5.61 7.19891 5.71 7.28891C5.8 7.38891 5.87 7.49891 5.92 7.61891C5.97 7.73891 6 7.86891 6 7.99891C6 8.12891 5.97 8.25891 5.92 8.37891C5.87 8.49891 5.8 8.60891 5.71 8.70891C5.61 8.79891 5.5 8.86891 5.38 8.91891C5.26 8.96891 5.13 8.99891 5 8.99891Z"
      fill="currentColor"
    />
  </>
)

export default ListViewIcon
