import { FC } from 'react'

const Analysed: FC = () => (
  <>
    <circle cx="12" cy="12" r="6" fill="currentColor" stroke="currentColor" strokeWidth="1.5" />
    <line x1="12" y1="19" x2="12" y2="5" stroke="white" strokeWidth="1.5" />
    <line x1="11.75" y1="11.75" x2="19.75" y2="11.75" stroke="white" strokeWidth="1.5" />
  </>
)

export default Analysed
