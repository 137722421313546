import { CollectionTask } from '@netpurpose/types'
import { AxiosInstances } from '../../client'
import { joinPaths } from '../../utils'
import { AbstractApi } from '../AbstractApi'
import { parseIngestionTask } from '../ingestionBatch'
import { parseSourceTask } from '../sourceBatch'
import { BackendCollectionTask } from './types'

export class CollectionTaskApi extends AbstractApi {
  constructor(axiosInstances: AxiosInstances) {
    super(axiosInstances)
    this.getNextTask = this.getNextTask.bind(this)
  }

  async getNextTask(step: 'EXTRACT' | 'VERIFY' | 'QA' | null): Promise<CollectionTask | null> {
    if (!step) {
      return Promise.reject(new Error(`Task step must be defined, received: ${step}`))
    }

    const { data } = await this.api.get<BackendCollectionTask | null>(
      joinPaths('/tasks/next', step),
    )
    if (!data) {
      return null
    }

    if (data.task_workflow === 'source') {
      return { ...parseSourceTask(data), workflow: 'source' }
    } else if (data.task_workflow === 'ingestion') {
      return { ...parseIngestionTask(data), workflow: 'ingestion' }
    }

    return Promise.reject(new Error('Unable to determine incoming task workflow.'))
  }
}
