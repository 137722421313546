import { FC } from 'react'

const SdgAffordableHousingIcon: FC = () => (
  <>
    <path
      d="M16 46V34C16 29.58 19.58 26 24 26C28.42 26 32 29.58 32 34V46"
      stroke="white"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2 42V20L24 2L46 20V42C46 44.2 44.2 46 42 46H6C3.8 46 2 44.2 2 42Z"
      stroke="white"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
)

export default SdgAffordableHousingIcon
