import { AxiosHeaders, InternalAxiosRequestConfig } from 'axios'

export const getAttachAuthTokenInterceptor =
  (getToken: () => Promise<string | undefined>) =>
  async (conf: InternalAxiosRequestConfig): Promise<InternalAxiosRequestConfig> => {
    const token = await getToken()
    if (!token) {
      return conf
    }
    return {
      ...conf,
      headers: new AxiosHeaders({
        ...conf.headers,
        Authorization: `Bearer ${token}`,
      }),
    }
  }
