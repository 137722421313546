import { AnalystStats, TeamStats } from '@netpurpose/types'

export type BackendTeamStats = {
  overall_avg_time_spent: number | null
  overall_error_rate: number | null
  overall_std_dev_time_spent: number | null
}

export type BackendAnalystStats = {
  analyst_avg_time_spent: number | null
  analyst_std_dev_time_spent: number | null
  analyst_error_rate: number | null
}

export const parseTeamStats = (stats: BackendTeamStats): TeamStats => ({
  overallAvgTimeSpentMins: stats.overall_avg_time_spent,
  overallErrorRate: stats.overall_error_rate,
  overallStdDevTimeSpentMins: stats.overall_std_dev_time_spent,
  overallStdDevErrorRate: 0.05, // set to 5%
})

export const parseAnalystStats = (stats: BackendAnalystStats): AnalystStats => ({
  analystAvgTimeSpentMins: stats.analyst_avg_time_spent,
  analystErrorRate: stats.analyst_error_rate,
})
