import { FC } from 'react'

const LightningBolt: FC = () => (
  <path
    d="M11 9H7V1L1 13H5V21L11 9Z"
    stroke="currentColor"
    strokeWidth="2"
    strokeMiterlimit="10"
    strokeLinecap="round"
    strokeLinejoin="round"
  />
)

export default LightningBolt
